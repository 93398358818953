import BrandVoiceRemote from "../../services/dao/remote/brandVoice";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {message} from "antd";
import {useCurrentWorkspace} from "./useCurrentWorkspace";

export const useBrandVoices = () => {
    const {currentWorkspace} = useCurrentWorkspace();

    const {data: brandVoices, isLoading} = useQuery({
        queryKey: ["brandVoices", currentWorkspace?.idWorkspace],
        queryFn: BrandVoiceRemote.list,
        refetchOnWindowFocus: false,
    });

    return {brandVoices, isLoading};
}

export const useCreateBrandVoice = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();
    const {currentWorkspace} = useCurrentWorkspace();

    const {mutate: createBrandVoice, isLoading: isCreating} = useMutation({
        mutationFn: BrandVoiceRemote.create,
        onSuccess: (brandVoice) => {
            queryClient.setQueryData(["brandVoices", currentWorkspace.idWorkspace], (brandVoices) => {
                return [...brandVoices, brandVoice];
            });
            onSuccess?.();
        },
        onError: (error) => {
            onError?.(error);
            message.error("Erro ao criar brand voice");
        }
    });

    return {createBrandVoice, isCreating};
}

export const useUpdateBrandVoice = ({onSuccess, onError}) => {
    const queryClient = useQueryClient();
    const {currentWorkspace} = useCurrentWorkspace();

    const {mutate: updateBrandVoice, isLoading: isUpdating} = useMutation({
        mutationFn: BrandVoiceRemote.update,
        onSuccess: (brandVoice) => {
            queryClient.setQueryData(["brandVoices", currentWorkspace.idWorkspace], (brandVoices) => {
                return brandVoices.map((bv) => {
                    if (bv.idBrandVoice === brandVoice.idBrandVoice) {
                        return brandVoice;
                    }
                    return bv;
                });
            });
            onSuccess?.();
            message.success("Brand voice atualizada com sucesso");
        },
        onError: (error) => {
            onError?.(error);
            message.error("Erro ao atualizar brand voice");
        }
    });

    return {updateBrandVoice, isUpdating};
}

export const useDeleteBrandVoice = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();
    const {currentWorkspace} = useCurrentWorkspace();

    const {mutate: deleteBrandVoice, isLoading: isDeleting} = useMutation({
        mutationFn: (idBrandVoice) => BrandVoiceRemote.remove(idBrandVoice),
        onSuccess: (idBrandVoice) => {
            queryClient.setQueryData(["brandVoices", currentWorkspace.idWorkspace], (brandVoices) => {
                return brandVoices.filter((bv) => bv.idBrandVoice !== idBrandVoice);
            });
            onSuccess?.();
            message.success("Brand voice deletada com sucesso");
        },
        onError: (error) => {
            onError?.(error);
            message.error("Erro ao deletar brand voice");
        }
    });

    return {deleteBrandVoice, isDeleting};
}