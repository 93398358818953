// src\app\settings\subscription\components\SubscribeButton.jsx

import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRocket} from "@fortawesome/free-solid-svg-icons";
import {Button} from "antd";

const SubscribeButton = ({highlight, onClick, isLoading}) => {
    return (
        <div>
            <Button
                className="rounded-full"
                type={highlight ? "primary" : "default"}
                block
                size="large"
                icon={<FontAwesomeIcon icon={faRocket} className="mr-4"/>}
                onClick={onClick}
                loading={isLoading}
            >
                Assinar
            </Button>
        </div>
    );
};

export default SubscribeButton;