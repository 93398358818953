// src/pages/image-generator/components/gallery/ImageGalleryComponent.jsx
import { Button, Image, Empty } from "antd";
import { DataView } from 'primereact/dataview';
import { useImages } from "../../../../utils/hooks/useImages";
import ImageMenuOptionsWrapper from "../menu-options-wrapper/ImageMenuOptionsWrapper";
import PremiumButton from "../../../../components/buttons/PremiumButton";
import "./ImageGalleryComponent.css";
import { useMemo, useState } from "react";
import { useCurrentWorkspace } from "../../../../utils/hooks/useCurrentWorkspace";

const ImageGalleryComponent = ({
    user,
    size,
    preview = true,
    optionsMenu = true,
    rows = 25,
    selectableItems = false,
    onSelect,
    onScreenChange
}) => {
    // Desestruture 'images' do hook 'useImages'
    const { images } = useImages();
    const [filter, setFilter] = useState("generated");

    // Filtragem das imagens
    const filteredImages = useMemo(() => {
        if (!Array.isArray(images)) {
            console.warn('images não é um array:', images);
            return [];
        }
        if (filter === "uploaded") {
            return images.filter(image => image.type === 'uploaded');
        } else if (filter === "generated") {
            return images.filter(image => image.type === 'generated');
        }
        return images;
    }, [images, filter]);

    // Template para cada item da galeria
    const itemTemplate = (image) => {
        if (!image) return null;

        if (!selectableItems) {
            return (
                <GalleryGridItem
                    image={image}
                    idUser={user?.idUser}
                    onScreenChange={onScreenChange}
                    size={size}
                    preview={preview}
                    optionsMenu={optionsMenu}
                />
            );
        }

        return (
            <GalleryGridItemSelectable
                image={image}
                idUser={user?.idUser}
                size={size}
                onClick={() => onSelect(image)}
            />
        );
    };

    // Renderiza o cabeçalho com os botões de filtro
    const renderHeader = () => {
        return (
            <div className="ImageGalleryComponentHeader">
                {renderFilterButtons()}
            </div>
        );
    };

    // Renderiza os botões de filtro
    const renderFilterButtons = () => {
        return (
            <div className="ImageGalleryComponentFilterButtons">
                {renderFilterButton("generated", "Apenas imagens geradas por você", filter === "generated")}
                {/* Você pode adicionar mais botões de filtro aqui se necessário */}
            </div>
        );
    };

    // Renderiza um botão de filtro individual
    const renderFilterButton = (filterValue, label, selected) => {
        if (selected) {
            return (
                <PremiumButton
                    innerClasses="ImageGalleryFilterButtonSelected"
                    size="middle"
                >
                    {label}
                </PremiumButton>
            );
        } else {
            return (
                <Button
                    className="ImageGalleryFilterButton"
                    type="default"
                    size="middle"
                    onClick={() => setFilter(filterValue)}
                >
                    {label}
                </Button>
            );
        }
    };

    // Renderiza a mensagem de fallback quando não há imagens
    const renderEmptyState = () => {
        return (
            <div className="ImageGalleryEmptyState">
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>Nenhuma imagem disponível</span>}
                />
            </div>
        );
    };

    return (
        <div style={{ position: "relative" }}>
            {renderHeader()}
            {filteredImages.length > 0 ? (
                <DataView
                    className="ImageGallery"
                    value={filteredImages}
                    itemTemplate={itemTemplate}
                    layout={"grid"}
                    rows={rows}
                    sortField={"createdAt"}
                    sortOrder={-1}
                    paginator
                />
            ) : (
                renderEmptyState()
            )}
        </div>
    );
};

export default ImageGalleryComponent;

// Função auxiliar para obter a URL da imagem
const getImageUrl = (idWorkspace, image) => {
    return `${process.env.REACT_APP_TOOL_GENERATED_IMAGES_S3_BUCKET_PREFIX_URL}/${idWorkspace}/${image.filename}.${image.extension}`;
};

// Componente para itens da galeria
const GalleryGridItem = ({ preview, size, image, idUser, onScreenChange, optionsMenu }) => {
    const { currentWorkspace } = useCurrentWorkspace();
    return (
        <div className="ImageGalleryGridItem">
            <Image
                className="ImageGalleryImage"
                height={size}
                width={size}
                src={getImageUrl(currentWorkspace?.idWorkspace, image)}
                preview={preview}
            />
            {optionsMenu && (
                <div className="ImageGalleryImageMenu">
                    <ImageMenuOptionsWrapper
                        idUser={idUser}
                        image={image}
                        options={["download", "delete"]}
                        onScreenChange={onScreenChange}
                    />
                </div>
            )}
        </div>
    );
};

// Componente para itens selecionáveis da galeria
const GalleryGridItemSelectable = ({ size, idUser, image, onClick }) => {
    const { currentWorkspace } = useCurrentWorkspace();
    return (
        <div className="ImageGalleryGridItem ImageGalleryComponentSelectableGridItem">
            <Image
                className="ImageGalleryImage"
                height={size}
                width={size}
                src={getImageUrl(currentWorkspace.idWorkspace, image)}
                preview={false}
                onClick={onClick}
            />
        </div>
    );
};