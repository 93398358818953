// src\app\settings\subscription\components\FreeSubscriptionPlanColumn.jsx

import React, {useState} from 'react';
import {Image} from "antd";
import SubscriptionPlanFeature from "./SubscriptionPlanFeature";
import _ from "lodash";
import SubscribeButton from "./SubscribeButton";
import CurrentSubscriptionPlanButton from "./CurrentSubscriptionPlanButton";
import UpdateSubscriptionButton from "./UpdateSubscriptionButton";
import {useStripeService} from "../../../../utils/hooks/useStripeService";
import SubscriptionRemoteDAO from "../../../../services/dao/remote/SubscriptionRemoteDAO";
import {useNavigate} from "react-router-dom";
import useUser from "../../../../utils/hooks/useUser";

const SubscriptionPlanColumn = ({billingPeriod, subscriptionPlan, activeSubscription, onOpenTeamModal, displayImage = true}) => {
    const stripeService = useStripeService();
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { user } = useUser();

    const formatPrice = (price, decimals = 0) => {
        if (price === 0) {
            return 0;
        }

        return price.toFixed(decimals).replace('.', ',');
    }

    const handleSubscribe = async () => {
        setLoading(true);
        await SubscriptionRemoteDAO.subscribeToFreemiumSubscriptionPlan();
        setTimeout(() => {
            setLoading(false);
            navigate("/subscription/activation");
        }, 5000);
    }

    const handleOpenPortal = async () => {
        await stripeService.openCustomerPortalWindow();
    }

    const isActive = (subscriptionPlan, activeSubscription) => {
        if (!activeSubscription) {
            return false;
        }

        return activeSubscription.idSubscriptionPlan === subscriptionPlan.idSubscriptionPlan
            && activeSubscription.status === "active";
    }

    const getPrice = () => {
        const prices = subscriptionPlan.product.prices;
        const monthlyPrice = _.head(prices.filter((price) => price.billingCycle === 'monthly'));
        const yearlyPrice = _.head(prices.filter((price) => price.billingCycle === 'yearly'));

        if (billingPeriod === 'yearly') {
            const price = _.get(yearlyPrice, "value", 0);
            return price ? formatPrice(price / 12) : formatPrice(0);
        } else {
            return formatPrice(_.get(monthlyPrice, "value", 0));
        }
    }

    const getDailyPrice = () => {
        const prices = subscriptionPlan.product.prices;
        const monthlyPrice = _.head(prices.filter((price) => price.billingCycle === 'monthly'));
        const yearlyPrice = _.head(prices.filter((price) => price.billingCycle === 'yearly'));

        if (billingPeriod === 'yearly') {
            const price = _.get(yearlyPrice, "value", 0);
            return price ? formatPrice(price / 365, 2) : formatPrice(0);
        } else {
            const price = _.get(monthlyPrice, "value", 0);
            return price ? formatPrice(price / 30, 2) : formatPrice(0);
        }
    }

    const renderImage = () => {
        return (
            <div className="text-center">
                <Image
                    preview={false}
                    src={`${process.env.REACT_APP_TOOL_SUBSCRIPTIONS_ICON_S3_BUCKET_PREFIX_URL}/${subscriptionPlan.icon}`}
                    alt={subscriptionPlan.name}
                    width={120}
                    height={120}
                />
            </div>
        );
    }

    const renderTitle = () => {
        return (
            <div className="text-3xl font-bold text-center text-dark font-sans">
                {subscriptionPlan.name}
            </div>
        );
    }

    const renderPrice = () => {
        const teamConfig = subscriptionPlan?.teamConfig;
        return (
            <div className="text-center font-sans text-primary-text">
                <div>
                    <label>R$</label>
                    <label className="text-4xl font-bold mx-1">{getPrice()}</label>
                    <label>/mês</label>
                </div>
                <div>
                    {!!getDailyPrice() ? (
                        <label>cobrança <label className="font-bold">{billingPeriod === "yearly" ? "anual" : "mensal"}</label></label>
                    ) : (
                        <label>sem <label className="font-bold">cobrança</label></label>
                    )}
                </div>
                <div className="rounded-full bg-primary-bg text-primary-text-hover py-2 my-4">
                    {(!teamConfig && !!getDailyPrice()) && <label>Apenas <label className="font-bold">R$ {getDailyPrice()}</label> por dia</label>}
                    {(teamConfig && !!getDailyPrice()) && <label>Workspace para <b>{teamConfig.usersIncluded} usuários</b></label>}
                    {!getDailyPrice() && <label>Gratuito <label className="font-bold">para sempre!</label></label>}
                </div>
            </div>
        );
    }

    const renderButton = () => {
        // Se for o plano freemium (valor real: "FreemiumSubscriptionPlan")
        if (subscriptionPlan.permission_group === "FreemiumSubscriptionPlan") {
            // Se o usuário já possui o plano freemium – seja via activeSubscription ou por permissão no usuário –, mostra "Plano atual"
            if (
              (activeSubscription && activeSubscription.idSubscriptionPlan === subscriptionPlan.idSubscriptionPlan) ||
              (user && user.permission_groups && user.permission_groups.includes("FreemiumSubscriptionPlan"))
            ) {
              return <CurrentSubscriptionPlanButton />;
            }
            // Caso contrário, chama a assinatura automática
            return (
              <SubscribeButton
                billingPeriod={billingPeriod}
                highlight={subscriptionPlan.highlighted}
                onClick={handleSubscribe}
                isLoading={isLoading}
              />
            );
          }
      
        // Lógica para outros planos (mantida inalterada)
        if (!activeSubscription) {
          return (
            <SubscribeButton
              billingPeriod={billingPeriod}
              highlight={subscriptionPlan.highlighted}
              onClick={() => handleSubscribe()}
              isLoading={isLoading}
            />
          );
        }
        if (isActive(subscriptionPlan, activeSubscription)) {
          return <CurrentSubscriptionPlanButton />;
        }
        if (subscriptionPlan.status === "ACTIVE") {
          return (
            <UpdateSubscriptionButton
              highlight={subscriptionPlan.highlighted}
              onClick={handleOpenPortal}
            />
          );
        }
      };

    const renderFeatures = () => {
        return (
            <div className="my-4">
                {subscriptionPlan.features.map(feature => {
                    return (
                        <SubscriptionPlanFeature
                            key={feature.title}
                            feature={feature}
                        />
                    );
                })}
            </div>
        );
    }

    const renderTag = () => {
        if (subscriptionPlan.tag) {
            return (
                <div className="absolute top-0 left-[50%] -translate-x-[50%] -translate-y-[50%]">
                    <div className="bg-primary text-white font-sans rounded-full w-[150px] text-center py-[6px]">
                        {subscriptionPlan.tag}
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={`h-full w-[260px] w-min-[260px] rounded-lg shadow py-1 px-2 bg-white`}>
            {renderTag()}
            {displayImage && renderImage()}
            {renderTitle()}
            {renderPrice()}
            {renderButton()}
            {renderFeatures()}
        </div>
    );
};

export default SubscriptionPlanColumn;