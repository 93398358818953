// src\app\brand-voice\BrandVoicePage.jsx

import React, { useEffect, useState } from "react";
import MainHeaderTitle from "../../components/main-header/MainHeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faPlus } from "@fortawesome/free-solid-svg-icons";
import BrandVoiceList from "./components/BrandVoiceList";
import { Avatar, Input, Popover, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import BrandVoiceCreateModal from "./components/BrandVoiceCreateModal";
import PremiumButton from "../../components/buttons/PremiumButton";
import { useSubscriptionService } from "../../utils/hooks/useSubscriptionService";
import { useBrandVoices } from "../../utils/hooks/useBrandVoices";
import _ from "lodash";
import UpgradeButton from "../../components/subscription/UpgradeButton";
import useIsMobile from "../../utils/hooks/useIsMobile";

const BrandVoicePage = ({ setHeaderTitle }) => {
  const [filter, setFilter] = useState("");
  const [openCreateVoiceModal, setOpenCreateVoiceModal] = useState(false);
  const subscriptionService = useSubscriptionService();
  const { brandVoices, isLoading } = useBrandVoices();
  const isMobile = useIsMobile();

  useEffect(() => {
    document.title = `Vozes de Marca · CMOs.ai`;
    setHeaderTitle(
      <MainHeaderTitle
        icon={<FontAwesomeIcon icon={faBullhorn} />}
        title="Vozes de Marca"
      />
    );
  }, [setHeaderTitle]);

  const canUserCreateMoreBrandVoices = () => {
    if (!brandVoices || isLoading) return false;
    const activeSubscriptionPlan = subscriptionService.getUserActiveSubscriptionPlan();
    const numberOfBrandVoices = _.get(activeSubscriptionPlan, "numberOfBrandVoices", 0);
    return brandVoices.length < numberOfBrandVoices;
  };

  const handleCreateBrandVoice = () => {
    if (canUserCreateMoreBrandVoices()) {
      setOpenCreateVoiceModal(true);
    }
  };

  const renderUpgradeWarningPopoverContent = () => {
    const activeSubscriptionPlan = subscriptionService.getUserActiveSubscriptionPlan();
    const numberOfBrandVoices = _.get(activeSubscriptionPlan, "numberOfBrandVoices", 0);
    return (
      <div className="flex flex-col gap-2 text-sm">
        <p>Você atingiu o limite de {numberOfBrandVoices} vozes de marca no seu plano atual.</p>
        <p>Faça um upgrade para criar mais vozes de marca e expandir suas possibilidades.</p>
        <UpgradeButton size="large" label="Fazer Upgrade" />
      </div>
    );
  };

  const renderCreateButton = () => {
    const button = (
      <PremiumButton
        className="flex items-center gap-2 transition-transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 w-full md:w-auto"
        type="primary"
        size="large"
        onClick={handleCreateBrandVoice}
        disabled={!canUserCreateMoreBrandVoices()}
      >
        <FontAwesomeIcon icon={faPlus} />
        Adicionar Voz de Marca
      </PremiumButton>
    );

    if (!canUserCreateMoreBrandVoices()) {
      return (
        <Popover
          content={renderUpgradeWarningPopoverContent()}
          trigger="hover"
          placement="right"
          overlayClassName="upgrade-popover"
        >
          {button}
        </Popover>
      );
    } else {
      return button;
    }
  };

  return (
    <div className="p-8 bg-white rounded-3xl shadow-xl max-w-screen-lg mx-auto">
      {/* Header */}
      <div className="flex items-center gap-6 mb-8">
        {!isMobile && (
          <Avatar
            className="bg-primary text-white"
            icon={<FontAwesomeIcon icon={faBullhorn} />}
            size="large"
          />
        )}
        <div>
          <h1 className="text-4xl font-bold text-gray-800">Vozes de Marca</h1>
          <p className="text-base text-gray-500 mt-1">
            Personalize o estilo e tom dos conteúdos gerados pelo CMOs.ai no chat especialista.
          </p>
        </div>
      </div>

      {/* Filtros e Botão de Criação */}
       <div 
        className="
            flex flex-col md:flex-row
            items-start md:items-center
            justify-between
            gap-4 mb-8
        "
        >
        {/* Área do filtro (Input) */}
        <div className="w-full md:w-auto flex-1">
            <Input
            size="large"
            placeholder="Buscar vozes de marca por nome ou descrição"
            prefix={<SearchOutlined className="text-gray-400" />}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="
                w-full
                border border-gray-300 rounded-lg
                focus:border-primary
                transition-colors
                focus:ring-2 focus:ring-primary
                focus:ring-offset-2
            "
            />
        </div>

   {/* Botão de criação */}
   <div className="flex-shrink-0">
     {renderCreateButton()}
   </div>
 </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center py-12">
          <Spin size="large" tip="Carregando vozes de marca..." />
        </div>
      ) : (
        <>
          {/* Lista de Vozes de Marca */}
          <BrandVoiceList
            brandVoices={brandVoices}
            isLoading={isLoading}
            filter={filter}
          />

          {/* Mensagem de Limite Atingido */}
          {!canUserCreateMoreBrandVoices() && (
            <div className="mt-8 flex justify-center">
              <div className="p-6 bg-gray-100 border border-gray-200 rounded-lg text-center w-full max-w-md mx-auto">
                <p className="text-gray-700 mb-4">
                  Você atingiu o limite de vozes de marca no seu plano atual.
                </p>
                <UpgradeButton size="large" label="Fazer Upgrade" />
              </div>
            </div>
          )}
        </>
      )}

      {/* Modal de Criação */}
      <BrandVoiceCreateModal
        open={openCreateVoiceModal}
        onClose={() => setOpenCreateVoiceModal(false)}
      />
    </div>
  );
};

export default BrandVoicePage;