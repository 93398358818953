// src/components/menu/MainMenuDesktop.jsx

import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./MainMenu.css";

// Importes necessários (FontAwesome, Hooks, Contexts, etc.)
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faWandSparkles,
  faPenNib,
  faImage,
  faBullhorn,
  faMicrochip,
  faChevronDown,
  faGaugeHigh, 
  faChartSimple, 
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";

import { faFacebook } from "@fortawesome/free-brands-svg-icons";

import { PermissionGroupsContext } from "../../utils/providers/permissionGroupsProvider";
import { OrganizationContext } from "../../utils/providers/organizationProvider";
import { useSubscriptionService } from "../../utils/hooks/useSubscriptionService";

// Componentes do projeto
import WalletBalanceWidget from "../usage/WalletBalanceWidget";
import UpgradeButton from "../subscription/UpgradeButton";
import WorkspaceSelector from "../../app/workspace/WorkspaceSelector";
import UserDropdown from "../main-header/UserDropdown";
import { Theme } from "../../api/Theme";

// antd
import { Button, Tag, Spin } from "antd";
import _ from "lodash";

// Logo
import logo from "../../images/logopurpleblack.png";

// ---------------------------
// Funções de apoio
// ---------------------------
function isActiveRoute(location, key) {
  return location.pathname.startsWith(`/${key}`);
}

function tokenize(text) {
  return text
    .toLowerCase()
    .trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/ /g, "-")
    .replace(/\([^)]*\)/g, "")
    .replace(/[^a-zA-Z0-9_-]/g, "");
}

const MainMenuDesktop = (props) => {
  const { onClose, user, onSignOut, defaultPicture } = props;

  const navigate = useNavigate();
  const location = useLocation();

  // Contexts
  const [permissionGroups] = useContext(PermissionGroupsContext);
  const [organization, setOrganization] = useContext(OrganizationContext);

  // Subscription
  const subscriptionService = useSubscriptionService();
  const activeSubscription = subscriptionService.getUserActiveSubscription();
  const trialing = subscriptionService.isUserActiveSubscriptionOnTrial();

  // States
  const [openWorkspaceDropdown, setOpenWorkspaceDropdown] = useState(false);
  const [openToolsDropdown, setOpenToolsDropdown] = useState(false);

  const [workspaceLoading, setWorkspaceLoading] = useState(false);

  // Se nenhum workspace estiver selecionado, seleciona automaticamente o primeiro workspace do usuário
  useEffect(() => {
    if (!organization && user && user.organizations && user.organizations.length > 0) {
      setOrganization(user.organizations[0]);
    }
  }, [organization, user, setOrganization]);

  // Navegação
  const handleMenuClick = (key, subkey = null) => {
    let to = `/${key}`;
    if (subkey) {
      to = `/t/${subkey}`;
    }
    if (location.pathname !== to) {
      navigate(to, { state: subkey ? { categoryKey: subkey } : {} });
    }
    if (onClose) onClose();
  };

  const handleOpenWhatsApp = () => {
    const whatsappUrl = "https://bit.ly/cmos-ai_treinamento_com_especialista";
    window.open(whatsappUrl, "_blank");
    if (onClose) onClose();
  };

  const goHome = () => navigate("/");

  // Permissões
  const allowedGroupsForCreator = ["Gods", "Administrators", "ContentCreators"];
  const showCreator =
    !_.isEmpty(permissionGroups) &&
    allowedGroupsForCreator.some((grp) => permissionGroups.includes(grp));

  // Itens do menu central (Desktop)
  const centralMenuItems = [
    {
      key: "chat",
      label: "Chat especialista",
      icon: faComments,
      onClick: () => handleMenuClick("chat"),
    },
    {
           key: "marketing-research",
           label: "Marketing research",
           icon: faPenNib,
           onClick: () => handleMenuClick("marketing-research"),
           isNew: true,
          },
    {
      key: "tools",
      label: "Agentes",
      icon: faWandSparkles,
      isDropdown: true,
      dropdownItems: [
        {
          key: "tools/content",
          label: "Conteúdo",
          icon: faPenNib,
          onClick: () => handleMenuClick("tools", "content"),
        },
        {
          key: "tools/social",
          label: "Redes sociais",
          icon: faFacebook,
          onClick: () => handleMenuClick("tools", "social"),
        },
        {
          key: "tools/productivity",
          label: "Produtividade",
          icon: faGaugeHigh,
          onClick: () => handleMenuClick("tools", "productivity"),
        },
        {
          key: "tools/management",
          label: "Gestão",
          icon: faChartSimple,
          onClick: () => handleMenuClick("tools", "management"),
        },
        {
          key: "tools/sales",
          label: "Vendas",
          icon: faMoneyBill,
          onClick: () => handleMenuClick("tools", "sales"),
        },
      ],
    },
    {
      key: "image",
      label: "Imagens",
      icon: faImage,
      onClick: () => handleMenuClick("image"),
    },
    {
           key: "brand-voice",
           label: "Brand voice",
           icon: faBullhorn,
           onClick: () => handleMenuClick("brand-voice"),
    },
    {
      key: "creator",
      label: "Creator",
      icon: faMicrochip,
      onClick: () => handleMenuClick("creator"),
      conditional: showCreator,
    },
  ];

  // Render Subscription
  const renderSubscriptionLabel = () => {
    if (!activeSubscription) return null;
    const planName = _.get(activeSubscription, "subscriptionPlan.name", "");
    return (
      <div className="flex items-center gap-2">
        <span className="text-sm font-semibold text-black">{planName}</span>
        {trialing && (
          <span className="px-2 py-1 text-xs bg-orange-500 text-white rounded-md">
            trial
          </span>
        )}
      </div>
    );
  };

  // User Dropdown
  const renderUserDropdown = () => {
    return (
      <UserDropdown
        user={user}
        defaultPicture={defaultPicture}
        onSignOut={onSignOut}
      />
    );
  };

  return (
    <header className="fixed w-full top-0 left-0 z-50 MainMenuContainer">
      <div
        className="
          bg-gradient-to-b from-gray-50 to-purple-50
          backdrop-blur-md
          border-b border-gray-200
          shadow-md
        "
      >
        <div className="flex items-center justify-between px-4 h-16 relative">
          {/* Logo (esquerda) */}
          <div>
            <button
              onClick={goHome}
              className="
                cursor-pointer
                hover:opacity-80
                transition-all
                bg-transparent
                border-none
                p-0
                appearance-none
              "
            >
              <img
                src={logo}
                alt="Logo"
                className="MainMenuLogoCustom h-5 w-auto object-contain"
              />
            </button>
          </div>

          {/* Menu central (Chat, Marketing Research, Agentes, etc.) */}
          <div className="absolute left-1/2 transform -translate-x-1/2 flex items-center">
            <div
              className="
                bg-white
                border border-gray-200
                rounded-full
                px-2 py-2
                flex items-center
                space-x-2
                shadow-sm
              "
            >
              {centralMenuItems.map((item) => {
                if (item.conditional === false) return null;

                // Verifica se a rota está ativa
                const isTools = item.key === "tools";
                const active = isTools
                  ? location.pathname.includes("/t")
                  : isActiveRoute(location, item.key);

                if (item.isDropdown) {
                  return (
                    <div
                      key={item.key}
                      className="relative"
                      onMouseEnter={() => setOpenToolsDropdown(true)}
                      onMouseLeave={() => setOpenToolsDropdown(false)}
                    >
                      <button
                        onClick={() => handleMenuClick("tools")}
                        className={`
                          flex items-center
                          shrink
                          px-2 py-1 md:px-4 md:py-2
                          text-xs md:text-sm font-medium
                          rounded-full
                          transition-all
                          whitespace-nowrap
                          ${active ? "bg-purple-500 text-white" : "text-gray-700 hover:bg-gray-100"}
                        `}
                      >
                        <FontAwesomeIcon icon={item.icon} className="mr-2" />
                        {item.label}
                        <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-xs" />
                      </button>

                      {openToolsDropdown && (
                        <div
                          className="
                            absolute top-full left-0
                            bg-white
                            border border-gray-200
                            rounded-2xl
                            p-4
                            shadow-lg
                            z-50
                            w-[300px]
                            mt-0
                          "
                        >
                          <div className="grid grid-cols-2 gap-4">
                            {item.dropdownItems.map((sub) => {
                              const subActive = location.pathname.includes(`/${sub.key}`);
                              return (
                                <button
                                  key={sub.key}
                                  onClick={sub.onClick}
                                  className={`
                                    flex flex-col items-center
                                    px-2 py-1 md:px-4 md:py-2
                                    text-xs md:text-sm
                                    rounded-lg
                                    transition-all
                                    hover:bg-gray-100
                                    whitespace-nowrap
                                    ${subActive ? "ring-2 ring-purple-400 bg-purple-50" : ""}
                                  `}
                                >
                                  <FontAwesomeIcon
                                    icon={sub.icon}
                                    className="text-xl mb-1 text-gray-700"
                                  />
                                  <span className="font-medium text-gray-800">
                                    {sub.label}
                                  </span>
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }

                // Botão simples
                return (
                  <button
                    key={item.key}
                    onClick={item.onClick}
                    className={`
                      relative
                      flex items-center
                      shrink
                      px-2 py-1 md:px-4 md:py-2
                      text-xs md:text-sm font-medium
                      rounded-full
                      transition-all
                      whitespace-nowrap
                      ${active ? "bg-purple-500 text-white" : "text-gray-700 hover:bg-gray-100"}
                    `}
                  >
                    <FontAwesomeIcon icon={item.icon} className="mr-2" />
                    {item.label}
                    {item.isNew && (
                      <Tag
                        color={Theme.PRIMARY}
                        style={{
                          position: "absolute",
                          top: "-8px",
                          right: "-10px",
                          fontSize: "0.7rem",
                          borderRadius: "8px",
                        }}
                      >
                        Novo
                      </Tag>
                    )}
                  </button>
                );
              })}
            </div>
          </div>

          {/* Lado direito */}
          <div className="flex items-center space-x-5">
            <WalletBalanceWidget currentOrganization={organization}>
              <UpgradeButton size="small" />
            </WalletBalanceWidget>

            {/* Workspace */}
            <div className="relative">
              <button
                onClick={() => setOpenWorkspaceDropdown(!openWorkspaceDropdown)}
                className="
                  px-2 py-1 md:px-3 md:py-1.5
                  rounded-[12px]
                  text-black text-xs md:text-sm font-medium
                  bg-gray-100 hover:bg-gray-200
                  transition-all duration-200
                  whitespace-nowrap
                "
              >
                Workspace
                <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-xs" />
              </button>

              {openWorkspaceDropdown && (
                <div
                  className="
                    absolute right-0 mt-2 z-50
                    bg-white
                    border border-gray-200
                    rounded-xl
                    p-4 w-56
                    shadow-lg
                  "
                >
                  <div className="mb-3 flex justify-center items-center">
                    {workspaceLoading ? <Spin /> : <WorkspaceSelector />}
                  </div>
                </div>
              )}
            </div>

            {/* Assinatura */}
            {renderSubscriptionLabel()}

            {/* User dropdown */}
            {renderUserDropdown()}
          </div>
        </div>
      </div>
    </header>
  );
};

export default MainMenuDesktop;
