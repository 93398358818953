// brainwave-ui-develop\src\utils\hooks\useCurrentWorkspace.js

import {createContext, useContext, useEffect, useState} from "react";
import {usePersistedState} from "./usePersistedState";
import useUser from "./useUser";

const CurrentWorkspaceContext = createContext(null);

export const CurrentWorkspaceProvider = ({children}) => {
    const {user, isLoading} = useUser();

    const [currentWorkspace, setCurrentWorkspace] = usePersistedState("currentWorkspace", null);
    const [currentUserRole, setCurrentUserRole] = useState(currentWorkspace?.members?.filter(m => m.idUser === user?.idUser)[0]?.role);

    useEffect(() => {
        if (!user || isLoading) return;

        if (!currentWorkspace) {
            setCurrentWorkspace(user?.workspaces?.[0]);
        }
    }, [currentWorkspace, isLoading, setCurrentWorkspace, user]);


    useEffect(() => {
        if (!currentWorkspace) return;
        const member = currentWorkspace?.members?.filter(m => m.idUser === user?.idUser)[0];
        setCurrentUserRole(member?.role);
    }, [currentWorkspace, user?.idUser]);

    const changeCurrentWorkspace = (workspace) => {
        setCurrentWorkspace(workspace);
    }

    return (
        <CurrentWorkspaceContext.Provider value={{currentWorkspace, setCurrentWorkspace: changeCurrentWorkspace, currentUserRole}}>
            {children}
        </CurrentWorkspaceContext.Provider>
    );
}

export const useCurrentWorkspace = () => useContext(CurrentWorkspaceContext);