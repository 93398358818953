// src/components/menu/MainMenuMobile.jsx
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Drawer, Typography, Divider, Button } from "antd";
import { MessageSquare, Wand2, ImageIcon, Briefcase, Menu, PenTool } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import logo from "../../images/logopurpleblack.png";
import ChatHistoryMobile from "../chat/ChatHistoryMobile";
import UserDropdown from "../main-header/UserDropdown";
import { useSubscriptionService } from "../../utils/hooks/useSubscriptionService";
import { useCurrentWorkspace } from "../../utils/hooks/useCurrentWorkspace";
import WorkspaceSelector from "../../app/workspace/WorkspaceSelector";

const { Title } = Typography;

/* 
  Styled Components com a estética ajustada ao seu brandbook:
  - O header utiliza um fundo Off-white (#F4F4F4) com borda e sombra em preto.
  - O Drawer apresenta um fundo em gradiente horizontal (90°) utilizando os tons de azul da sua paleta:
    de Azul Pérola (#BEE4F8) a Azul Acqua (#4FBCF5) e finalizando em Azul Cobalto (#2962FF).
  - Os botões, quando ativos, usam sempre Azul Cobalto (#2962FF) e, quando inativos, permanecem com fundo Off-white.
*/

const BrutalHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background: linear-gradient(90deg,rgb(255, 255, 255), #4FBCF5, #2962FF);
  border-bottom: 3px solid #000;
  box-shadow: 6px 6px 0 #000;
`;

const BrutalDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    /* Gradiente horizontal de Azul Pérola -> Azul Acqua -> Azul Cobalto */
    background: linear-gradient(90deg,rgb(255, 255, 255), #4FBCF5, #2962FF);
    border: 3px solid #000;
    box-shadow: 12px 12px 0 #000;
  }
`;

const BrutalButton = styled(Button)`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 10px 16px;
  height: 40px;
  border-radius: 8px;
  margin-bottom: 8px;
  border: 3px solid #000;
  box-shadow: 3px 3px 0 #000;
  background: ${(props) => (props.active ? props.activeColor : "#F4F4F4")};
  color: ${(props) => (props.active ? "#F4F4F4" : "#000")};
  justify-content: flex-start;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translate(1.5px, 1.5px);
    box-shadow: 1.5px 1.5px 0 #000;
    background: ${(props) => (props.active ? props.activeColor : "#F4F4F4")};
  }

  &:active {
    transform: translate(3px, 3px);
    box-shadow: none;
  }
`;

const MenuIconButton = styled(Button)`
  background: transparent;
  border: none;
  padding: 0;
  &:hover,
  &:focus {
    background: transparent;
  }
`;

const NavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const WorkspaceContainer = styled.div`
  margin-top: 1rem;
`;

const MainMenuMobile = (props) => {
  const {
    user,
    defaultPicture,
    onSignOut,
    onChatCreate,
    onChatChange,
    onChatDelete,
    onChatRename,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const chats =
    queryClient.getQueryData(["chats", currentWorkspace?.idWorkspace]) || [];
  const selectedChatId = localStorage.getItem("idChatSelected") || null;

  const subscriptionService = useSubscriptionService();
  const activeSubscription = subscriptionService.getUserActiveSubscription();
  const trialing = subscriptionService.isUserActiveSubscriptionOnTrial();
  const planName = activeSubscription?.subscriptionPlan?.name || null;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [workspaceSelectorOpen, setWorkspaceSelectorOpen] = useState(false);

  const handleOpenDrawer = () => setDrawerOpen(true);
  const handleCloseDrawer = () => setDrawerOpen(false);

  const handleMenuClick = (path) => {
    navigate(`/${path}`);
    handleCloseDrawer();
  };

  const isChatRoute = location.pathname.startsWith("/chat");

  const firstName = user?.name?.trim().split(/\s+/)[0] || "Usuário";

  // Atualizamos os itens de navegação para usar apenas as cores da paleta: Azul Cobalto para o estado ativo
  const navItems = [
    { path: "chat", icon: MessageSquare, label: "Chat especialista", color: "#2962FF" },
    { path: "marketing-research", icon: PenTool, label: "Marketing research", color: "#2962FF" },
    { path: "tools", icon: Wand2, label: "Agentes", color: "#2962FF" },
    { path: "image", icon: ImageIcon, label: "Imagens", color: "#2962FF" },
    { path: "workspace", icon: Briefcase, label: "Workspace", color: "#2962FF" },
  ];

  return (
    <>
      {/* Cabeçalho */}
      <BrutalHeader>
        <MenuIconButton type="text" onClick={handleOpenDrawer}>
          <Menu className="w-5 h-5" />
        </MenuIconButton>

        <img
          src={logo}
          alt="Logo"
          className="h-5 cursor-pointer"
          onClick={() => navigate("/")}
        />

        <UserDropdown
          user={user}
          defaultPicture={defaultPicture}
          onSignOut={onSignOut}
          minimal
        />
      </BrutalHeader>

      {/* Drawer – Menu lateral */}
      <BrutalDrawer
        placement="left"
        width={280}
        closable={false}
        onClose={handleCloseDrawer}
        open={drawerOpen}
      >
        {/* Boas-vindas e Plano */}
        <div className="mt-1">
          <Title
            level={5}
            style={{
              fontFamily: "Montserrat, sans-serif",
              margin: 0,
              fontWeight: 900,
              color: "#000",
            }}
          >
            Olá, {firstName}!
          </Title>
          {planName && (
            <span
              style={{
                display: "inline-block",
                background: "#F4F4F4",
                color: "#000",
                padding: "4px 8px",
                border: "3px solid #000",
                fontSize: "12px",
                borderRadius: "4px",
                marginTop: "8px",
              }}
            >
              {planName} {trialing && <span style={{ color: "#000" }}>(Trial)</span>}
            </span>
          )}
        </div>

        <Divider style={{ borderColor: "#000" }} />

        {/* Histórico de Chat */}
        {isChatRoute && (
          <>
            <div
              style={{
                border: "3px solid #000",
                borderRadius: "8px",
                padding: "8px",
                maxHeight: "50vh",
                overflowY: "max-height",  
                background: "#F4F4F4",
              }}
            >
              <ChatHistoryMobile
                chats={chats}
                selectedChatId={selectedChatId}
                onChatCreate={onChatCreate}
                onChatChange={onChatChange}
                onChatDelete={onChatDelete}
                onChatRename={onChatRename}
              />
            </div>
            <Divider style={{ borderColor: "#000" }} />
          </>
        )}

        {/* Navegação */}
        <NavContainer>
          {navItems.map(({ path, icon: Icon, label, color }) => (
            <BrutalButton
              key={path}
              onClick={() => handleMenuClick(path)}
              active={location.pathname.startsWith(`/${path}`)}
              activeColor={color}
              icon={<Icon className="w-4 h-4 mr-2" />}
            >
              {label}
            </BrutalButton>
          ))}
        </NavContainer>

        {/* Botão para selecionar workspace */}
        <WorkspaceContainer>
          <BrutalButton
            onClick={() => setWorkspaceSelectorOpen(!workspaceSelectorOpen)}
            active={false}
            activeColor="#2962FF"
            style={{ width: "100%" }}
          >
            Selecionar Workspace
          </BrutalButton>
          {workspaceSelectorOpen && (
            <div style={{ marginTop: "8px" }}>
              <WorkspaceSelector />
            </div>
          )}
        </WorkspaceContainer>

        <Divider style={{ borderColor: "#000", marginTop: "auto" }} />

        {/* UserDropdown completo */}
        <UserDropdown
          user={user}
          defaultPicture={defaultPicture}
          onSignOut={onSignOut}
        />
      </BrutalDrawer>
    </>
  );
};

export default MainMenuMobile;
