// src\components\post-registration\CompleteRegistrationForm.jsx

import {Col, Form, Input, Radio, Row, Select} from "antd";
import React, {useEffect, useState} from "react";
import StringMask from "string-mask";
import {useLegalDocumentValidation} from "../../utils/hooks/useLegalDocumentValidation";
import BrasilDAORemote from "../../services/dao/remote/BrasilDAORemote";
import {BrazilianStates} from "../../api/BrazilianStates";
import "./CompleteRegistrationForm.css";

const CompleteRegistrationForm = ({
                                      entityType: [entityType, setEntityType],
                                      cpf: [cpf, setCpf],
                                      cnpj: [cnpj, setCnpj],
                                      client: [client, setClient],
                                      phone: [phone, setPhone],
                                      cep: [cep, setCep],
                                      address: [address, setAddress],
                                      number: [number, setNumber],
                                      complement: [complement, setComplement],
                                      neighborhood: [neighborhood, setNeighborhood],
                                      city: [city, setCity],
                                      state: [state, setState],
                                      cepValidateStatus: [cepValidateStatus, setCepValidateStatus],
                                  }) => {
    const [isFetchingCep, setFetchingCep] = useState(false);

    const CEP_MASK = "00.000-000";
    const CPF_MASK = "000.000.000-00";
    const CNPJ_MASK = "00.000.000/0000-00";
    const PHONE_MASK = "(00) 00000-0000";

    const legalDocumentValidation = useLegalDocumentValidation();

    useEffect(() => {
        if (cep.length === CEP_MASK.length) {
            fetchCep();
        }
    }, [cep]);

    const applyMask = (value, mask) => {
        const masker = new StringMask(mask);
        return masker.apply(value);
    }

    const getStateOptions = () => {
        return BrazilianStates;
    }

    const fetchCep = () => {
        const cepValue = cep.replace(/[/.-]/g, '');
        setCepValidateStatus("validating")
        setFetchingCep(true);
        BrasilDAORemote.fetchCep(cepValue)
            .then((response) => {
                if (response.errors) {
                    throw new Error("CEP inválido");
                }

                const {street, neighborhood, city, state} = response;
                setAddress(street);
                setNeighborhood(neighborhood);
                setCity(city);
                setState(state);
                setCepValidateStatus("success");
            })
            .catch((error) => {
                setCepValidateStatus("error");
            })
            .finally(() => {
                setFetchingCep(false);
            });
    }

    const getValidateStatus = (value, validator, mask) => {
        if (value.length !== mask.length) {
            return null;
        }
        return validator(value) ? "success" : "error";
    }

    const handleMaskedValuesChange = ({newValue, currentValue, setter, mask}) => {
        if (newValue.length > mask.length) {
            return;
        }

        if (newValue.length > currentValue.length) {
            newValue = newValue.replace(/\W/g, '');
            setter(applyMask(newValue, mask));
        } else {
            setter(newValue);
        }
    }

    const isFormValid = () => {
        const isValidDocument = entityType === "individual" ? legalDocumentValidation.validateCPF(cpf) : legalDocumentValidation.validateCNPJ(cnpj);
        console.log("is form valid");
        return (
            isValidDocument &&
            client.length > 3 &&
            phone.length === PHONE_MASK.length &&
            cepValidateStatus === "success" &&
            address.length > 0 &&
            number.length > 0 &&
            complement.length > 0 &&
            neighborhood.length > 0 &&
            city.length > 0 &&
            state.length > 0
        );
    }

    const renderLegalDocumentField = () => {
        if (entityType === "individual") {
            return renderCpfField();
        } else if (entityType === "company") {
            return renderCnpjField();
        }
    }

    const renderCpfField = () => {
        const validateStatus = () => getValidateStatus(cpf, legalDocumentValidation.validateCPF, CPF_MASK);
        const handleChange = (e) => handleMaskedValuesChange(
            {newValue: e.target.value, currentValue: cpf, setter: setCpf, mask: CPF_MASK}
        );

        return (
            <Form.Item
                colon={false}
                name={['cpf']}
                rules={[{required: true}]}
                validateStatus={validateStatus()}
                hasFeedback
                validateTrigger={["onChange"]}
                help={validateStatus() === "error" ? "CPF inválido" : null}
            >
                <label className="CompleteRegistrationFormTitle">CPF</label>
                <Input
                    value={cpf}
                    onChange={handleChange}
                    placeholder={CPF_MASK}
                />
            </Form.Item>
        );
    }

    const renderCnpjField = () => {
        const validateStatus = () => getValidateStatus(cnpj, legalDocumentValidation.validateCNPJ, CNPJ_MASK);
        const handleChange = (e) => handleMaskedValuesChange(
            {newValue: e.target.value, currentValue: cnpj, setter: setCnpj, mask: CNPJ_MASK}
        );

        return (
            <Form.Item
                colon={false}
                name={['cnpj']}
                rules={[{required: true}]}
                validateStatus={validateStatus()}
                hasFeedback
                validateTrigger={["onChange"]}
                help={validateStatus() === "error" ? "CNPJ inválido" : null}
            >
                <label className="CompleteRegistrationFormTitle">CNPJ</label>
                <Input
                    value={cnpj}
                    onChange={handleChange}
                    placeholder={CNPJ_MASK}
                />
            </Form.Item>
        );
    }

    const renderTaxInformationFields = () => {
        return (
            <>
                <Row gutter={8} justify={"start"}>
                    <Col>{renderLegalEntityField()}</Col>
                </Row>

                <Row gutter={8} justify={"center"}>
                    <Col xs={24} sm={24} md={11}>{renderClientField()}</Col>
                    <Col xs={12} sm={12} md={7}>{renderLegalDocumentField()}</Col>
                    <Col xs={12} sm={12} md={6}>{renderPhoneField()}</Col>
                </Row>
            </>
        );
    }

    const renderLegalEntityField = () => {
        return (
            <Form.Item>
                <Radio.Group
                    value={entityType}
                    onChange={(e) => setEntityType(e.target.value)}
                >
                    <Radio value="individual">Pessoa física</Radio>
                    <Radio value="company">Pessoa jurídica</Radio>
                </Radio.Group>
            </Form.Item>
        );
    }

    const renderClientField = () => {
        return (
            <Form.Item
                colon={false}
                name={['client']}
                rules={[{required: true}]}
            >
                <label className="CompleteRegistrationFormTitle">
                    {entityType === "individual" ? "Nome completo" : "Razão social"}
                </label>
                <Input
                    value={client}
                    onChange={(e) => setClient(e.target.value)}
                    placeholder={entityType === "individual" ? "Nome completo" : "Razão social"}
                />
            </Form.Item>
        );
    }

    const renderPhoneField = () => {
        const handleChange = (e) => handleMaskedValuesChange(
            {newValue: e.target.value, currentValue: phone, setter: setPhone, mask: PHONE_MASK}
        );

        return (
            <Form.Item
                colon={false}
                name={['phone']}
                rules={[{required: true}]}
            >
                <label className="CompleteRegistrationFormTitle">
                    Telefone
                </label>
                <Input
                    value={phone}
                    onChange={handleChange}
                    placeholder={"(99) 99999-9999"}
                />
            </Form.Item>
        );
    }

    const renderAddressFields = () => {
        return (
            <>
                <Row gutter={8} justify={"center"}>
                    <Col xs={24} sm={8} md={6}>{renderCepField()}</Col>
                    <Col xs={18} sm={12} md={14}>{renderAddressField()}</Col>
                    <Col xs={6} sm={4} md={4}>{renderNumberField()}</Col>
                </Row>

                <Row gutter={8} justify={"center"}>
                    <Col xs={12} sm={12} md={6}>{renderComplementField()}</Col>
                    <Col xs={12} sm={12} md={6}>{renderNeighborhoodField()}</Col>
                    <Col xs={12} sm={12} md={6}>{renderCityField()}</Col>
                    <Col xs={12} sm={12} md={6}>{renderStateField()}</Col>
                </Row>
            </>
        );
    }

    const renderCepField = () => {
        const handleChange = (e) => handleMaskedValuesChange(
            {newValue: e.target.value, currentValue: cep, setter: setCep, mask: CEP_MASK}
        );

        return (
            <Form.Item
                colon={false}
                name={['cep']}
                rules={[{required: true}]}
                hasFeedback
                validateTrigger={["onChange"]}
                validateStatus={cepValidateStatus}
                help={cepValidateStatus === "error" ? "CEP inválido" : null}
            >
                <label className="CompleteRegistrationFormTitle">CEP</label>
                <Input
                    value={cep}
                    onChange={handleChange}
                    disabled={isFetchingCep}
                    placeholder={CEP_MASK}
                />
            </Form.Item>
        );
    }

    const renderAddressField = () => {
        return (
            <Form.Item name={['address']} rules={[{required: true}]}>
                <label className="CompleteRegistrationFormTitle">Endereço</label>
                <Input
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    disabled={isFetchingCep}
                    placeholder={"Endereço completo"}
                />
            </Form.Item>
        );
    }

    const renderNumberField = () => {
        return (
            <Form.Item name={['number']} rules={[{required: true}]}>
                <label className="CompleteRegistrationFormTitle">Número</label>
                <Input
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    disabled={isFetchingCep}
                    placeholder={"123"}
                    maxLength={8}
                />
            </Form.Item>
        );
    }

    const renderComplementField = () => {
        return (
            <Form.Item name={['complement']} rules={[{required: true}]}>
                <label className="CompleteRegistrationFormTitle">Complemento</label>
                <Input
                    value={complement}
                    onChange={(e) => setComplement(e.target.value)}
                    disabled={isFetchingCep}
                    placeholder={"Apto 101"}
                />
            </Form.Item>
        );
    }

    const renderNeighborhoodField = () => {
        return (
            <Form.Item name={['neighborhood']} rules={[{required: true}]}>
                <label className="CompleteRegistrationFormTitle">Bairro</label>
                <Input
                    value={neighborhood}
                    onChange={(e) => setNeighborhood(e.target.value)}
                    disabled={isFetchingCep}
                    placeholder={"Bairro"}
                />
            </Form.Item>
        );
    }

    const renderCityField = () => {
        return (
            <Form.Item name={['city']} rules={[{required: true}]}>
                <label className="CompleteRegistrationFormTitle">Cidade</label>
                <Input
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    disabled={isFetchingCep}
                    placeholder={"Cidade"}
                />
            </Form.Item>
        );
    }

    const renderStateField = () => {
        return (
            <Form.Item name={['state']} rules={[{required: true}]}>
                <label className="CompleteRegistrationFormTitle">Estado</label>
                <Select
                    options={getStateOptions()}
                    value={state}
                    onChange={(value) => setState(value)}
                    showSearch
                    disabled={isFetchingCep}
                />
            </Form.Item>
        );
    }

    return (
        <div className="CompleteRegistrationForm">
            <Form
                size="large"
                layout="horizontal"
                style={{maxWidth: 800, margin: "auto"}}
            >
                {renderTaxInformationFields()}
                {renderAddressFields()}
            </Form>
        </div>
    );
}

export default CompleteRegistrationForm;