// src\components\chat\ChatHistory.jsx

import React, { Component } from "react";
import "./ChatHistory.css";
import ChatHistoryItem from "./ChatHistoryItem";
import autoBind from "auto-bind";
import _ from "lodash";
import Sider from "antd/es/layout/Sider";
import { Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import withIsMobile from "../../utils/hocs/withIsMobile";
import PremiumButton from "../buttons/PremiumButton";

class ChatHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
      visibleChatsCount: 10, // Exibe inicialmente 10 chats
    };
    autoBind(this);
  }

  // Detecta mudanças na prop workspaceId para atualizar o histórico
  componentDidUpdate(prevProps) {
    if (prevProps.workspaceId !== this.props.workspaceId) {
      // Resetar a quantidade de chats visíveis
      this.setState({ visibleChatsCount: 10 });
      
      // Opcional: se houver chats, seleciona automaticamente o primeiro
      if (this.props.chats && this.props.chats.length > 0) {
        const sortedChats = _.orderBy(
          this.props.chats,
          [(chat) => String(chat.updatedAt)],
          ["desc"]
        );
        const firstChat = sortedChats[0];
        // Se houver uma função para alterar o chat selecionado, chame-a
        if (firstChat && this.props.onChatChange) {
          this.props.onChatChange(firstChat.idChat);
        }
      }
    }
  }

  onCollapse() {
    this.setState((prevState) => ({
      isCollapsed: !prevState.isCollapsed,
    }));
  }

  handleLoadMore() {
    this.setState((prevState) => ({
      visibleChatsCount: prevState.visibleChatsCount + 10,
    }));
  }

  renderChatItems() {
    const { isCollapsed, visibleChatsCount } = this.state;
    const {
      chats,
      selectedChatId,
      onChatChange,
      onChatDelete,
      onChatRename,
      disabled,
    } = this.props;

    // Ordena os chats por data desc
    const sortedChats = _.orderBy(
      chats,
      [(chat) => String(chat.updatedAt)],
      ["desc"]
    );
    // Seleciona apenas os chats que serão exibidos
    const visibleChats = sortedChats.slice(0, visibleChatsCount);

    return visibleChats.map((chat) => (
      <ChatHistoryItem
        key={chat.idChat}
        title={chat.title}
        idChat={chat.idChat}
        isSelected={chat.idChat === selectedChatId}
        icon={<FontAwesomeIcon icon="fa-solid fa-user" />}
        onChatChange={onChatChange}
        onChatDelete={onChatDelete}
        onChatRename={onChatRename}
        collapsed={isCollapsed}
        disabled={disabled}
      />
    ));
  }

  renderCreateNewChatButton() {
    const { onChatCreate, disabled } = this.props;
    return (
      <PremiumButton
        innerClasses="rounded-full my-1"
        block
        icon={<FontAwesomeIcon icon={faPlus} className="mr-2" />}
        onClick={disabled ? null : onChatCreate}
      >
        Novo chat
      </PremiumButton>
    );
  }

  renderCollapseButton() {
    const { isCollapsed } = this.state;
    return (
      <Button
        shape="circle"
        size="large"
        type="text"
        icon={
          isCollapsed ? (
            <FontAwesomeIcon icon={faChevronRight} />
          ) : (
            <FontAwesomeIcon icon={faChevronLeft} />
          )
        }
        onClick={this.onCollapse}
        className="ChatHistoryCollapseButton"
      />
    );
  }

  render() {
    const { isCollapsed } = this.state;
    const { isMobile, isLoading, chats } = this.props;

    // Define a posição: se for mobile e não estiver colapsado, utiliza "absolute"
    const position = isMobile && !isCollapsed ? "absolute" : "relative";

    // Se seu MainMenu tiver altura diferente, ajuste aqui
    const MAIN_MENU_HEIGHT = 0;

    return (
      <Sider
        className={`
          ChatHistorySider
          ${isCollapsed ? "collapsed" : ""}
          p-4 z-10
        `}
        style={{
          marginTop: `${MAIN_MENU_HEIGHT}px`,
          height: `calc(100% - ${MAIN_MENU_HEIGHT}px)`,
          position: position,
          overflow: "hidden",
        }}
        width={220}
        breakpoint="md"
        collapsible
        collapsed={isCollapsed}
        trigger={null}
      >
        {this.renderCollapseButton()}

        {!isCollapsed && (
          <>
            {this.renderCreateNewChatButton()}
            <div className="h-[16px]" />

            {isLoading && (
              <div className="w-full flex justify-center text-center p-2">
                <LoadingOutlined
                  className="text-white"
                  spin
                  style={{ fontSize: 36 }}
                />
              </div>
            )}
            {!isLoading && (
              <div
                className="overflow-y-auto pb-4 scrollbar-hide"
                style={{ height: "calc(100% - 36px)" }}
              >
                {this.renderChatItems()}
                {chats.length > this.state.visibleChatsCount && (
                  <Button
                    onClick={this.handleLoadMore}
                    block
                    className="CarregarMaisBtn"
                    style={{ marginTop: "8px" }}
                  >
                    Carregar mais...
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </Sider>
    );
  }
}

export default withIsMobile(ChatHistory);