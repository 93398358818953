// src/app/marketing-research/MessageBubble.jsx

import React, { useState, useMemo, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import clsx from 'clsx';
import ToggleReasoningButton from './ui/ToggleReasoningButton';

const LUMON_GREEN = '#78B978';

// Remove citações do tipo [1] [2][3] do texto
const removeCitations = (text) =>
  text.replace(/\s*\[[0-9]+(?:\]\[[0-9]+\])*\]/g, '');

// Função para converter blocos de texto em tabelas Markdown
function convertPlainTableToMarkdown(text) {
  // Remove linhas contendo somente "="
  text = text.replace(/^\s*={3,}\s*$/gm, "").trim();

  // NOVO: Remove linhas que consistem apenas de caracteres de separação
  text = text.replace(/^[═\s]+$/gm, "").trim();

  // NOVO: Converte marcadores de lista "•" para itens de lista Markdown
  text = text.replace(/(?<!\n)\s*•\s*/g, "\n- ");

  // Converte <br> e variantes para quebras de linha
  text = text.replace(/<br\s*\/?>/gi, "\n");

  // Caso especial: se o texto começar com "Tabela", remova o rótulo "Tabela X:".
  if (text.trim().toLowerCase().startsWith("tabela")) {
    const colonIndex = text.indexOf(":");
    if (colonIndex !== -1) {
      text = text.substring(colonIndex + 1).trim();
    }
  }
  
  // Se não houver quebras de linha e houver pipes, processa como tabela inline.
  if (!text.includes("\n") && text.includes("|")) {
    let tokens = text.split("|").map(token => token.trim()).filter(token => token !== "");
    if (tokens.length === 4) {
      return `| ${tokens[0]} | ${tokens[1]} |\n| --- | --- |\n| ${tokens[2]} | ${tokens[3]} |`;
    }
    if (tokens.length % 2 === 0 && tokens.length > 0) {
      let header = `| ${tokens[0]} | ${tokens[1]} |`;
      let separator = `| --- | --- |`;
      let dataRows = [];
      for (let i = 2; i < tokens.length; i += 2) {
        dataRows.push(`| ${tokens[i]} | ${tokens[i + 1]} |`);
      }
      return [header, separator, ...dataRows].join("\n");
    }
  }
  
  // Função auxiliar para dividir uma linha: se contiver tabulações, use-as; senão, 2+ espaços.
  function splitLine(line) {
    if (line.includes('\t')) {
      return line.split('\t').map(item => item.trim()).filter(Boolean);
    } else {
      return line.split(/\s{2,}/).map(item => item.trim()).filter(Boolean);
    }
  }
  
  // Se o texto não contém pipes mas cada linha possui 2+ colunas delimitadas por tab ou 2+ espaços:
  const spaceSeparatedLines = text.split('\n').filter(line => line.trim() !== "");
  if (
    spaceSeparatedLines.length >= 2 &&
    spaceSeparatedLines.every(line => splitLine(line).length >= 2)
  ) {
    const rows = spaceSeparatedLines.map(line => splitLine(line));
    const mdRows = rows.map(row => `| ${row.join(" | ")} |`);
    const separator = `| ${rows[0].map(() => '---').join(" | ")} |`;
    mdRows.splice(1, 0, separator);
    return mdRows.join("\n");
  }
  
  // Caso padrão: processa o texto linha a linha
  const lines = text.split('\n');
  let resultLines = [];
  let tableBlock = [];
  let inTableBlock = false;
  
  lines.forEach((line) => {
    if (line.includes('|') && line.split('|').map(t => t.trim()).filter(Boolean).length >= 2) {
      inTableBlock = true;
      tableBlock.push(line.trim());
    } else {
      if (inTableBlock) {
        if (tableBlock.length >= 2 && !/^[-|\s]+$/.test(tableBlock[1])) {
          const headerCols = tableBlock[0].split('|').map(s => s.trim()).filter(Boolean);
          const separator = headerCols.map(() => '---').join(' | ');
          tableBlock.splice(1, 0, separator);
        }
        tableBlock = tableBlock.map((l) => {
          if (!l.startsWith('|')) l = '| ' + l;
          if (!l.endsWith('|')) l = l + ' |';
          return l;
        });
        resultLines.push(...tableBlock);
        tableBlock = [];
        inTableBlock = false;
      }
      resultLines.push(line);
    }
  });
  
  if (inTableBlock && tableBlock.length) {
    if (tableBlock.length >= 2 && !/^[-|\s]+$/.test(tableBlock[1])) {
      const headerCols = tableBlock[0].split('|').map(s => s.trim()).filter(Boolean);
      const separator = headerCols.map(() => '---').join(' | ');
      tableBlock.splice(1, 0, separator);
    }
    tableBlock = tableBlock.map((l) => {
      if (!l.startsWith('|')) l = '| ' + l;
      if (!l.endsWith('|')) l = l + ' |';
      return l;
    });
    resultLines.push(...tableBlock);
  }
  
  return resultLines.join('\n');
}


// Função para extrair o domínio de uma URL
function extractDomain(url) {
  try {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }
    const { hostname } = new URL(url);
    return hostname.toLowerCase();
  } catch (err) {
    return '';
  }
}

// Função para montar a URL do favicon utilizando a API do Google
function getFaviconUrl(domain) {
  return `https://www.google.com/s2/favicons?sz=32&domain_url=${domain}`;
}

function CitationFavicon({ link }) {
  const [iconError, setIconError] = useState(false);
  const domain = extractDomain(link);
  const faviconUrl = getFaviconUrl(domain);

  return (
    <div
      onClick={() => window.open(link, '_blank')}
      style={{
        width: '28px',
        height: '28px',
        borderRadius: '50%',
        overflow: 'hidden',
        cursor: 'pointer',
        backgroundColor: '#EEE',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'transform 0.2s ease-in-out'
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(1.1)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1.0)';
      }}
    >
      <img
        src={
          iconError
            ? 'https://via.placeholder.com/16/cccccc/FFFFFF?text=%3F'
            : faviconUrl
        }
        alt="favicon"
        style={{ width: 16, height: 16, objectFit: 'contain' }}
        onError={() => setIconError(true)}
      />
    </div>
  );
}

/**
 * Componente que exibe uma animação de loading com o texto "Pesquisando"/"Gerando"
 * O cursor agora é renderizado como uma barra vertical animada.
 */
function LoadingWords({ text, letterInterval = 120 }) {
  const [displayText, setDisplayText] = useState('');
  const [letterIndex, setLetterIndex] = useState(0);

  useEffect(() => {
    setDisplayText('');
    setLetterIndex(0);
  }, [text]);

  useEffect(() => {
    if (letterIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayText(text.slice(0, letterIndex + 1));
        setLetterIndex(letterIndex + 1);
      }, letterInterval);
      return () => clearTimeout(timer);
    }
  }, [letterIndex, text, letterInterval]);

  return (
    <div className="loading-words flex items-center text-2xl text-gray-700 font-semibold">
      {displayText}
      {/* A barra de teclando animada */}
      <span
        className="blinking-cursor ml-2"
        style={{
          display: 'inline-block',
          width: '2px',
          height: '1em',
          backgroundColor: 'currentColor',
          animation: 'blink 1s step-start infinite'
        }}
      ></span>
    </div>
  );
}

/* 
  CSS global necessário (por exemplo, no seu arquivo CSS global):

  @keyframes blink {
    50% { opacity: 0; }
  }
*/

// Ícone de spinner para o estado de aprimoramento
function SpinnerIcon({ className }) {
  return (
    <svg className={className} viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z" />
    </svg>
  );
}

// Ícone de aprimoramento (ex: símbolo de sparkles)
function EnhanceIcon({ className }) {
  return (
    <svg className={className} viewBox="0 0 24 24">
      <path d="M12 2l2.39 7.39H22l-6 4.36L17.39 22 12 17.27 6.61 22 8 13.75l-6-4.36h7.61L12 2z" fill="currentColor" />
    </svg>
  );
}

// Componentes padrão para renderizar Markdown
const markdownComponents = {
  table: ({ node, ...props }) => (
    <div className="overflow-x-auto my-4">
      <table className="min-w-full table-auto border-collapse border border-gray-300 rounded-sm" {...props} />
    </div>
  ),
  thead: ({ node, ...props }) => <thead className="bg-gray-100" {...props} />,
  tbody: ({ node, ...props }) => <tbody className="bg-white divide-y divide-gray-200" {...props} />,
  tr: ({ node, ...props }) => <tr className="hover:bg-gray-50 even:bg-gray-50" {...props} />,
  th: ({ node, ...props }) => (
    <th
      className="px-4 py-2 text-xs font-semibold text-gray-700 border border-gray-300 uppercase tracking-wide text-left"
      {...props}
    />
  ),
  td: ({ node, ...props }) => (
    <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300" {...props} />
  ),
  code: ({ node, inline, className, children, ...props }) => {
    const match = /language-(\w+)/.exec(className || '');
    return !inline ? (
      <pre className="bg-gray-800 text-white p-3 rounded-md overflow-x-auto my-4">
        <code className={className} {...props}>
          {children}
        </code>
      </pre>
    ) : (
      <code className="bg-gray-100 text-red-500 p-1 rounded" {...props}>
        {children}
      </code>
    );
  },
  heading: ({ node, level, ...props }) => {
    const sizes = [
      'text-2xl',
      'text-xl',
      'text-lg',
      'text-base',
      'text-sm',
      'text-xs',
    ];
    const size = sizes[level - 1] || sizes[2];
    return React.createElement(
      'h' + level,
      { className: `font-bold ${size} my-4`, ...props },
      props.children
    );
  },
  blockquote: ({ node, ...props }) => (
    <blockquote className="border-l-4 border-gray-300 pl-4 italic text-gray-600 my-4" {...props} />
  ),
  // Personalização da linha horizontal para um visual mais suave
  hr: ({ node, ...props }) => (
    <hr className="my-8 border-0 h-px bg-gradient-to-r from-transparent via-gray-300 to-transparent" {...props} />
  ),
};

/**
 * Componente de balão de mensagem que renderiza o conteúdo da mensagem (Markdown)
 * sem qualquer ajuste adicional no texto aprimorado.
 */
function MessageBubble({
  message,
  showEnhanceButton = false,
  onEnhanceClick,
  enhancedContent = '',
  isEnhancing = false,
}) {
  const [showReasoning, setShowReasoning] = useState(false);
  const isUser = message.role === 'user';
  const isSystem = message.role === 'system';

  // Garante que message.reasoning seja uma string
  const reasoningText = typeof message.reasoning === 'string' ? message.reasoning : '';
  const hasReasoning = reasoningText.trim().length > 0;

  // Remove citações dos textos
  const cleanedContent = removeCitations(message.content || '');
  const cleanedReasoning = removeCitations(reasoningText);
  const citations = Array.isArray(message.citations) ? message.citations : [];
  const convertedContent = useMemo(() => convertPlainTableToMarkdown(cleanedContent), [cleanedContent]);

  const convertedEnhancedContent = useMemo(
    () => convertPlainTableToMarkdown(enhancedContent),
    [enhancedContent]
  );

  // Conteúdo principal
  const memoizedContent = useMemo(() => (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={markdownComponents}>
      {convertedContent}
    </ReactMarkdown>
  ), [convertedContent]);

  // Raciocínio (se existir)
  const memoizedReasoning = useMemo(() => (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={markdownComponents}>
      {cleanedReasoning}
    </ReactMarkdown>
  ), [cleanedReasoning]);

  // Conteúdo aprimorado: renderiza o conteúdo bruto retornado pelo modelo
  const memoizedEnhancedContent = useMemo(() => (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={markdownComponents}>
      {convertedEnhancedContent}
    </ReactMarkdown>
  ), [convertedEnhancedContent]);

  // Classes do container
  const containerClasses = clsx(
    'p-6 rounded-xl max-w-[70%] shadow-md transition-all duration-300 border',
    isUser
      ? 'bg-white border-gray-200'
      : isSystem
      ? 'bg-blue-50 border-blue-200'
      : 'bg-gray-50 border-gray-200',
    'text-gray-800'
  );

  // Alinhamento da mensagem
  const alignment = isUser
    ? 'justify-end'
    : isSystem
    ? 'justify-center'
    : 'justify-start';

  // Texto de loading – garantindo que message.content seja sempre uma string
  const loadingText = (message.content || '').trim() === '' ? 'Pesquisando' : 'Gerando';

  return (
    <div className={clsx('mb-6 flex', alignment)}>
      <div className={containerClasses}>
        {/* Raciocínio (para assistente) */}
        {!isUser && !isSystem && hasReasoning && (
          <div className="mb-4">
            <ToggleReasoningButton
              onClick={() => setShowReasoning((prev) => !prev)}
              active={showReasoning}
              loading={!message.isFinished}
            />
            {showReasoning && (
              <div className="mt-3 p-4 bg-white text-gray-700 rounded-lg shadow-sm border border-gray-200">
                {memoizedReasoning}
              </div>
            )}
          </div>
        )}

        {/* Conteúdo principal */}
        <div className="prose prose-base max-w-none leading-relaxed">
          {memoizedContent}
        </div>

        {/* Loading (para assistente) */}
        {!message.isFinished && message.role === 'assistant' && (
          <div className="mt-2">
            <LoadingWords text={loadingText} />
          </div>
        )}

        {/* Botão de Aprimoramento */}
        {showEnhanceButton && (
          <div className="mt-4">
            <button
              type="button"
              onClick={onEnhanceClick}
              disabled={isEnhancing}
              className={clsx(
                'flex items-center gap-2 px-5 py-2 text-sm font-medium rounded-lg text-white transition-transform transform hover:scale-105',
                isEnhancing
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-[color:var(--lumon-green)] hover:opacity-90'
              )}
              style={{ ['--lumon-green']: LUMON_GREEN }}
            >
              {isEnhancing ? (
                <>
                  <SpinnerIcon className="w-4 h-4 animate-spin" />
                  Aprimorando...
                </>
              ) : (
                <>
                  <EnhanceIcon className="w-4 h-4" />
                  Aprimoramento avançado
                </>
              )}
            </button>
          </div>
        )}

        {/* Conteúdo Aprimorado - renderizado "bruto" com design aprimorado */}
        {enhancedContent && (
          <div className="mt-4 rounded-lg shadow overflow-hidden">
            <div className="p-3" style={{ backgroundColor: LUMON_GREEN }}>
              <span className="font-semibold text-sm text-white">
                Resposta Aprimorada
              </span>
            </div>
            <div className="p-5 bg-white text-gray-700">
              {memoizedEnhancedContent}
            </div>
          </div>
        )}

        {/* Favicons das citações */}
        {citations.length > 0 && (
          <div className="mt-4 flex gap-2">
            {citations.map((link, i) => (
              <CitationFavicon key={i} link={link} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(MessageBubble);
