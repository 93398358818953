import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  to { transform: rotate(360deg); }
`;

const StyledButton = styled.button`
  --black-700: hsla(0, 0%, 12%, 1);
  --border_radius: 9999px;
  --transition: 0.3s ease-in-out;
  
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: transparent;
  border: none;
  border-radius: var(--border_radius);
  /* Reduzimos o aumento para 5% */
  transform: scale(calc(1 + (var(--active, 0) * 0.05)));
  transition: transform var(--transition);
  z-index: 1;
  
  &:hover,
  &:focus-visible {
    transform: scale(1.05);
    --active: 1;
  }
  
  &:active {
    transform: scale(1);
  }
  
  /* Pseudo-elemento ::before para efeito de fundo */
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: var(--black-700);
    border-radius: var(--border_radius);
    box-shadow: inset 0 0.5px hsl(0, 0%, 100%),
                inset 0 -1px 2px 0 hsl(0, 0%, 0%),
                0px 4px 10px -4px hsla(0, 0%, 0%, calc(1 - var(--active, 0))),
                0 0 0 calc(var(--active, 0) * 0.375rem) hsla(0, 0%, 10%, 0.75);
    transition: all var(--transition);
    z-index: -1;
  }
  
  /* Pseudo-elemento ::after com efeito mais dark (black) */
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    /* Efeito: de black com opacidade até transparente */
    background: linear-gradient(to top, hsla(0, 0%, 10%, 0.75), transparent);
    opacity: var(--active, 0);
    border-radius: var(--border_radius);
    transition: opacity var(--transition);
    z-index: 2;
  }
  
  /* Estilização do texto do botão */
  .text {
    position: relative;
    z-index: 10;
    font-size: 1rem;
    font-weight: 500;
    color: white;
  }
  
  /* Spinner animado */
  .spinner {
    width: 1rem;
    height: 1rem;
    border: 2px solid transparent;
    border-top-color: white;
    border-radius: 50%;
    animation: ${rotate} 1s linear infinite;
  }
`;

const ToggleReasoningButton = ({ onClick, active, loading }) => {
  return (
    <StyledButton onClick={onClick} aria-label={active ? "Ocultar raciocínio" : "Ver raciocínio"}>
      <span className="text">
        {active ? "Ocultar raciocínio" : "Ver raciocínio"} {active ? "▲" : "▼"}
      </span>
      {loading && <div className="spinner" />}
    </StyledButton>
  );
};

export default ToggleReasoningButton;
