import SpecialtyDAORemote from "../../services/dao/remote/SpecialtyDAORemote";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {message} from "antd";

export const useSpecialties = () => {
    const {data: specialties, isLoading} = useQuery({
        queryKey: ["specialties"],
        queryFn: SpecialtyDAORemote.list,
        refetchOnWindowFocus: false,
    });

    return {specialties, isLoading};
}

export const usePublishedSpecialties = () => {
    const {data: publishedSpecialties, isLoading} = useQuery({
        queryKey: ["publishedSpecialties"],
        queryFn: SpecialtyDAORemote.getPublished,
        refetchOnWindowFocus: false,
    });

    return {publishedSpecialties, isLoading};
}

export const useCreateSpecialty = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();

    const {mutate: createSpecialty, isLoading: isCreating} = useMutation({
        mutationFn: SpecialtyDAORemote.create,
        onSuccess: (specialty) => {
            queryClient.setQueryData(["specialties"], (specialties) => {
                return [...specialties, specialty];
            });
            onSuccess?.();
            message.success(`Especialidade "${specialty.name}" criada!`);
        },
        onError: (error) => {
            onError?.(error);
            message.error("Erro ao criar a especialidade");
            console.log(error);
        }
    });

    return {createSpecialty, isCreating};
}

export const useUpdateSpecialty = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();

    const {mutate: updateSpecialty, isLoading: isUpdating} = useMutation({
        mutationFn: SpecialtyDAORemote.update,
        onSuccess: (specialty) => {
            queryClient.setQueryData(["specialties"], (specialties) => {
                return specialties.map((s) => {
                    if (s.idSpecialty === specialty.idSpecialty) {
                        return specialty;
                    }
                    return s;
                });
            });
            onSuccess?.();
            message.success(`Especialidade "${specialty.name}" atualizada!`);
        },
        onError: (error) => {
            onError?.(error);
            message.error("Erro ao atualizar especialidade");
            console.log(error);
        }
    });

    return {updateSpecialty, isUpdating};
}

export const useDeleteSpecialty = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();

    const {mutate: deleteSpecialty, isLoading: isDeleting} = useMutation({
        mutationFn: SpecialtyDAORemote.remove,
        onSuccess: (idSpecialty) => {
            queryClient.setQueryData(["specialties"], (specialties) => {
                return specialties.filter((s) => s.idSpecialty !== idSpecialty);
            });
            onSuccess?.();
            message.success(`Especialidade deletada!`);
        },
        onError: (error) => {
            onError?.(error);
            message.error("Erro ao deletar especialidade");
            console.log(error);
        }
    });

    return {deleteSpecialty, isDeleting};
}

export const usePublishSpecialty = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();

    const {mutate: publishSpecialty, isLoading: isPublishing} = useMutation({
        mutationFn: SpecialtyDAORemote.publish,
        onSuccess: (specialty) => {
            queryClient.setQueryData(["specialties"], (specialties) => {
                return specialties.map((s) => {
                    if (s.idSpecialty === specialty.idSpecialty) {
                        return specialty;
                    }
                    return s;
                });
            });
            onSuccess?.();
            message.success(`Especialidade "${specialty.name}" publicada!`);
        },
        onError: (error) => {
            onError?.(error);
            message.error("Erro ao publicar especialidade");
            console.log(error);
        }
    });

    return {publishSpecialty, isPublishing};
}

export const useUnpublishSpecialty = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();

    const {mutate: unpublishSpecialty, isLoading: isUnpublishing} = useMutation({
        mutationFn: SpecialtyDAORemote.unpublish,
        onSuccess: (specialty) => {
            queryClient.setQueryData(["specialties"], (specialties) => {
                return specialties.map((s) => {
                    if (s.idSpecialty === specialty.idSpecialty) {
                        return specialty;
                    }
                    return s;
                });
            });
            onSuccess?.();
            message.success(`Especialidade "${specialty.name}" ocultada!`);
        },
        onError: (error) => {
            onError?.(error);
            message.error("Erro ao ocultar especialidade");
            console.log(error);
        }
    });

    return {unpublishSpecialty, isUnpublishing};
}
