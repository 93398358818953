// src/services/api.js

import axios from 'axios';
import { Auth } from 'aws-amplify';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

// Interceptor para requisições
api.interceptors.request.use(
    async (config) => {
        try {
            // Adiciona token de autenticação
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            // Adiciona headers para prevenir cache
            config.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
            config.headers['Pragma'] = 'no-cache';
            config.headers['Expires'] = '0';

            // Adiciona timestamp para evitar cache em GETs
            if (config.method === 'get') {
                config.params = {
                    ...config.params,
                    _t: Date.now()
                };
            }

            console.log('Requisição:', {
                url: config.url,
                method: config.method,
                headers: config.headers
            });

            return config;
        } catch (error) {
            console.error('Erro no interceptor de requisição:', error);
            return Promise.reject(error);
        }
    },
    (error) => {
        console.error('Erro na configuração da requisição:', error);
        return Promise.reject(error);
    }
);

// Interceptor para respostas
api.interceptors.response.use(
    (response) => {
        // Verifica se a resposta é HTML
        if (typeof response.data === 'string' && response.data.includes('<!doctype html>')) {
            console.error('Resposta HTML detectada:', response.data);
            return Promise.reject(new Error('Resposta HTML inválida recebida do servidor'));
        }

        // Log da resposta bem-sucedida
        console.log('Resposta recebida:', {
            url: response.config.url,
            status: response.status,
            data: response.data
        });

        return response;
    },
    (error) => {

        // Se receber 404, tenta adicionar prefixo /api
        if (error.response?.status === 404) {
            const originalRequest = error.config;
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                const newUrl = `/api${originalRequest.url}`;
                console.log('Tentando novamente com prefixo /api:', newUrl);
                return api(newUrl);
            }
        }

        return Promise.reject(error);
    }
);

export default api;