// src\components\playground\Playground.jsx

import {Col, Row} from "antd";
import PlaygroundAttributes from "./PlaygroundAttributes";
import PlaygroundPlaceholders from "./PlaygroundPlaceholders";
import "./Playground.css";
import React, {useEffect, useRef, useState} from "react";
import PlaygroundInput from "./PlaygroundInput";
import PlaygroundDAORemote from "../../services/dao/remote/PlaygroundDAORemote";
import PlaygroundResults from "./PlaygroundResults";
import _ from "lodash";
import {v4 as uuidv4} from "uuid";
import {replace} from "../../utils/functions/collection";
import StopGenerationButton from "../tools/StopGenerationButton";
import {useLocation} from "react-router-dom";
import MainHeaderTitle from "../main-header/MainHeaderTitle";
import {faFlask} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

const Playground = (props) => {
    const PlaygroundInputRef = useRef(null);
    const PlaygroundAttributesRef = useRef(null);

    const location = useLocation();

    const getResults = useQuery({
        queryKey: ['playgroundResults'],
        queryFn: () => PlaygroundDAORemote.getAll(),
        refetchOnWindowFocus: false,
    });

    const createResult = useMutation({
        mutationFn: (result) => PlaygroundDAORemote.create(result),
        onSuccess: (result) => {
            queryClient.setQueryData(["playgroundResults"], (old) => [result, ...old]);
        }
    });

    const removeResult = useMutation({
        mutationFn: (result) => PlaygroundDAORemote.remove(result),
        onSuccess: (result) => {
            queryClient.setQueryData(["playgroundResults"], (old) => {
                return old.filter((r) => r.idPlaygroundResult !== result.idPlaygroundResult)
            });
        }
    });

    const [isGenerating, setGenerating] = useState(false);
    const [results, setResults] = useState(getResults.data || []);

    const [prompt, setPrompt] = useState(null);
    const [values, setValues] = useState({});

    const resultsCache = useRef([]);
    const stopGeneration = useRef(false);

    const queryClient = useQueryClient();

    useEffect(() => {
        document.title = `Playground · CMOs.ai`;
        props.setHeaderTitle(
            <MainHeaderTitle
                icon={<FontAwesomeIcon icon={faFlask}/>}
                title="Playground"
                renderBack={location?.state?.tool}
            />
        );

        if (getResults.data) {
            const orderedResults = _.orderBy(getResults.data, (result) => new Date(result.createdAt), ['desc']);
            resultsCache.current = orderedResults;
            setResults(orderedResults);
        }

    }, [getResults.data, getResults.isLoading]);

    const handlePlaceholderClick = (placeholder) => {
        PlaygroundInputRef.current.setPlaceholderVariable(placeholder);
    }

    const handleValueChange = (value, field) => {
        const clone = _.cloneDeep(values);
        clone[field.token] = value;
        setValues(clone);
    }

    const handleGenerate = async (prompt) => {
        const specialty = PlaygroundAttributesRef.current.specialty;
        const generationModelClass = PlaygroundAttributesRef.current.generationModelClass;
        const temperature = PlaygroundAttributesRef.current.temperature;
        const maximumLength = PlaygroundAttributesRef.current.maximumLength;
        const brandVoice = PlaygroundAttributesRef.current.brandVoice;

        setGenerating(true);

        const emptyResult = createEmptyResult(specialty, generationModelClass, temperature, maximumLength, prompt, values, brandVoice);
        resultsCache.current.unshift(emptyResult);
        setResults(resultsCache.current);

        const {controller, reader} = await PlaygroundDAORemote.generate(
            {
                prompt, values, specialty, generationModelClass, temperature, maximumLength, brandVoice
            }
        );

        await processStreamReader(
            controller,
            reader,
            (text) => updateResult(text)
        );

        handleCreateResult(_.head(resultsCache.current));
        setGenerating(false);
    }

    const handleCreateResult = (playgroundResult) => {
        createResult.mutate(playgroundResult);
    }

    const handleStopGeneration = () => {
        stopGeneration.current = true;
        setTimeout(() => {
            stopGeneration.current = false;
        }, 1000);
    }

    const handleRemoveResult = (result) => {
        resultsCache.current = _.filter(results, r => r.idPlaygroundResult !== result.idPlaygroundResult);
        // setResults(resultsCache.current);
        removeResult.mutate(result.idPlaygroundResult);
    }

    const addResult = (result) => {
        const copy = _.cloneDeep(results);
        copy.push(result);
        setResults(copy);
    }

    const updateResult = (text) => {
        const result = _.cloneDeep(_.head(resultsCache.current));
        result.text += text;
        resultsCache.current = replace(result, resultsCache.current, {idPlaygroundResult: result.idPlaygroundResult});
        setResults(resultsCache.current);
    }

    const createEmptyResult = (specialty, generationModelClass, temperature, maximumLength, prompt, values, brandVoice) => {
        return {
            idPlaygroundResult: uuidv4(),
            text: "",
            specialty,
            generationModelClass,
            temperature,
            maximumLength,
            prompt,
            values,
            brandVoice,
            createdAt: new Date()
        }
    };

    const getResult = (idPlaygroundResult) => {
        return results.find(result => result.idPlaygroundResult === idPlaygroundResult);
    }

    const processStreamReader = async (controller, reader, callback) => {
        let done = false;
        while (!done) {
            if (stopGeneration.current) {
                controller.abort();
                done = true;
                break;
            }

            const {value, done: doneReading} = await reader.read();
            done = doneReading;

            const text = new TextDecoder("utf-8").decode(value);
            callback(text);
        }
    }

    const renderTitle = () => {
        return (
            <div className="PlaygroundTitle">
                Playground
            </div>
        );
    }

    const renderStopGenerationButton = () => {
        return (
            <StopGenerationButton
                onStop={handleStopGeneration}
                isGenerating={isGenerating}
            />
        );
    }

    const renderVariables = () => {
        if (location?.state?.tool) {
            return (
                <PlaygroundPlaceholders
                    tool={location.state.tool}
                    onClick={handlePlaceholderClick}
                    onValueChange={handleValueChange}
                />
            );
        }
    }

    const renderPlayground = () => {
        return (
            <>
                <div className="Playground">
                    {renderTitle()}
                    <PlaygroundInput
                        ref={PlaygroundInputRef}
                        isGenerating={isGenerating}
                        onGenerate={handleGenerate}
                        onPromptChange={setPrompt}
                    />
                    <PlaygroundResults
                        results={results}
                        isLoading={getResults.isLoading}
                        onRemove={handleRemoveResult}
                    />
                </div>
                {renderStopGenerationButton()}
            </>
        );
    }

    const renderAttributes = () => {
        return (
            <PlaygroundAttributes ref={PlaygroundAttributesRef}/>
        );
    }

    return (
        <div className="PlaygroundContainer">
            <Row style={{height: "100%"}}>
                <Col style={{height: "100%", overflow: "auto"}} span={6}>{renderVariables()}</Col>
                <Col style={{height: "100%", overflow: "auto"}} span={14}>{renderPlayground()}</Col>
                <Col style={{height: "100%", overflow: "auto"}} span={4}>{renderAttributes()}</Col>
            </Row>
        </div>
    );
}

export default Playground;