// src/components/main-header/UserDropdown.jsx

import {
    BarChartOutlined,
    CrownOutlined,
    LockOutlined,
    LogoutOutlined,
    TeamOutlined,
    UserOutlined
  } from "@ant-design/icons";
  import { Avatar, Dropdown } from "antd";
  import "./UserDropdown.css";
  import useIsMobile from "../../utils/hooks/useIsMobile";
  import { useNavigate } from "react-router-dom";
  
  // Importamos o ícone do "landmark" para representar "Treinamento em IA"
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faLandmark } from "@fortawesome/free-solid-svg-icons";
  
  const UserDropdown = ({ user, defaultPicture, onSignOut }) => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
  
    // Função para abrir o WhatsApp
    const handleOpenWhatsApp = () => {
      const whatsappUrl = "https://bit.ly/cmos-ai_treinamento_com_especialista";
      window.open(whatsappUrl, "_blank");
    };
  
    const handleMenuItem = (target) => {
      navigate(`/settings/${target}`);
    };
  
    // Itens do dropdown
    const renderMenuProfileItem = () => (
      <div className="UserDropdownItem" onClick={() => handleMenuItem("profile")}>
        <UserOutlined />
        <span>Meu perfil</span>
      </div>
    );
  
    const renderMenuSecurityItem = () => (
      <div className="UserDropdownItem" onClick={() => handleMenuItem("security")}>
        <LockOutlined />
        <span>Segurança</span>
      </div>
    );
  
    const renderMenuSubscriptionItem = () => (
      <div className="UserDropdownItem" onClick={() => handleMenuItem("subscription")}>
        <CrownOutlined />
        <span>Assinatura</span>
      </div>
    );
  
    const renderMenuWorkspaceItem = () => (
      <div className="UserDropdownItem" onClick={() => navigate("/workspace")}>
        <TeamOutlined />
        <span>Workspace</span>
      </div>
    );
  
    const renderMenuUsageItem = () => (
      <div className="UserDropdownItem" onClick={() => handleMenuItem("usage")}>
        <BarChartOutlined />
        <span>Créditos</span>
      </div>
    );
  
    const getProfilePictureUrl = () => {
      if (user.picture) {
        return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/${user.idUser}/64/${user.picture}`;
      } else if (defaultPicture) {
        return defaultPicture;
      }
      return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/default/64/default.png`;
    };
  
    const renderUserAvatar = () => {
      return <Avatar size={36} src={getProfilePictureUrl()} />;
    };
  
    const renderUserName = () => {
      if (isMobile) return null;
  
      const userName = user.name.split(" ")[0];
      return <div className="text-md text-neutral-800">{userName}</div>;
    };
  
    // Aqui definimos os itens do menu do usuário
    const items = [
      {
        label: renderMenuProfileItem(),
        key: "profile",
      },
      {
        label: renderMenuSecurityItem(),
        key: "security",
      },
      {
        label: renderMenuSubscriptionItem(),
        key: "subscription",
      },
      // Se quiser habilitar créditos, descomente:
      // {
      //   label: renderMenuUsageItem(),
      //   key: "usage",
      // },
      {
        label: renderMenuWorkspaceItem(),
        key: "workspace",
      },
      {
        type: "divider",
      },
      // Novo item: "Treinamento em IA"
      {
        label: (
          <div className="UserDropdownItem" onClick={handleOpenWhatsApp}>
            <FontAwesomeIcon icon={faLandmark} className="mr-2" />
            <span>Treinamento em IA</span>
          </div>
        ),
        key: "training",
      },
      {
        key: "logout",
        icon: <LogoutOutlined />,
        label: "Sair",
        onClick: () => onSignOut(),
      },
    ];
  
    // Renderização do dropdown do usuário
    const renderUserDropdown = () => {
      return (
        <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
          <div className="flex flex-row items-center leading-none gap-2 py-2 px-4 rounded-xl hover:bg-primary-bg hover:cursor-pointer transition-all ease-in-out duration-200">
            {renderUserAvatar()}
            <div className="flex flex-col gap-[2px] font-primary">
              {renderUserName()}
            </div>
          </div>
        </Dropdown>
      );
    };
  
    if (!user) return null;
  
    return <div>{renderUserDropdown()}</div>;
  };
  
  export default UserDropdown;
  