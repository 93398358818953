// src/components/text-input/TypewriterPlaceholder.jsx
import React, { useState, useEffect } from "react";
import "./TypewriterPlaceholder.css";

const TypewriterPlaceholder = ({
  sentences,
  typingSpeed = 100,    // velocidade de digitação (ms por caractere)
  pause = 1500,         // pausa ao final da digitação (ms)
  eraseSpeed = 50,      // velocidade de apagar (ms por caractere)
  restartPause = 1000,  // pausa após apagar antes de iniciar a próxima frase (ms)
  cursorChar = "|",     // caractere utilizado para o cursor
  loop = true,          // se verdadeiro, continua em loop com as frases
}) => {
  const [text, setText] = useState("");
  const [sentenceIndex, setSentenceIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    let timer;
    const currentSentence = sentences[sentenceIndex];

    if (isTyping) {
      // Modo de digitação
      if (charIndex < currentSentence.length) {
        timer = setTimeout(() => {
          setText((prev) => prev + currentSentence.charAt(charIndex));
          setCharIndex(charIndex + 1);
        }, typingSpeed);
      } else {
        // Frase completa; aguarda um tempo antes de iniciar o apagamento
        timer = setTimeout(() => {
          setIsTyping(false);
        }, pause);
      }
    } else {
      // Modo de apagar
      if (charIndex > 0) {
        timer = setTimeout(() => {
          setText((prev) => prev.slice(0, -1));
          setCharIndex(charIndex - 1);
        }, eraseSpeed);
      } else {
        // Após apagar tudo, aguarda restartPause e passa para a próxima frase (se loop for verdadeiro)
        if (loop) {
          timer = setTimeout(() => {
            setIsTyping(true);
            setSentenceIndex((prev) => (prev + 1) % sentences.length);
          }, restartPause);
        }
      }
    }
    return () => clearTimeout(timer);
  }, [charIndex, isTyping, sentences, sentenceIndex, typingSpeed, pause, eraseSpeed, restartPause, loop]);

  return (
    <div className="typewriter-placeholder">
      {text}
      <span className="cursor">{cursorChar}</span>
    </div>
  );
};

export default TypewriterPlaceholder;
