import GenerationModelsRemote from "../../services/dao/remote/generationModels";
import {useQuery} from "@tanstack/react-query";

export const useOpenAIModels = () => {
    const {data: openAIModels, isLoading} = useQuery({
        queryKey: ["openAIModels"],
        queryFn: GenerationModelsRemote.getOpenAIModels,
        refetchOnWindowFocus: false
    });

    return {openAIModels, isLoading};
}

export const useAnthropicModels = () => {
    const {data: anthropicModels, isLoading} = useQuery({
        queryKey: ["anthropicModels"],
        queryFn: GenerationModelsRemote.getAnthropicModels,
        refetchOnWindowFocus: false,
    });

    return {anthropicModels, isLoading};
}

export const useGenerationModelClasses = () => {
    const {data: generationModelClasses, isLoading} = useQuery({
        queryKey: ["generationModelClasses"],
        queryFn: GenerationModelsRemote.getGenerationModelClasses,
        refetchOnWindowFocus: false,
    });

    return {generationModelClasses, isLoading};
}