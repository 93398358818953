// src/pages/image-generator/community-gallery/CommunityGallery.jsx
import React, { useMemo, useState } from "react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import ImageRemote from "../../../services/dao/remote/ImageDAORemote";
import "./CommunityGallery.css";
import { message, Tooltip, Image } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { CopyOutlined, EyeOutlined } from "@ant-design/icons";

export default function CommunityGallery() {
    const queryClient = useQueryClient();
    const [previewImage, setPreviewImage] = useState('');
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isError,
    } = useInfiniteQuery(
        ['communityImages'],
        ({ pageParam = 1 }) => ImageRemote.getCommunityImages(pageParam, 20),
        {
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage || lastPage.length === 0) return undefined;
                return allPages.length + 1;
            },
        }
    );

    const images = useMemo(() => {
        return data?.pages.flat() || [];
    }, [data]);

    const loadMoreImages = () => {
        if (hasNextPage) {
            fetchNextPage();
        }
    };

    const handleImageError = (idImage) => {
        queryClient.setQueryData(['communityImages'], (oldData) => {
            if (!oldData) return oldData;
            return {
                ...oldData,
                pages: oldData.pages.map(page => page.filter(image => image.id_image !== idImage)),
            };
        });
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                message.success("Prompt copiado para a área de transferência!");
            },
            () => {
                message.error("Falha ao copiar o prompt.");
            }
        );
    };

    const renderImageItem = (image) => {
        const imageUrl = getImageUrl(image);

        if (!imageUrl) {
            return null;
        }

        return (
            <div key={image.id_image} className="gallery-item">
                <img
                    alt={image.title || "Imagem da Comunidade"}
                    src={imageUrl}
                    onError={() => handleImageError(image.id_image)}
                    className="gallery-image"
                    onClick={() => {
                        setPreviewImage(imageUrl);
                        setIsPreviewVisible(true);
                    }}
                />
                <div className="icon-container">
                    <Tooltip title="Visualizar imagem">
                        <EyeOutlined
                            className="icon"
                            onClick={() => {
                                setPreviewImage(imageUrl);
                                setIsPreviewVisible(true);
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Copiar prompt">
                        <CopyOutlined
                            className="icon"
                            onClick={() => copyToClipboard(image.prompt)}
                        />
                    </Tooltip>
                </div>
            </div>
        );
    };

    return (
        <div className="CommunityGallery">
            <h2>Galeria da Comunidade</h2>
            {isLoading ? (
                <div className="loading-container">
                    <p>Carregando...</p>
                </div>
            ) : isError ? (
                <div className="error-container">
                    <p>{error.message}</p>
                </div>
            ) : images.length > 0 ? (
                <>
                    <div
                        id="scrollableDiv"
                        style={{ height: '80vh', overflow: 'auto' }}
                    >
                        <InfiniteScroll
                            dataLength={images.length}
                            next={loadMoreImages}
                            hasMore={hasNextPage}
                            loader={<p style={{ textAlign: 'center', color: '#888' }}>Carregando mais imagens...</p>}
                            endMessage={
                                <p style={{ textAlign: 'center', color: '#888' }}>
                                    <b>Você chegou ao fim da galeria.</b>
                                </p>
                            }
                            scrollableTarget="scrollableDiv"
                            scrollThreshold={0.9}
                        >
                            <div className="gallery-grid">
                                {images.map((image) => renderImageItem(image))}
                            </div>
                        </InfiniteScroll>
                    </div>
                    <Image
                        src={previewImage}
                        style={{ display: 'none' }}
                        preview={{
                            visible: isPreviewVisible,
                            src: previewImage,
                            onVisibleChange: (value) => {
                                setIsPreviewVisible(value);
                            },
                        }}
                    />
                </>
            ) : (
                <div className="no-images-container">
                    <p>Nenhuma imagem encontrada.</p>
                </div>
            )}
        </div>
    );
}

// Função para obter a URL da imagem
const getImageUrl = (image) => {
    const prefixUrl = process.env.REACT_APP_TOOL_GENERATED_IMAGES_S3_BUCKET_PREFIX_URL;
    if (!prefixUrl) {
        console.error(
            "Variável de ambiente REACT_APP_TOOL_GENERATED_IMAGES_S3_BUCKET_PREFIX_URL não está definida."
        );
        return "";
    }
    if (!image.id_workspace || !image.filename || !image.extension) {
        console.warn("Dados da imagem incompletos:", image);
        return "";
    }
    const sanitizedPrefix = prefixUrl.replace(/\/+$/, "");
    return `${sanitizedPrefix}/${image.id_workspace}/${image.filename}.${image.extension}`;
};