// src\app\settings\subscription\components\SubscriptionPlanFeature.jsx

import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {InfoCircleOutlined} from "@ant-design/icons";
import {Modal, Tooltip} from "antd";
import useIsMobile from "../../../../utils/hooks/useIsMobile";

const SubscriptionPlanFeature = ({feature}) => {
    const [openDescriptionModal, setOpenDescriptionModal] = useState(false);

    const isMobile = useIsMobile();

    const renderDescription = () => {
        if (!feature.description) {
            return;
        }

        if (isMobile) {
            return (
                <>
                    <InfoCircleOutlined
                        className="text-primary-text-hover text-sm ml-1 hover:cursor-pointer"
                        onClick={() => setOpenDescriptionModal(true)}
                    />
                    <Modal
                        open={openDescriptionModal}
                        centered
                        footer={null}
                        onCancel={() => setOpenDescriptionModal(false)}
                        title={
                            <div>
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="text-primary-text-hover mr-2"
                                />
                                <label className="text-lg font-bold text-primary-text">
                                    {feature.title}
                                </label>
                            </div>
                        }
                    >
                        <label className="text-reg font-sans">
                            {feature.description}
                        </label>
                    </Modal>
                </>
            );
        } else {
            return (
                <Tooltip title={feature.description} placement="right">
                    <InfoCircleOutlined className="text-primary-text-hover text-sm ml-1 hover:cursor-pointer"/>
                </Tooltip>
            );
        }
    }

    return (
        <div className="font-sans my-1 ml-2 flex items-center text-reg">
            <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-primary-text-hover mr-2"
            />
            <label className="text-primary-text">{feature.title}</label>
            {renderDescription()}
        </div>
    );
};

export default SubscriptionPlanFeature;