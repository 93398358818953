// src/app/marketing-research/marketing-research.jsx

import React, { useRef, useState, useEffect } from "react";
import { Card } from "./ui/card.tsx";
import DeepSeekQuickChat from "./DeepSeekQuickChat.jsx";
import MobileUnavailable from "./ui/MobileUnavailable";

// Array com todas as sessões (atualizado com emojis personalizados)
const sidebarCards = [
  {
    sessionId: "session1",
    type: "normal",
    emoji: "🎵",
    title: "As Músicas que Estão Bombando no TikTok e Instagram",
    image: "/images/musicasvirais.jpg",
    featured: {
      image: "/images/featured1.png",
      alt: "Featured image 1",
      title: "Músicas que bombam nas redes sociais",
    },
    bottomCards: [
      {
        icon: "🎸",
        title: "O impacto das trends musicais nas redes sociais",
        bg: "bg-[#fff8f3]",
        hiddenPrompt:
          "Realize uma análise detalhada sobre como as músicas virais estão influenciando as tendências de conteúdo no TikTok e Instagram no mercado brasileiro, me passe dicas de trends para fazer nas redes sociais com foco no mercado brasileiro, incluindo dados recentes, estudos de caso e métricas atuais. Busque informações atuais.",
      },
      {
        icon: "🎶",
        title: "Quais são as músicas que estão bombando no Brasil hoje?",
        bg: "bg-[#f3f9ff]",
        hiddenPrompt:
          "Forneça uma análise aprofundada das músicas que estão bombando hoje e são virais no brasil hoje e estão impulsionando o crescimento de artistas e influenciadores no Brasil, com base em dados e estudos atuais. Busque informações atuais.",
      },
      {
        icon: "🎤",
        title: "Os bastidores das trilhas sonoras que dominam o TikTok e o Instagram",
        bg: "bg-[#fff8e6]",
        hiddenPrompt:
          "Descreva os segredos e estratégias por trás das trilhas sonoras que se tornam hits nas redes sociais do brasil hoje, embasando a resposta em análises e estudos recentes com foco no mercado brasileiro. Busque informações atuais.",
      },
      {
        icon: "🎧",
        title: "Dicas para usar músicas virais e aumentar o engajamento do seu conteúdo",
        bg: "bg-[#fff3f8]",
        hiddenPrompt:
          "Explique como escolher e utilizar músicas virais para aumentar o engajamento de conteúdos em redes sociais, me passe as músicas que estão em alta no brasil hoje e com recomendações práticas e exemplos baseados em dados atuais e estudos recentes, especialmente no Brasil. Busque informações atuais.",
      },
    ],
  },
  {
    sessionId: "session2",
    type: "normal",
    emoji: "✉️",
    title: "Escreva e-mails melhores",
    image: "/images/featured2.png",
    featured: {
      image: "/images/featured2principal.png",
      alt: "Featured image 2",
      title: "Melhore a comunicação com seus e-mails",
    },
    bottomCards: [
      {
        icon: "📧",
        title: "Dicas para e-mails mais claros",
        bg: "bg-[#fef9f3]",
        hiddenPrompt:
          "Forneça uma análise detalhada com dicas e estratégias para escrever e-mails claros e objetivos, embasando a resposta em estudos e dados atuais. Busque informações atuais.",
      },
      {
        icon: "📄",
        title: "Estrutura ideal para um e-mail",
        bg: "bg-[#f3fffa]",
        hiddenPrompt:
          "Descreva qual a estrutura ideal para um e-mail profissional eficiente, com recomendações baseadas em melhores práticas e dados atuais. Busque informações atuais.",
      },
      {
        icon: "📢",
        title: "Assuntos que chamam a atenção",
        bg: "bg-[#f9f3ff]",
        hiddenPrompt:
          "Apresente quais tipos de assunto de e-mail geram maior taxa de abertura para profissionais de marketing, com exemplos práticos e referências atuais. Busque informações atuais.",
      },
      {
        icon: "📫",
        title: "Aumente a taxa de resposta",
        bg: "bg-[#fff3e6]",
        hiddenPrompt:
          "Explique como otimizar e-mails para obter maior taxa de resposta, detalhando estratégias e dados atuais que comprovem a eficácia das práticas sugeridas. Busque informações atuais.",
      },
    ],
  },
  {
    sessionId: "tool1",
    type: "normal",
    emoji: "🔥",
    title: "Monitoramento de Tendências e Virais em Tempo Real",
    image: "/images/tool1.png",
    featured: {
      image: "/images/toolee1.png",
      alt: "Tendências em Tempo Real",
      title: "Monitoramento de Trends e Virais",
    },
    bottomCards: [
      {
        icon: "⏰",
        title: "Alertas de Trends Instantâneos",
        bg: "bg-[#e0f7fa]",
        hiddenPrompt:
          "Realize uma análise detalhada de tendências emergentes nas redes sociais no brasil neste momento, identificando os tópicos que estão ganhando destaque em tempo real com dados recentes, estudos de caso e métricas atuais. Busque informações atuais do mercado brasileiro.",
      },
      {
        icon: "😊",
        title: "Análise de Sentimento Viral",
        bg: "bg-[#e8f5e9]",
        hiddenPrompt:
          "Forneça uma análise aprofundada do sentimento por trás das tendências na área de cosméticos virais atuais, incluindo referências a estudos e dados recentes que expliquem o impacto emocional dos cosméticos nas redes sociais. Busque informações atuais do mercado brasileiro.",
      },
      {
        icon: "🔗",
        title: "Integração Multiplataforma para Trends",
        bg: "bg-[#f3e5f5]",
        hiddenPrompt:
          "Integre dados de múltiplas plataformas para monitorar tendências na área da beleza de forma eficaz, me passe todos esses dados, estudos de caso e dados atualizados para me ajudar a viralizar. Busque informações atuais do mercado brasileiro.",
      },
      {
        icon: "🚀",
        title: "Otimização de Campanhas Baseadas em Trends",
        bg: "bg-[#fffde7]",
        hiddenPrompt:
          "Explique como adaptar minhas estratégias de marketing com base em tendências emergentes no brasil, fornecendo recomendações detalhadas dos dias atuais, estudos de caso e dados atuais que comprovem a eficácia das práticas sugeridas. Busque informações atuais.",
      },
    ],
  },
  {
    sessionId: "tool2",
    type: "normal",
    emoji: "🤖",
    title: "Análise Automatizada da Concorrência em Tempo Real",
    image: "/images/tool2.png",
    featured: {
      image: "/images/featured_tool2.png",
      alt: "Análise da Concorrência",
      title: "Radar Competitivo Automático",
    },
    bottomCards: [
      {
        icon: "📡",
        title: "Radar Competitivo Automático",
        bg: "bg-[#e3f2fd]",
        hiddenPrompt:
          "Realize um radar competitivo detalhado que analise em tempo real as estratégias, campanhas, preços e posicionamento dos principais concorrentes do meu setor no Brasil como Nubank, Ambev, Vale, fundamentando a análise com dados atuais e estudos recentes da internet. Busque informações atuais.",
      },
      {
        icon: "🚨",
        title: "Alertas de Mudanças Competitivas",
        bg: "bg-[#fce4ec]",
        hiddenPrompt:
          "Identifique e analise alterações significativas nas estratégias e comportamentos dos concorrentes no Brasil como a rede de lojas Magazine Luiza e varejistas como grupos de supermercados atacadistas, detalhando os impactos dessas mudanças com base em dados e estudos atuais do mercado brasileiro. Busque informações atuais.",
      },
      {
        icon: "📈",
        title: "Comparativo Estratégico Instantâneo",
        bg: "bg-[#e8f5e9]",
        hiddenPrompt:
          "Gere um comparativo estratégico completo entre minha marca e os principais concorrentes no Brasil como a rede de lojas Havan e varejistas como Atacadão, incluindo métricas de desempenho, estratégias de marketing e posicionamento, embasado em informações e estudos de mercado recentes do Brasil. Busque informações atuais.",
      },
      {
        icon: "🔍",
        title: "Análise de Campanhas dos Concorrentes",
        bg: "bg-[#fff3e0]",
        hiddenPrompt:
          "Forneça uma análise detalhada do desempenho das campanhas de marketing dos meus concorrentes aqui no mercado brasileiro, identificando pontos fortes, fraquezas e oportunidades, com dados atuais e referências de estudos recentes, com foco no mercado brasileiro. Busque informações atuais.",
      },
    ],
  },
  {
    sessionId: "tool3",
    type: "normal",
    emoji: "💡",
    title: "Identificação de Oportunidades de Mercado e Lacunas Não Exploradas",
    image: "/images/tool3.png",
    featured: {
      image: "/images/featured_tool3.png",
      alt: "Oportunidades de Mercado",
      title: "Mapeamento de White Spaces",
    },
    bottomCards: [
      {
        icon: "🗺️",
        title: "Mapeamento de White Spaces",
        bg: "bg-[#f1f8e9]",
        hiddenPrompt:
          "Realize uma análise abrangente para identificar segmentos de mercado e lacunas não exploradas no brasil, fundamentada em dados atuais, pesquisas de mercado e estudos recentes com foco no Brasil. Busque informações atuais.",
      },
      {
        icon: "🗣️",
        title: "Análise de Feedback do Consumidor",
        bg: "bg-[#e8eaf6]",
        hiddenPrompt:
          "Forneça uma análise detalhada do feedback geral dos consumidores brasileiros com relação ao mercado, identificando reclamações recorrentes e oportunidades de inovação, com base em dados e estudos atuais, considerando o comportamento dos consumidores no Brasil. Busque informações atuais.",
      },
      {
        icon: "🌱",
        title: "Detecção de Nichos Emergentes",
        bg: "bg-[#fff3e0]",
        hiddenPrompt:
          "Identifique e descreva nichos de mercado emergentes que ainda não foram explorados no brasil, utilizando dados atuais e tendências recentes, com referências a estudos de mercado no Brasil. Busque informações atuais.",
      },
      {
        icon: "💡",
        title: "Insights de Demanda Oculta",
        bg: "bg-[#e0f7fa]",
        hiddenPrompt:
          "Forneça insights detalhados sobre demandas ocultas do mercado brasileiro, utilizando dados atuais e estudos recentes para identificar oportunidades não atendidas, com foco no mercado brasileiro. Busque informações atuais.",
      },
    ],
  },
  {
    sessionId: "tool4",
    type: "normal",
    emoji: "🎯",
    title: "Geração de Estratégias Personalizadas com Base no Comportamento do Consumidor",
    image: "/images/tool4.png",
    featured: {
      image: "/images/featured_tool4.png",
      alt: "Estratégias Personalizadas",
      title: "Planos de Marketing Sob Medida",
    },
    bottomCards: [
      {
        icon: "🤖",
        title: "Segmentação Avançada com IA",
        bg: "bg-[#e8f5e9]",
        hiddenPrompt:
          "Realize uma análise aprofundada para criar segmentos de consumidores brasileiros com base em seus comportamentos recentes, utilizando dados e estudos atuais para fornecer insights acionáveis, com foco no mercado brasileiro. Busque informações atuais.",
      },
      {
        icon: "📋",
        title: "Planos de Marketing Sob Medida",
        bg: "bg-[#fce4ec]",
        hiddenPrompt:
          "Desenvolva um plano de marketing personalizado que integre dados atuais sobre o comportamento do consumidor brasileiro, com recomendações detalhadas e exemplos baseados em estudos recentes, com ênfase no mercado brasileiro. Busque informações atuais.",
      },
      {
        icon: "📊",
        title: "Otimização de Canais por Comportamento",
        bg: "bg-[#e3f2fd]",
        hiddenPrompt:
          "Forneça uma análise detalhada para identificar quais canais de marketing são mais eficazes para cada segmento de público brasileiro, utilizando dados atuais e referências recentes, com foco no mercado brasileiro. Busque informações atuais.",
      },
      {
        icon: "✨",
        title: "Estratégias Hiper-Personalizadas",
        bg: "bg-[#f1f8e9]",
        hiddenPrompt:
          "Descreva estratégias hiper-personalizadas para campanhas de marketing brasileiras com base em notícias atuais, adaptadas a micro-segmentos do público, fundamentadas em dados atuais e estudos recentes, com foco no mercado brasileiro. Busque informações atuais.",
      },
    ],
  },
  {
    sessionId: "tool5",
    type: "normal",
    emoji: "🔄",
    title: "Detecção de Mudanças no Comportamento do Público-Alvo e Ajustes Estratégicos",
    image: "/images/tool5.png",
    featured: {
      image: "/images/featured_tool5.png",
      alt: "Mudanças no Comportamento",
      title: "Sismógrafo do Público-Alvo",
    },
    bottomCards: [
      {
        icon: "🚦",
        title: "Monitoramento de Sinais de Churn",
        bg: "bg-[#fff3e0]",
        hiddenPrompt:
          "Realize uma análise detalhada para identificar sinais precoces de churn e mudanças no comportamento dos clientes brasileiros, fundamentada em dados atuais e estudos recentes, com foco no mercado brasileiro. Busque informações atuais.",
      },
      {
        icon: "🔄",
        title: "Ajustes Estratégicos em Tempo Real",
        bg: "bg-[#e0f7fa]",
        hiddenPrompt:
          "Forneça recomendações detalhadas para ajustes estratégicos imediatos com base em mudanças recentes no comportamento do público brasileiro, utilizando dados atuais e análises recentes, com foco no mercado brasileiro. Busque informações atuais.",
      },
      {
        icon: "💬",
        title: "Análise de Engajamento e Sentimento",
        bg: "bg-[#e8f5e9]",
        hiddenPrompt:
          "Realize uma análise aprofundada das métricas de engajamento e do sentimento do público brasileiro, integrando dados atuais e estudos recentes para orientar ajustes estratégicos, com foco no mercado brasileiro. Busque informações atuais.",
      },
      {
        icon: "🚨",
        title: "Alertas de Mudança de Preferência",
        bg: "bg-[#fce4ec]",
        hiddenPrompt:
          "Configure e descreva alertas precisos para mudanças súbitas nas preferências dos consumidores brasileiros, baseando-se em dados atuais e análises recentes, para orientar ajustes estratégicos imediatos, com foco no mercado brasileiro. Busque informações atuais.",
      },
    ],
  },
  {
    sessionId: "tool6",
    type: "normal",
    emoji: "🔮",
    title: "Previsão de Impacto de Campanhas Antes do Lançamento",
    image: "/images/tool6.png",
    featured: {
      image: "/images/featured_tool6.png",
      alt: "Previsão de Campanhas",
      title: "Simulação de Cenários de Campanha",
    },
    bottomCards: [
      {
        icon: "🎲",
        title: "Simulação de Cenários de Campanha",
        bg: "bg-[#e3f2fd]",
        hiddenPrompt:
          "Realize simulações detalhadas de diferentes cenários de campanha utilizando dados históricos e atuais, fornecendo previsões precisas e fundamentadas em estudos recentes, com foco no mercado brasileiro. Busque informações atuais.",
      },
      {
        icon: "📊",
        title: "Modelos Preditivos para Marketing",
        bg: "bg-[#e8f5e9]",
        hiddenPrompt:
          "Explique quais dados históricos e atuais são essenciais para construir modelos preditivos precisos para campanhas de marketing, com base em análises e estudos recentes, considerando o contexto do mercado brasileiro. Busque informações atuais.",
      },
      {
        icon: "💸",
        title: "Otimização de Investimentos em Campanhas",
        bg: "bg-[#fff3e0]",
        hiddenPrompt:
          "Forneça recomendações detalhadas para otimizar o investimento em campanhas de marketing no brasil, utilizando modelos preditivos e dados atuais, embasados em estudos recentes e exemplos práticos, com ênfase no mercado brasileiro. Busque informações atuais.",
      },
      {
        icon: "🕹️",
        title: "Teste Virtual de Estratégias de Campanha",
        bg: "bg-[#fce4ec]",
        hiddenPrompt:
          "Realize testes virtuais de diferentes estratégias de campanhas, fornecendo previsões detalhadas e análises comparativas baseadas em dados atuais e estudos recentes para orientar a escolha da melhor abordagem, com foco no mercado brasileiro. Busque informações atuais.",
      },
    ],
  },
  {
    sessionId: "tool7",
    type: "normal",
    emoji: "🤝",
    title: "Sugestão de Influenciadores Alinhados com a Marca em Cada Momento",
    image: "/images/tool7.png",
    featured: {
      image: "/images/featured_tool7.png",
      alt: "Influenciadores Alinhados",
      title: "Matching de Influenciadores com IA",
    },
    bottomCards: [
      {
        icon: "🤝",
        title: "Matching de Influenciadores com IA",
        bg: "bg-[#e8f5e9]",
        hiddenPrompt:
          "Realize uma análise aprofundada para identificar influenciadores no Brasil que se alinhem perfeitamente com os valores e o público da minha marca, utilizando dados atuais e estudos recentes para fundamentar a seleção. Busque informações atuais.",
      },
      {
        icon: "🔍",
        title: "Análise de Autenticidade de Influenciadores",
        bg: "bg-[#f1f8e9]",
        hiddenPrompt:
          "Forneça uma análise detalhada que valide a autenticidade e o engajamento real dos influenciadores, utilizando métricas e dados atuais, com referências a estudos recentes, com foco no mercado brasileiro. Busque informações atuais.",
      },
      {
        icon: "🌟",
        title: "Identificação de Tendências entre Influenciadores",
        bg: "bg-[#fce4ec]",
        hiddenPrompt:
          "Identifique quais influenciadores no Brasil estão em alta no momento, baseando-se em dados e tendências atuais, e forneça uma análise detalhada do potencial de cada um, com suporte em estudos recentes. Busque informações atuais.",
      },
      {
        icon: "💼",
        title: "Recomendações Personalizadas para Parcerias",
        bg: "bg-[#e3f2fd]",
        hiddenPrompt:
          "Forneça recomendações detalhadas para parcerias com influenciadores, baseadas em uma análise aprofundada de seus perfis, engajamento e alinhamento com a marca, utilizando dados atuais e estudos recentes, com foco no mercado brasileiro. Busque informações atuais.",
      },
    ],
  },
  {
    sessionId: "tool8",
    type: "normal",
    emoji: "📊",
    title: "Automação de Relatórios de Desempenho e ROI de Campanhas",
    image: "/images/tool8.png",
    featured: {
      image: "/images/featured_tool8.png",
      alt: "Relatórios Automatizados",
      title: "Relatórios Automatizados com IA",
    },
    bottomCards: [
      {
        icon: "🤖",
        title: "Relatórios Automatizados com IA",
        bg: "bg-[#e8f5e9]",
        hiddenPrompt:
          "Gere um relatório automatizado e detalhado de campanhas de marketing brasileiras, incluindo análise de desempenho, ROI e insights estratégicos, fundamentado em dados atuais e estudos recentes. Busque informações atuais.",
      },
      {
        icon: "📊",
        title: "Análise de Desempenho Multiplataforma",
        bg: "bg-[#fff3e0]",
        hiddenPrompt:
          "Forneça uma análise detalhada de campanhas de marketing atuais brasileiras que consolide dados de diversas plataformas de marketing em um único relatório, utilizando informações atuais e estudos recentes para embasar as conclusões. Busque informações atuais.",
      },
      {
        icon: "📝",
        title: "Insights Narrativos de Campanhas",
        bg: "bg-[#fce4ec]",
        hiddenPrompt:
          "Transforme dados brutos de grandes campanhas brasileiras atuais em insights narrativos detalhados, fornecendo análises aprofundadas e referências a estudos recentes, com base em informações atuais. Busque informações atuais.",
      },
      {
        icon: "📈",
        title: "Dashboard Interativo de ROI",
        bg: "bg-[#e3f2fd]",
        hiddenPrompt:
          "Descreva como criar um dashboard interativo que visualize o ROI de campanhas de marketing, utilizando dados atuais e estudos recentes para fornecer insights estratégicos detalhados. Busque informações atuais.",
      },
    ],
  },
];

// Definindo as fileiras (rows) para exibir os cards com layouts variados e imagens maiores
const sidebarRows = [
  {
    layout: "grid grid-cols-2 gap-4",
    cards: [sidebarCards[0], sidebarCards[1]],
    cardHeight: "h-56",
  },
  {
    layout: "grid grid-cols-1 gap-4",
    cards: [sidebarCards[2]],
    cardHeight: "h-44",
  },
  {
    layout: "grid grid-cols-1 gap-4",
    cards: [sidebarCards[3]],
    cardHeight: "h-72",
  },
  {
    layout: "grid grid-cols-2 gap-4",
    cards: [sidebarCards[4], sidebarCards[5]],
    cardHeight: "h-56",
  },
  {
    layout: "grid grid-cols-1 gap-4",
    cards: [sidebarCards[6]],
    cardHeight: "h-44",
  },
  {
    layout: "grid grid-cols-2 gap-4",
    cards: [sidebarCards[7], sidebarCards[8]],
    cardHeight: "h-56",
  },
  {
    layout: "grid grid-cols-1 gap-4",
    cards: [sidebarCards[9]],
    cardHeight: "h-44",
  },
];

export default function MarketingResearchPage() {
  const [currentSession, setCurrentSession] = useState(sidebarCards[0]);
  const deepSeekRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  // Verifica se é mobile (exemplo: largura menor que 768px)
  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const handleSwitchSession = (card) => {
    setCurrentSession(card);
    if (deepSeekRef.current?.resetMessages) {
      deepSeekRef.current.resetMessages();
    }
  };

  const handleSuggestionClick = (label, hiddenPrompt) => {
    if (deepSeekRef.current) {
      deepSeekRef.current.sendHiddenPrompt(label, hiddenPrompt);
    }
  };

  // Se for mobile, renderiza a tela de aviso
  if (isMobile) {
    return (
      <div className="h-screen overflow-hidden bg-[#faf9f6] flex justify-center items-center font-sans">
        <MobileUnavailable />
      </div>
    );
  }

  return (
    // Container com o fundo original (#faf9f6)
    <div className="h-screen overflow-hidden bg-[#faf9f6] flex justify-center font-sans">
      <div className="w-full max-w-[1440px] flex">
        {/* Sidebar (fundo transparente e com espaçamentos reduzidos) */}
        <aside className="w-[320px] border-r border-neutral-100 p-4 space-y-4 h-full overflow-y-auto shadow-lg rounded-lg animate-fadeIn">
          <div>
            <h1 className="text-2xl font-black text-gray-800">
              <span
                className="block bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 bg-clip-text text-transparent 
                           animate-pulse transition-transform duration-500 transform hover:scale-105"
              >
                Apresentamos:
              </span>
              <span className="block mt-1 text-3xl font-bold text-gray-800">
                <span
                  className="ml-1 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 bg-clip-text text-transparent 
                             animate-pulse transition-transform duration-500 transform hover:scale-105"
                >
                  Marketing Research
                </span>
              </span>
            </h1>
          </div>

          {/* Card de Apresentação com espaçamento reduzido */}
          <Card className="p-3 bg-transparent rounded-3xl shadow-lg transition-shadow hover:shadow-2xl border border-neutral-200">
            <div className="flex flex-col gap-1">
              <h3 className="text-xl font-bold text-gray-800">
                Descubra as tendências antes de todo mundo!
              </h3>
              <p className="text-base font-medium text-gray-700">
                Seja para criar, se inspirar ou viralizar, aqui você encontra tudo o que precisa para sair na frente!
              </p>
            </div>
          </Card>

          {/* Grid de sessões organizadas por fileiras */}
          <div className="space-y-6 mt-6">
            {sidebarRows.map((row, rowIndex) => (
              <div key={rowIndex} className={row.layout}>
                {row.cards.map((card, cardIndex) => {
                  let cardClasses =
                    "cursor-pointer relative overflow-hidden rounded-3xl shadow-lg transition-transform transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500 bg-transparent";
                  if (row.cardHeight) {
                    cardClasses += ` ${row.cardHeight}`;
                  } else {
                    cardClasses += row.layout.includes("grid-cols-2")
                      ? " h-56"
                      : " h-64";
                  }
                  return (
                    <div
                      key={cardIndex}
                      onClick={() => handleSwitchSession(card)}
                      tabIndex={0}
                      role="button"
                      aria-label={`Selecionar sessão: ${card.title}`}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          handleSwitchSession(card);
                        }
                      }}
                    >
                      <Card className={cardClasses}>
                        {card.image && (
                          <img
                            src={card.image}
                            alt={card.title}
                            className="object-cover w-full h-full transition-transform duration-500 ease-in-out hover:scale-110"
                          />
                        )}
                        {/* Emoji Badge */}
                        {card.emoji && (
                          <div className="absolute top-2 left-2 bg-white rounded-full p-1 shadow-md">
                            <span className="text-xl">{card.emoji}</span>
                          </div>
                        )}
                        {/* Sobreposição com gradiente para realçar o título */}
                        <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/60 to-transparent p-4 flex items-end">
                          <div className="px-2 py-1 rounded drop-shadow-md">
                            <p className="text-base font-bold text-white leading-tight">
                              {card.title}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </aside>

        {/* Conteúdo Principal */}
        <div className="flex-1 overflow-hidden">
          <DeepSeekQuickChat
            ref={deepSeekRef}
            currentSession={currentSession}
            handleSuggestionClick={handleSuggestionClick}
          />
        </div>
      </div>
    </div>
  );
}
