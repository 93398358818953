// src/components/message/Message.jsx

import React, { useEffect, useRef, useState, useMemo } from "react";
import "./Message.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // Suporte para Markdown no estilo GitHub
import rehypeHighlight from "rehype-highlight"; // Realce de sintaxe
import rehypeSanitize, { defaultSchema } from "rehype-sanitize"; // Sanitização
import { Avatar, Tag, Button } from "antd";
import Logo from "../../images/message.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faFilePdf,
  faFileAlt,
  faImage,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBullhorn,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  faRedhat,
  // ... outros ícones de brands
} from "@fortawesome/free-brands-svg-icons";
import PropTypes from "prop-types";
import "prismjs/themes/prism.css"; // Tema do Prism.js
import { Tooltip } from "react-tooltip";

const Type = React.memo((props) => <div {...props} />);
const Content = React.memo((props) => <div {...props} />);
const Artifact = React.memo((props) => <div {...props} />);

const customSchema = {
  ...defaultSchema,
  tagNames: [...defaultSchema.tagNames, "type", "content", "artifact"],
  attributes: {
    ...defaultSchema.attributes,
    type: ["*"],
    content: ["*"],
    artifact: ["*"],
  },
};

const Message = ({
  idChat,
  role,
  content,
  reasoningContent = "",
  timestamp,
  onboard = false,
  user,
  picture,
  specialty,
  files, // array de strings com URIs de arquivos
  brandVoice,
  isMobile = false,
  onArtifactPreview,
  generationModelUsed = "",
  onStartTyping = () => {},
  onFinishTyping = () => {},
  scrollDown = () => {},
  citations = [], // <-- Recebe as citações (URLs) aqui
}) => {
  const [typedMessage, setTypedMessage] = useState("");
  const [localCitations, setLocalCitations] = useState([]);
  const [showReasoning, setShowReasoning] = useState(true);
  const typeTimer = useRef(null);
  const isDeepSeek = generationModelUsed === "deepseek-r1";

  // Componentes padrão para renderizar Markdown, incluindo tabelas
    const markdownComponents = {
      table: ({ node, ...props }) => (
        <div className="overflow-x-auto my-4">
          <table className="min-w-full table-auto border-collapse border border-gray-300 rounded-sm" {...props} />
        </div>
      ),
      thead: ({ node, ...props }) => <thead className="bg-gray-100" {...props} />,
      tbody: ({ node, ...props }) => <tbody className="bg-white divide-y divide-gray-200" {...props} />,
      tr: ({ node, ...props }) => <tr className="hover:bg-gray-50 even:bg-gray-50" {...props} />,
      th: ({ node, ...props }) => (
        <th className="px-4 py-2 text-xs font-semibold text-gray-700 border border-gray-300 uppercase tracking-wide text-left" {...props} />
      ),
      td: ({ node, ...props }) => (
        <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300" {...props} />
      ),
      code: ({ node, inline, className, children, ...props }) => {
        const match = /language-(\w+)/.exec(className || '');
        return !inline ? (
          <pre className="bg-gray-800 text-white p-3 rounded-md overflow-x-auto my-4" {...props}>
            <code className={className}>{children}</code>
          </pre>
        ) : (
          <code className="bg-gray-100 text-red-500 p-1 rounded" {...props}>
            {children}
          </code>
        );
      },
      heading: ({ node, level, ...props }) => {
        const sizes = [
          'text-2xl',
          'text-xl',
          'text-lg',
          'text-base',
          'text-sm',
          'text-xs',
        ];
        const size = sizes[level - 1] || sizes[2];
        return React.createElement(
          'h' + level,
          { className: `font-bold ${size} my-4`, ...props },
          props.children
        );
      },
      blockquote: ({ node, ...props }) => (
        <blockquote className="border-l-4 border-gray-300 pl-4 italic text-gray-600 my-4" {...props} />
      ),
      hr: ({ node, ...props }) => (
        <hr className="my-8 border-0 h-px bg-gradient-to-r from-transparent via-gray-300 to-transparent" {...props} />
      ),
    };

  // [ADDED]: Função para remover o bloco ||METADATA||...||ENDMETADATA||
  function removeMetadata(str) {
    if (!str) return "";

    // [ADDED] Captura o bloco METADATA se existir
    const metadataRegex = /\|\|METADATA\|\|(.*?)\|\|ENDMETADATA\|\|/s;
    const match = str.match(metadataRegex);
    if (match) {
      try {
        const metaJson = JSON.parse(match[1]);
        // [ADDED] Se o JSON tiver citations, salvamos em localCitations
        if (Array.isArray(metaJson.citations)) {
          setLocalCitations(metaJson.citations);
        }
      } catch (err) {
        console.error("Erro ao parsear METADATA:", err);
      }
    }

    // [ADDED] Remove todo o ||METADATA||...||ENDMETADATA|| do texto
    return str.replace(metadataRegex, "");
  }

  // ----------------------------------------------------------------------
  // 1) PARSE DO content COMO JSON PARA EXTRAIR text E file_uri
  // ----------------------------------------------------------------------
  let displayedText = content;
  let extraFileUri = null;
  try {
    const parsed = JSON.parse(content);
    if (parsed && typeof parsed === "object") {
      displayedText = parsed.text || "";
      extraFileUri = parsed.file_uri || null;
    }
  } catch (err) {
    // Se não for JSON, ignoramos
  }
  // Garantir que displayedText seja uma string
  displayedText = typeof displayedText === "string" ? displayedText : String(displayedText);

  // ----------------------------------------------------------------------
  // 2) UNIFICAR file_uri do JSON c/ array de files
  // ----------------------------------------------------------------------
  let allFiles = Array.isArray(files) ? [...files] : [];
  if (extraFileUri) {
    allFiles.push(extraFileUri);
  }

  // ----------------------------------------------------------------------
  // 3) Cálculo de snippet de código
  // ----------------------------------------------------------------------
  const codeSnippet = useMemo(() => detectCodeSnippet(displayedText), [displayedText]);

  // Se a mensagem for "onboard", tipagem animada
  useEffect(() => {
    if (onboard) {
      typeText(displayedText);
    }
    return () => {
      if (typeTimer.current) {
        clearInterval(typeTimer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboard, displayedText]);

  const typeText = (text) => {
    onStartTyping();
    let i = 0;
    let accumulatedText = "";

    if (typeTimer.current) {
      clearInterval(typeTimer.current);
    }
    typeTimer.current = setInterval(() => {
      if (i >= text.length) {
        clearInterval(typeTimer.current);
        setTypedMessage(text);
        onFinishTyping();
        return;
      }
      accumulatedText += text.charAt(i);
      // a cada 10 caracteres, ou no final, atualizar o estado
      if (i % 10 === 0 || i === text.length - 1) {
        setTypedMessage((prev) => prev + accumulatedText);
        accumulatedText = "";
        scrollDown();
      }
      i++;
    }, 15);
  };

  // ----------------------------------------------------------------------
  // 4) Renderização condicional (onboard vs normal)
  // ----------------------------------------------------------------------
  const messageContent = useMemo(() => {
    if (onboard) {
      return renderOnboardingMessage(typedMessage);
    } else {
      return renderRegularMessage(displayedText);
    }
  }, [onboard, displayedText, typedMessage]);

  // [ADDED]: Remover METADATA no modo onboarding
  function renderOnboardingMessage(typed) {
    const clean = removeMetadata(typed);
    return (
      <div>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeSanitize(customSchema), rehypeHighlight]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <pre className={`language-${match[1]}`} {...props}>
                  <code className={`language-${match[1]}`}>{children}</code>
                </pre>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            type: (props) => <Type {...props} />,
            content: (props) => <Content {...props} />,
            artifact: (props) => <Artifact {...props} />,
          }}
        >
          {clean}
        </ReactMarkdown>
      </div>
    );
  }

  function removeCitations(str) {
    return str.replace(/\s*\[[0-9]+(?:\]\[[0-9]+\])*\]/g, "");
  }

  function convertPlainTableToMarkdown(text) {
    // Remove linhas contendo apenas "=" (ou mais), como "===============================================================================".
    text = text.replace(/^\s*={3,}\s*$/gm, "").trim();
  
    // Caso especial: se o texto começar com "Tabela", remova o rótulo "Tabela X:".
    if (text.trim().toLowerCase().startsWith("tabela")) {
      const colonIndex = text.indexOf(":");
      if (colonIndex !== -1) {
        text = text.substring(colonIndex + 1).trim();
      }
    }
    
    // Se não houver quebras de linha e houver pipes, processa como uma tabela inline.
    if (!text.includes("\n") && text.includes("|")) {
      let tokens = text.split("|").map(token => token.trim()).filter(token => token !== "");
      // Se houver exatamente 4 tokens, assumimos uma tabela 2x2 (cabeçalho + 1 linha de dados).
      if (tokens.length === 4) {
        return `| ${tokens[0]} | ${tokens[1]} |\n| --- | --- |\n| ${tokens[2]} | ${tokens[3]} |`;
      }
      // Se o número de tokens for par (>= 2), assumimos 2 colunas com várias linhas.
      if (tokens.length % 2 === 0 && tokens.length > 0) {
        let header = `| ${tokens[0]} | ${tokens[1]} |`;
        let separator = `| --- | --- |`;
        let dataRows = [];
        for (let i = 2; i < tokens.length; i += 2) {
          dataRows.push(`| ${tokens[i]} | ${tokens[i + 1]} |`);
        }
        return [header, separator, ...dataRows].join("\n");
      }
    }
    
    // Se o texto não contém pipes mas cada linha possui 2+ colunas separadas por dois ou mais espaços,
    // converte esse bloco para tabela Markdown.
    const spaceSeparatedLines = text.split('\n').filter(line => line.trim() !== "");
    if (spaceSeparatedLines.length >= 2 && spaceSeparatedLines.every(line => line.split(/\s{2,}/).length >= 2)) {
      const rows = spaceSeparatedLines.map(line => line.split(/\s{2,}/).map(item => item.trim()));
      const mdRows = rows.map(row => `| ${row.join(" | ")} |`);
      const separator = `| ${rows[0].map(() => '---').join(" | ")} |`;
      mdRows.splice(1, 0, separator);
      return mdRows.join("\n");
    }
    
    // Caso padrão: processa o texto linha a linha
    const lines = text.split('\n');
    let resultLines = [];
    let tableBlock = [];
    let inTableBlock = false;
    
    lines.forEach((line) => {
      if (line.includes('|') && line.split('|').map(t => t.trim()).filter(Boolean).length >= 2) {
        inTableBlock = true;
        tableBlock.push(line.trim());
      } else {
        if (inTableBlock) {
          if (tableBlock.length >= 2 && !/^[-|\s]+$/.test(tableBlock[1])) {
            const headerCols = tableBlock[0].split('|').map(s => s.trim()).filter(Boolean);
            const separator = headerCols.map(() => '---').join(' | ');
            tableBlock.splice(1, 0, separator);
          }
          tableBlock = tableBlock.map((l) => {
            if (!l.startsWith('|')) l = '| ' + l;
            if (!l.endsWith('|')) l = l + ' |';
            return l;
          });
          resultLines.push(...tableBlock);
          tableBlock = [];
          inTableBlock = false;
        }
        resultLines.push(line);
      }
    });
    
    if (inTableBlock && tableBlock.length) {
      if (tableBlock.length >= 2 && !/^[-|\s]+$/.test(tableBlock[1])) {
        const headerCols = tableBlock[0].split('|').map(s => s.trim()).filter(Boolean);
        const separator = headerCols.map(() => '---').join(' | ');
        tableBlock.splice(1, 0, separator);
      }
      tableBlock = tableBlock.map((l) => {
        if (!l.startsWith('|')) l = '| ' + l;
        if (!l.endsWith('|')) l = l + ' |';
        return l;
      });
      resultLines.push(...tableBlock);
    }
    
    return resultLines.join('\n');
  }
  

  // [ADDED]: Remover METADATA no modo normal
  function renderRegularMessage(text) {
    // Primeiro, remove METADATA e as citações
    let modifiedText = removeMetadata(text);
    modifiedText = removeCitations(modifiedText);
    modifiedText = convertPlainTableToMarkdown(modifiedText);
    let extracted = "";
    
    // Verifica se existe uma tag <think> sem o respectivo fechamento
    const openIdx = modifiedText.indexOf("<think>");
    const closeIdx = modifiedText.indexOf("</think>");
    
    if (openIdx !== -1 && closeIdx === -1) {
      extracted = modifiedText.substring(openIdx + 7).trim();
      modifiedText = modifiedText.substring(0, openIdx);
    } else if (openIdx !== -1 && closeIdx !== -1) {
      const regex = /<think>([\s\S]*?)<\/think>/gi;
      extracted = "";
      let match;
      while ((match = regex.exec(modifiedText)) !== null) {
        extracted += match[1] + " ";
      }
      extracted = extracted.trim();
      modifiedText = modifiedText.replace(regex, "");
    }
    
    // Cria um objeto que também inclui os componentes para tabelas
    const extendedComponents = {
      ...markdownComponents,
      // Se necessário, mantenha os componentes customizados para type, content, artifact
      type: (props) => <Type {...props} />,
      content: (props) => <Content {...props} />,
      artifact: (props) => <Artifact {...props} />,
    };
    
    return (
      <span className="MessageText">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeSanitize(customSchema), rehypeHighlight]}
          components={extendedComponents}
        >
          {modifiedText}
        </ReactMarkdown>
        {extracted && (
          <div className="mt-3 p-4 bg-white text-black rounded-lg shadow-sm border border-gray-200">
            <h4 className="mb-2 text-sm font-semibold text-black">
            </h4>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeHighlight]}
              className="text-sm text-black"
              components={extendedComponents}
            >
              {extracted}
            </ReactMarkdown>
          </div>
        )}
      </span>
    );
  }

  // ----------------------------------------------------------------------
  // Resto do seu código se mantém como está
  // ----------------------------------------------------------------------

  // Renderizar lista de arquivos
  const renderFiles = () => {
    if (!allFiles || allFiles.length === 0) return null;
    return (
      <div className="MessageFiles">
        {allFiles.map((fileUrl, index) => {
          const fileName = getFileNameFromUrl(fileUrl);
          const fileExtension = getFileExtension(fileUrl).toLowerCase();

          let fileIcon;
          if (["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(fileExtension)) {
            fileIcon = faImage;
          } else if (["pdf"].includes(fileExtension)) {
            fileIcon = faFilePdf;
          } else {
            fileIcon = faFileAlt;
          }

          return (
            <div key={index} className="MessageFileItem">
              <FontAwesomeIcon icon={fileIcon} className="MessageFileIcon" />
              <span className="MessageFileName">{fileName}</span>
            </div>
          );
        })}
      </div>
    );
  };

  // Botão "Ver Raciocínio"
  const renderReasoningToggle = () => {
    if (!isDeepSeek) return null;
    if (!reasoningContent) return null;

    return (
      <Button
        type="text"
        onClick={() => setShowReasoning(!showReasoning)}
        style={{
          padding: "0 6px",
          fontSize: "14px",
          color: "#1E90FF",
          display: "flex",
          alignItems: "center",
        }}
      >
        {showReasoning ? (
          <>
            <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 4 }} />
            Ocultar Raciocínio
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 4 }} />
            Ver Raciocínio
          </>
        )}
      </Button>
    );
  };

  function getFileNameFromUrl(url) {
    try {
      const urlObj = new URL(url);
      const pathname = urlObj.pathname;
      const fileName = pathname.substring(pathname.lastIndexOf("/") + 1);
      return decodeURIComponent(fileName);
    } catch (error) {
      console.error("Invalid URL:", url);
      return url;
    }
  }

  function getFileExtension(url) {
    try {
      const urlObj = new URL(url);
      const pathname = urlObj.pathname;
      return pathname.split(".").pop();
    } catch (error) {
      console.error("Invalid URL:", url);
      return "";
    }
  }

  // Render tags: Specialty e BrandVoice
  const renderSpecialtyTag = () => {
    if (!specialty) return null;
    const tooltipId = `tooltip-specialty-${idChat}-${timestamp}`;
    return (
      <>
        <span data-tooltip-id={tooltipId}>
          <Tag
            className="flex flex-row items-center gap-1 cursor-pointer"
            icon={<FontAwesomeIcon icon={faRedhat} className="text-gray-600" />}
          >
            {specialty?.name}
          </Tag>
        </span>
        <Tooltip id={tooltipId} place="top" effect="solid" />
      </>
    );
  };

  const renderBrandVoiceTag = () => {
    if (!brandVoice) return null;
    const tooltipId = `tooltip-brandvoice-${idChat}-${timestamp}`;
    return (
      <>
        <span data-tooltip-id={tooltipId}>
          <Tag
            className="flex flex-row items-center gap-1 cursor-pointer"
            icon={<FontAwesomeIcon icon={faBullhorn} className="text-gray-600" />}
          >
            {brandVoice?.name}
          </Tag>
        </span>
        <Tooltip id={tooltipId} place="top" effect="solid" />
      </>
    );
  };

  // Detectar snippet de código
  function detectCodeSnippet(text) {
    const codeRegex = /```(\w*)\n([\s\S]*?)```/g;
    let match;
    let snippets = [];

    while ((match = codeRegex.exec(text)) !== null) {
      const [fullMatch, language = "", codeContent = ""] = match;
      const lang = language.toLowerCase().trim();
      const code = codeContent.trim();
      const shellCommandPatterns = [
        /^(\$|#)\s+/,
        /^npx\s+/,
        /^npm\s+/,
        /^yarn\s+/,
        /^cd\s+/,
        /^mkdir\s+/,
        /^rm\s+/,
        /^echo\s+/,
        /^cat\s+/,
        /^ls\s+/,
        /^pwd\s+/,
      ];

      const isShellCommand = shellCommandPatterns.some((p) => p.test(code));
      if (isShellCommand || ["shell", "bash"].includes(lang)) {
        console.log("Skipping shell command code block");
        continue;
      }
      snippets.push({
        language: lang || "javascript",
        content: code,
      });
    }
    if (snippets.length > 0) {
      return snippets[0];
    }
    return null;
  }

  // Copiar texto
  const handleCopy = () => {
    navigator.clipboard.writeText(displayedText);
  };

  // Botão copy + preview (se for system e tiver code snippet)
  const renderIconTray = () => {
    if (role === "SYSTEM" && codeSnippet) {
      return (
        <div className="float-right text-white mb-2">
          {renderCopyButton()}
          {renderPreviewButton()}
        </div>
      );
    }
    return null;
  };

  const renderCopyButton = () => {
    const tooltipId = `tooltip-copy-${idChat}-${timestamp}`;
    return (
      <>
        <span data-tooltip-id={tooltipId}>
          <div
            className="transition-all duration-100 ease-in cursor-pointer hover:text-white hover:scale-125"
            onClick={handleCopy}
          >
            <FontAwesomeIcon icon={faClone} />
          </div>
        </span>
        <Tooltip id={tooltipId} place="top" effect="solid" />
      </>
    );
  };

  const renderPreviewButton = () => {
    return (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          console.log("Preview button clicked in Message:", codeSnippet);
          if (onArtifactPreview && codeSnippet) {
            onArtifactPreview(codeSnippet);
          }
        }}
        style={{ marginTop: "8px", marginLeft: "8px" }}
      >
        Preview
      </Button>
    );
  };

  // Posição da mensagem
  const getMessagePositionClass = () => {
    return role === "SYSTEM" ? "left" : "right";
  };

  // Pegar imagem do usuário
  const getUserProfilePictureUrl = () => {
    if (user.picture) {
      if (user.picture.startsWith("http")) {
        return user.picture;
      }
      return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/${user.idUser}/256/${user.picture}`;
    }
    return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/default/256/default.png`;
  };

  // Favicons para as citações
  function extractDomain(url) {
    try {
     if (!/^https?:\/\//i.test(url)) {
       url = "https://" + url;  // prepend "https://" se não tiver
     }
      const { hostname } = new URL(url);
      return hostname.toLowerCase();
    } catch (err) {
      return "";
    }
  }

  function getFaviconUrl(domain) {
    // Troque sz=64 por 16, 32, etc. caso queira outro tamanho
    return `https://www.google.com/s2/favicons?sz=64&domain_url=${domain}`;
  }

  function CitationFaviconButton({ link }) {
    const [iconError, setIconError] = React.useState(false);
    const domain = extractDomain(link);
    const faviconUrl = getFaviconUrl(domain);

    return (
      <div
        onClick={() => window.open(link, "_blank")}
        style={{
          width: "28px",
          height: "28px",
          borderRadius: "50%",
          overflow: "hidden",
          cursor: "pointer",
          backgroundColor: "#EEE",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "transform 0.2s ease-in-out",
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = "scale(1.1)";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = "scale(1.0)";
        }}
      >
        <img
          src={
            iconError
              ? "https://via.placeholder.com/16/cccccc/FFFFFF?text=%3F"
              : faviconUrl
          }
          alt="favicon"
          style={{
            width: 16,
            height: 16,
            objectFit: "contain",
          }}
          onError={() => setIconError(true)}
        />
      </div>
    );
  }

  function renderCitations() {
    if (!localCitations || localCitations.length === 0) return null;

    return (
      <div
        className="CitationsArea"
        style={{
          marginTop: "8px",
          display: "flex",
          gap: "8px",
        }}
      >
        {localCitations.map((link, i) => (
          <CitationFaviconButton key={`citation-${i}`} link={link} />
        ))}
      </div>
    );
  }

  const renderMessageAuthor = () => {
    if (role === "SYSTEM") {
      return (
        <div className="flex items-center gap-2">
          <Avatar
            size={48}
            className={`MessageIcon ${getMessagePositionClass()}`}
            src={Logo}
          />
          <div className="flex flex-col gap-1">
            <div className="flex flex-row items-center gap-2">
              <label className="text-[16px] font-bold">CMOs.ai</label>
              {isDeepSeek && renderReasoningToggle()}
            </div>

            {isDeepSeek && showReasoning && (
              <div
                style={{
                  background: "#fafafa",
                  borderLeft: "4px solid #ffb100",
                  padding: "12px",
                  marginTop: "6px",
                  borderRadius: "4px",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                  transition: "all 0.3s ease",
                  maxWidth: "900px",
                  wordWrap: "break-word",
                }}
              >
                <h4
                  style={{
                    margin: "0 0 8px 0",
                    fontSize: "14px",
                    color: "#33333",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  CoT (Cadeia de Pensamento):
                </h4>
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeHighlight]}
                  style={{ fontSize: "14px", color: "#555" }}
                >
                  {reasoningContent}
                </ReactMarkdown>
              </div>
            )}
            <div className="flex flex-row items-center">
              {renderSpecialtyTag()}
              {renderBrandVoiceTag()}
            </div>
          </div>
        </div>
      );
    } else {
      // USER normal
      return (
        <div className="flex justify-end items-center gap-4">
          <div>
            <label className="text-[16px] font-bold">
              {user.name.split(" ")[0]}
            </label>
          </div>
          <Avatar size={48} src={getUserProfilePictureUrl()} />
        </div>
      );
    }
  };

  if (!displayedText || displayedText.trim() === "") {
    if (!allFiles.length) {
      return null;
    }
  }

  const roleCustomClass =
    role === "SYSTEM"
      ? "px-4 my-2 bg-white rounded-[12px] MessageSystem text-white"
      : "px-4 my-2 bg-white rounded-[12px] MessageUser";

  return (
    <div>
      <div key={timestamp}>
        {renderMessageAuthor()}
        <div
          className={`${getMessagePositionClass()} ${
            isMobile ? "max-w-[95%]" : "max-w-[70%]"
          } ${roleCustomClass}`}
        >
          {renderFiles()}
          {messageContent}
          {renderIconTray()}
          {renderCitations()}
        </div>
        <div style={{ clear: "both" }}></div>
      </div>
    </div>
  );
};

Message.propTypes = {
  idChat: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  timestamp: PropTypes.instanceOf(Date).isRequired,
  onboard: PropTypes.bool,
  user: PropTypes.object.isRequired,
  picture: PropTypes.string,
  specialty: PropTypes.object,
  brandVoice: PropTypes.object,
  files: PropTypes.arrayOf(PropTypes.string),
  isMobile: PropTypes.bool,
  onArtifactPreview: PropTypes.func.isRequired,
  onStartTyping: PropTypes.func,
  onFinishTyping: PropTypes.func,
  scrollDown: PropTypes.func,
  reasoningContent: PropTypes.string,
  citations: PropTypes.arrayOf(PropTypes.string),
  generationModelUsed: PropTypes.string,
};

export default React.memo(Message);
