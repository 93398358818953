// src\components\validator\ActiveSubscriptionValidator.jsx

import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../utils/providers/userProvider";
import _ from "lodash";
import {PermissionGroupsContext} from "../../utils/providers/permissionGroupsProvider";
import {useSubscriptions} from "../../utils/hooks/useSubscriptions";
import {useLocation, useNavigate} from "react-router-dom";
import {useCurrentWorkspace} from "../../utils/hooks/useCurrentWorkspace";

export const ActiveSubscriptionValidator = ({additionalRequiredPermissions, ...props}) => {
    const location = useLocation();

    const {user} = useContext(UserContext);
    const subscriptions = useSubscriptions();
    const [permissionGroups] = useContext(PermissionGroupsContext);
    const {currentWorkspace} = useCurrentWorkspace();

    const [validated, setValidated] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const allowedPermissionGroups = subscriptions.map(subscription => subscription.permissionGroup);
        if (!_.isEmpty(user) && !_.isEmpty(subscriptions)) {
            if (permissionGroups?.includes("TeamMemberSubscriptionPlan") && currentWorkspace?.type === "SHARED") {
                setValidated(true);
                return;
            }

            if (_.isEmpty(_.get(user, "activeSubscriptions", []))
                || _.head(_.get(user, "activeSubscriptions", [])).status === "past_due" ||
                (permissionGroups != null && !permissionGroups.some(permissionGroup => allowedPermissionGroups.includes(permissionGroup))))
            {
                navigate(`/settings/subscription`);
            }

            if (additionalRequiredPermissions && !permissionGroups.some(permissionGroup => additionalRequiredPermissions.includes(permissionGroup))) {
                navigate(`/chat`);
            }
            setValidated(true);
        }
    }, [user, subscriptions, props, location, additionalRequiredPermissions, permissionGroups, navigate, currentWorkspace]);

    if (validated) {
        return props.children;
    }
}