// brainwave-ui-develop\src\app\workspace\WorkspaceSelector.jsx

import React, {useCallback, useEffect} from 'react';
import {Avatar, Dropdown, Popover} from "antd";
import {ChevronDownIcon} from "lucide-react";
import {useCurrentWorkspace} from "../../utils/hooks/useCurrentWorkspace";
import classnames from "classnames";
import {useWorkspaces} from "../../utils/hooks/useWorkspaces";
import PremiumButton from "../../components/buttons/PremiumButton";
import {useNavigate} from "react-router-dom";
import {useCurrentSubscriptionPlans} from "../../utils/hooks/useCurrentSubscriptionPlan";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import useIsMobile from "../../utils/hooks/useIsMobile";

const WorkspaceSelector = () => {
    const {workspaces, isLoading} = useWorkspaces();
    const {currentWorkspace, setCurrentWorkspace} = useCurrentWorkspace();
    const {currentSubscriptionPlans} = useCurrentSubscriptionPlans();
    const isMobile = useIsMobile();

    const navigate = useNavigate();

    const getWorkspaceByLabel = useCallback((label) => {
        return workspaces?.find(workspace => workspace.name === label);
    }, [workspaces]);

    const handleWorkspaceChange = async (label) => {
        setCurrentWorkspace(getWorkspaceByLabel(label));
    }

    const renderOptionLabel = useCallback((label) => {
        return (
            <div className="h-8 flex items-center" onClick={async () => await handleWorkspaceChange(label)}>
                <div className="text-[14px] text-neutral-800">
                    {label}
                </div>
            </div>
        );
    }, [getWorkspaceByLabel, setCurrentWorkspace]);

    const getDropdownItems = useCallback(() => {
        if (!workspaces || isLoading || !currentWorkspace) return [];

        return workspaces?.filter((workspace) => workspace.idWorkspace !== currentWorkspace.idWorkspace)
            .map(workspace => {
                return {
                    label: renderOptionLabel(workspace.name),
                    value: workspace.id
                }
            });

    }, [currentWorkspace, isLoading, renderOptionLabel, workspaces]);


    useEffect(() => {
        if (workspaces?.length && currentWorkspace == null) {
            setCurrentWorkspace(workspaces[0]);
        } else if (workspaces?.length && currentWorkspace && !workspaces.find(workspace => workspace.idWorkspace === currentWorkspace.idWorkspace)) {
            setCurrentWorkspace(workspaces[0]);
        }
    }, [currentWorkspace, getDropdownItems, setCurrentWorkspace, workspaces]);

    if (!workspaces?.length) return;

    const getPopoverContent = () => {
        if (isMobile) {
            return null;
        }

        if (currentWorkspace?.type === "SHARED") {
            return (
                <div className="max-w-[200px] flex flex-col gap-2">
                    <div>Este é um <b>workspace compartilhado</b>.</div>
                    <div>Todas as mensagens e arquivos compartilhados neste espaço de trabalho serão <b>visíveis</b> para todos os membros.</div>
                </div>
            );
        } else if (currentWorkspace?.type === "DEFAULT") {
            return (
                <div className="max-w-[200px] flex flex-col gap-2">
                    <div>Este é um <b>workspace pessoal</b>.</div>
                    <div>Todas as mensagens e arquivos compartilhados neste espaço de trabalho serão <b>visíveis apenas para você</b>.</div>
                </div>
            );
        }
    }

    const renderUpgradeButton = () => {
        const currentPermissionGroups = currentSubscriptionPlans.map(subscriptionPlan => subscriptionPlan.permissionGroup);
        if (!currentPermissionGroups.includes("TeamSubscriptionPlan") && !currentPermissionGroups.includes("TeamMemberSubscriptionPlan")) {
            return (
                <PremiumButton
                    size="large"
                    type="text"
                    className="text-white"
                    onClick={() => navigate('/settings/subscription', {state: {openTeamModal: true}})}
                    block
                    icon={<FontAwesomeIcon icon={faUsers} className="text-white"/>}
                >
                    Adicionar Team
                </PremiumButton>
            );
        }
    }

    return (
        <div>
            <div className="mb-2">
                {renderUpgradeButton()}
            </div>

            <Popover content={getPopoverContent()} placement="right">
                <Dropdown
                menu={{items: getDropdownItems()}}
                trigger="click"
                placement="topRight"
            >
                <div
                    className="flex flex-row items-center justify-between gap-1 text-gray-800 duration-200 ease-in-out transition-all hover:bg-neutral-200 hover:cursor-pointer rounded-lg py-2 pl-2 pr-3"
                >
                    <div className="flex flex-row items-center gap-2">
                        <Avatar
                            className={classnames({
                                "bg-premium": currentWorkspace?.type === "SHARED",
                                "bg-neutral-400": currentWorkspace?.type !== "SHARED"
                            })}
                        >
                            {currentWorkspace?.name?.split(" ").reduce((acc, char) => acc + char[0].toUpperCase(), "")}
                        </Avatar>
                        <div className="font-primary flex flex-col">
                            <div className="text-[14px] text-neutral-800">
                                {currentWorkspace?.name}
                            </div>
                        </div>
                    </div>
                    <ChevronDownIcon size={18} className="text-gray-800"/>
                </div>

            </Dropdown>
            </Popover>

        </div>
    );
};

export default WorkspaceSelector;