import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {ToolsRemote} from "../../services/dao/remote/tools";
import {message} from "antd";

export const useTools = () => {
    const {data: tools, isLoading} = useQuery({
        queryKey: ["tools"],
        queryFn: ToolsRemote.list,
        refetchOnWindowFocus: false,
    });

    return {tools, isLoading};
}

export const useToolsWithPrompt = () => {
    const {data: tools, isLoading} = useQuery({
        queryKey: ["tools"],
        queryFn: ToolsRemote.listWithPrompt,
        refetchOnWindowFocus: false,
    });

    return {tools, isLoading};
}

// export const usePublishedTools = () => {
//     const {data: publishedTools, isLoading} = useQuery({
//         queryKey: ["publishedTools"],
//         queryFn: ToolsRemote.getPublished
//     });
//
//     return {publishedTools, isLoading};
// }

export const useCreateTool = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();

    const {mutate: createTool, isLoading: isCreating} = useMutation({
        mutationFn: ToolsRemote.create,
        onSuccess: (tool) => {
            queryClient.setQueryData(["tools"], (tools) => {
                return [...tools, tool];
            });
            onSuccess?.();
            message.success(`Ferramenta "${tool.name}" criada!`);
        },
        onError: (error) => {
            onError?.(error);
            message.error("Erro ao criar a ferramenta");
            console.log(error);
        }
    });

    return {createTool, isCreating};
}

export const useUpdateTool = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();

    const {mutate: updateTool, isLoading: isUpdating} = useMutation({
        mutationFn: ToolsRemote.update,
        onSuccess: (tool) => {
            queryClient.setQueryData(["tools"], (tools) => {
                return tools.map((t) => {
                    if (t.idTool === tool.idTool) {
                        return tool;
                    }
                    return t;
                });
            });
            onSuccess?.();
            message.success(`Ferramenta "${tool.name}" atualizada!`);
        },
        onError: (error) => {
            onError?.(error);
            message.error("Erro ao atualizar a ferramenta");
            console.log(error);
        }
    });

    return {updateTool, isUpdating};
}

export const useDeleteTool = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();

    const {mutate: deleteTool, isLoading: isDeleting} = useMutation({
        mutationFn: ToolsRemote.remove,
        onSuccess: (idTool) => {
            queryClient.setQueryData(["tools"], (tools) => {
                return tools.filter((t) => t.idTool !== idTool);
            });
            onSuccess?.();
            message.success("Ferramenta removida!");
        },
        onError: (error) => {
            onError?.(error);
            message.error("Erro ao remover a ferramenta");
            console.log(error);
        }
    });

    return {deleteTool, isDeleting};
}

export const usePublishTool = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();

    const {mutate: publishTool, isLoading: isPublishing} = useMutation({
        mutationFn: ToolsRemote.publish,
        onSuccess: (tool) => {
            queryClient.setQueryData(["tools"], (tools) => {
                return tools.map((t) => {
                    if (t.idTool === tool.idTool) {
                        return tool;
                    }
                    return t;
                });
            });
            onSuccess?.();
            message.success(`Ferramenta "${tool.name}" publicada!`);
        },
        onError: (error) => {
            onError?.(error);
            message.error("Erro ao publicar a ferramenta");
            console.log(error);
        }
    });

    return {publishTool, isPublishing};
}

export const useUnpublishTool = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();

    const {mutate: unpublishTool, isLoading: isUnpublishing} = useMutation({
        mutationFn: ToolsRemote.unpublish,
        onSuccess: (tool) => {
            queryClient.setQueryData(["tools"], (tools) => {
                return tools.map((t) => {
                    if (t.idTool === tool.idTool) {
                        return tool;
                    }
                    return t;
                });
            });
            onSuccess?.();
            message.success(`Ferramenta "${tool.name}" ocultada!`);
        },
        onError: (error) => {
            onError?.(error);
            message.error("Erro ao ocultar a ferramenta");
            console.log(error);
        }
    });

    return {unpublishTool, isUnpublishing};
}