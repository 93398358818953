// src/app/generation-model-class/GenerationModelClassSelector.jsx

import React, { useEffect, useState } from 'react';
import { useGenerationModelClasses } from '../../utils/hooks/useGenerationModels';
import { Avatar, Button, Dropdown, Popover, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons';
import OpenAIIcon from '../../images/icons/openai.svg';
import AnthropicIcon from '../../images/icons/anthropic.jpeg';
import GoogleIcon from '../../images/icons/google.png';
import DeepSeekIcon from '../../images/icons/deepseek.png';
import { useGenerationModelClass } from '../../utils/hooks/useGenerationModelClass';
import UpgradeButton from '../../components/subscription/UpgradeButton';
import { useCurrentSubscriptionPlans } from '../../utils/hooks/useCurrentSubscriptionPlan';
import { Theme } from '../../api/Theme';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

const GenerationModelClassSelector = () => {
  const [selectedGenerationModelClass, setSelectedGenerationModelClass] = useState(null);
  const [currentGenerationModelClass, setCurrentGenerationModelClass] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { generationModelClasses, isLoading } = useGenerationModelClasses();
  const { generationModelClass, setGenerationModelClass } = useGenerationModelClass();
  const { currentSubscriptionPlans } = useCurrentSubscriptionPlans();

  // Verifica se é mobile (telas <= 767px)
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const isFreemium = currentSubscriptionPlans?.some(
    (plan) => plan.planName?.toLowerCase() === 'freemium'
  );

  // Ordem específica de modelos – incluímos "o3-mini"
  const ORDERED_MODELS = [
    'o3-mini',
    'deepseek-r1',  
    'gemini-flash',
    'gemini-pro',    
    'gpt-4o-mini',
    'gpt-4o',
    'claude-3-haiku',
    'claude-3-sonnet',
    'claude-3-opus',
    'regular',
    'advanced',
  ];

  // Ajuste inicial do modelo default, de acordo com o plano do usuário
  useEffect(() => {
    const availableModels =
      currentSubscriptionPlans?.map((plan) => plan.generationModelClasses).flat() || [];

    if (!currentGenerationModelClass) {
      let defaultModel;
      if (isFreemium) {
        defaultModel = 'gpt-4o-mini'; // Para Freemium
      } else {
        // Para planos pagos, usa o primeiro da ordem preferida
        defaultModel = ORDERED_MODELS.find((model) => availableModels.includes(model));
      }

      if (defaultModel && availableModels.includes(defaultModel)) {
        setSelectedGenerationModelClass(defaultModel);
        setGenerationModelClass(defaultModel);
        setCurrentGenerationModelClass(defaultModel);
      } else {
        // Se o default não estiver disponível, pega o primeiro disponível
        const firstAvailable = availableModels[0];
        if (firstAvailable) {
          setSelectedGenerationModelClass(firstAvailable);
          setGenerationModelClass(firstAvailable);
          setCurrentGenerationModelClass(firstAvailable);
        }
      }
    } else {
      // Se já há um modelo selecionado, verifica se ainda é válido
      const availableModels =
        currentSubscriptionPlans?.map((plan) => plan.generationModelClasses).flat() || [];
      if (availableModels.includes(generationModelClass)) {
        setCurrentGenerationModelClass(generationModelClass);
        setSelectedGenerationModelClass(generationModelClass);
      } else {
        const firstAvailable = availableModels[0];
        if (firstAvailable) {
          setSelectedGenerationModelClass(firstAvailable);
          setGenerationModelClass(firstAvailable);
          setCurrentGenerationModelClass(firstAvailable);
        }
      }
    }
  }, [generationModelClass, isFreemium, currentSubscriptionPlans]);

  // Verifica se um modelo está disponível no plano do usuário
  const isGenerationModelClassAvailable = (model) => {
    const availableModels =
      currentSubscriptionPlans?.map((plan) => plan.generationModelClasses).flat() || [];
    return availableModels.includes(model);
  };

  const handleGenerationModelClassChange = (model) => {
    if (isGenerationModelClassAvailable(model)) {
      setGenerationModelClass(model);
      setSelectedGenerationModelClass(model);
      setDropdownOpen(false);
    }
  };

  // Render de cada item (texto + ícone)
  const renderOptionLabel = (model) => {
    const component = (
      <div
        className="cursor-pointer hover:bg-gray-100 transition-colors rounded"
        style={{
          padding: isMobile ? '6px 10px' : '8px 12px',
        }}
        onClick={() => handleGenerationModelClassChange(model.value)}
      >
        <div className="flex flex-row items-center gap-2">
          <Avatar
            shape="square"
            size={isMobile ? 24 : 'default'}
            src={getOptionIcon(model.value)}
          />
          <div>
            {/* Nome do modelo */}
            <div
              style={{
                fontSize: isMobile ? '0.85rem' : '1rem',
                maxWidth: isMobile ? '140px' : '250px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {model.name}
            </div>
            {/* Descrição do modelo */}
            {!isMobile && (
              <div
                style={{
                  fontSize: '0.75rem',
                  color: '#999',
                  maxWidth: '250px',
                  whiteSpace: 'normal',
                }}
              >
                {model.description}
              </div>
            )}
          </div>
        </div>
      </div>
    );

    // Se não estiver disponível no plano, exibe popover de "Upgrade"
    if (!isGenerationModelClassAvailable(model.value)) {
      return (
        <Popover
          content={renderPopoverContent()}
          trigger="hover"
          placement="right"
          styles={{ root: { zIndex: 9999 } }}  // Ajuste aqui
        >
          {component}
        </Popover>
      );
    } else {
      return component;
    }
  };

  // Conteúdo do popover "Upgrade"
  const renderPopoverContent = () => (
    <div className="font-primary w-[200px] flex flex-col gap-2">
      Para acessar modelos mais avançados, faça um upgrade de plano.
      <UpgradeButton size="large" />
    </div>
  );

  // Função para retornar o ícone de cada modelo, agora incluindo "o3-mini"
  const getOptionIcon = (model) => {
    if (!model) return null;
    if (model.startsWith('o3-mini')) {
      return OpenAIIcon; // ou um ícone específico, se disponível
    } else if (model.startsWith('gpt')) {
      return OpenAIIcon;
    } else if (model.startsWith('claude')) {
      return AnthropicIcon;
    } else if (model.startsWith('gemini')) {
      return GoogleIcon;
    } else if (model.startsWith('deepseek')) {
      return DeepSeekIcon;
    } else {
      return (
        <Avatar className="bg-premium" shape="square">
          <FontAwesomeIcon icon={faWandMagicSparkles} />
        </Avatar>
      );
    }
  };

  // Ordena as classes de modelo
  const getSortedModelClasses = () => {
    if (isLoading || !generationModelClasses) {
      return [];
    }
    const items = [...generationModelClasses];
    items.sort((a, b) => {
      const aIndex = ORDERED_MODELS.indexOf(a.value);
      const bIndex = ORDERED_MODELS.indexOf(b.value);
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
    return items;
  };

  // Divide os itens em colunas
  const splitIntoColumns = (items, columns = 2) => {
    const total = items.length;
    const itemsPerColumn = Math.ceil(total / columns);
    const result = [];
    for (let i = 0; i < columns; i++) {
      const start = i * itemsPerColumn;
      const end = start + itemsPerColumn;
      result.push(items.slice(start, end));
    }
    return result;
  };

  // Dropdown customizado
  const renderDropdownOverlay = () => {
    const sortedItems = getSortedModelClasses();
    // Se for mobile, 1 coluna; senão 2
    const columnsCount = isMobile ? 1 : 2;
    const columns = splitIntoColumns(sortedItems, columnsCount);

    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: isMobile ? '8px' : '12px 16px',
          borderRadius: 6,
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
          width: isMobile ? '240px' : 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: '24px',
          }}
        >
          {columns.map((columnItems, colIndex) => (
            <div
              key={`col-${colIndex}`}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {columnItems.map((model) => (
                <div key={model.value} style={{ marginBottom: 4 }}>
                  {renderOptionLabel(model)}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getSelectedGenerationModelClass = (model) => {
    if (isLoading || !generationModelClasses) {
      return null;
    }
    return generationModelClasses.find((item) => item.value === model);
  };

  // Render principal do seletor
  return (
    <div
      className="generation-model-class-selector flex flex-row items-center gap-2"
      style={{
        flexWrap: isMobile ? 'wrap' : 'nowrap',
      }}
    >
      <Dropdown
        dropdownRender={() => renderDropdownOverlay()}
        trigger={['click']}
        open={dropdownOpen}
        onOpenChange={(flag) => setDropdownOpen(flag)}
      >
        <Button
          type="text"
          size={isMobile ? 'middle' : 'large'}
          style={{
            color: '#444',
            display: 'flex',
            alignItems: 'center',
            gap: isMobile ? 4 : 8,
            padding: isMobile ? '4px 8px' : undefined,
          }}
        >
          <Avatar
            shape="square"
            size={isMobile ? 24 : 'default'}
            src={getOptionIcon(
              getSelectedGenerationModelClass(currentGenerationModelClass)?.value
            )}
          />
          <span
            style={{
              fontSize: isMobile ? '0.85rem' : '1rem',
              maxWidth: isMobile ? '100px' : 'unset',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {getSelectedGenerationModelClass(currentGenerationModelClass)?.name}
          </span>
          <FontAwesomeIcon
            icon={faChevronDown}
            style={{ marginLeft: isMobile ? 0 : '0.5rem' }}
          />
        </Button>
      </Dropdown>
      {!isMobile && <Tag color={Theme.PRIMARY}>Novo</Tag>}
    </div>
  );
};

export default GenerationModelClassSelector;
