// src/components/chat/ChatOrchestrator.jsx

import React, { Component } from "react";
import ChatRenderer from "./ChatRenderer";
import PropTypes from "prop-types";

/**
 * ChatOrchestrator é um componente de classe que orquestra a renderização do ChatRenderer.
 * Ele recebe diversas props relacionadas ao usuário, chat atual e funções de manipulação.
 * Além disso, agora passa a função `onArtifactPreview` para permitir a visualização de blocos de código.
 */
class ChatOrchestrator extends Component {
  constructor(props) {
    super(props);
  }

  /**
   * Renderiza o componente ChatRenderer com as props necessárias.
   * @returns {JSX.Element|null} O componente ChatRenderer ou null se não houver chat selecionado.
   */
  render() {
    const {
      user,
      chat,
      onSpecialtyChange,
      onBrandVoiceChange,
      onAIWriting,
      onMessageSent,
      onArtifactPreview, // Função para lidar com a visualização de artifacts (blocos de código)
    } = this.props;

    // Não renderiza nada se não houver chat selecionado
    if (!chat) {
      return null;
    }

    // Garante que specialty e brandVoice sejam ao menos null caso não existam
    const specialty = chat.specialty || null;
    const brandVoice = chat.brandVoice || null;

    return (
      <ChatRenderer
        user={user}
        idChat={chat.idChat}
        specialty={specialty}
        brandVoice={brandVoice}
        onSpecialtyChange={onSpecialtyChange}
        onBrandVoiceChange={onBrandVoiceChange}
        onAIWriting={onAIWriting}
        onMessageSent={onMessageSent}
        onArtifactPreview={onArtifactPreview} 
      />
    );
  }
}

// Define os tipos das props para validação
ChatOrchestrator.propTypes = {
  user: PropTypes.object.isRequired,                // Objeto representando o usuário
  chat: PropTypes.object,                           // Objeto representando o chat atual
  onSpecialtyChange: PropTypes.func.isRequired,     // Função para alterar a especialidade do chat
  onBrandVoiceChange: PropTypes.func.isRequired,    // Função para alterar a voz da marca do chat
  onAIWriting: PropTypes.func.isRequired,           // Função para indicar se a IA está escrevendo
  onMessageSent: PropTypes.func.isRequired,         // Função chamada quando uma mensagem é enviada
  onArtifactPreview: PropTypes.func.isRequired,     // Função para lidar com a visualização de artifacts (blocos de código)
};

export default ChatOrchestrator;
