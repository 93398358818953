import React from 'react';
import { Statistic } from 'antd';
import { Coins } from 'lucide-react';
import CountUp from 'react-countup';
import moment from 'moment/moment';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import useUser from '../../utils/hooks/useUser';
import { useWallet } from '../../utils/hooks/useWallet';
import { useMediaQuery } from 'react-responsive';

const CreditsCounter = () => {
  const { user, isLoading } = useUser();
  const { textCredits, nextRecharge } = useWallet();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  if (!user || isLoading) {
    return (
      <div
        style={{
          padding: '8px 12px',
          borderRadius: 8,
          background: '#f3f3f3',
          textAlign: 'center',
        }}
      >
        Carregando créditos...
      </div>
    );
  }

  const formattedDate = moment(nextRecharge).format('DD/MM/YYYY');

  // Conteúdo da tooltip com "Recarrega em" em branco
  const tooltipContent = (
    <div style={{ padding: '8px 12px', textAlign: 'center', color: '#fff' }}>
      <strong style={{ color: '#fff' }}>Recarrega em:</strong>
      <br />
      {formattedDate}
    </div>
  );

  // Formata o valor com countup, exceto se for ilimitado
  const formatter = (value) => {
    if (value === -1) return 'Ilimitado';
    return <CountUp end={value} />;
  };

  return (
    <div
      className="credits-counter"
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        padding: '8px 12px',
        borderRadius: 8,
        background: '#fafafa',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        // Deixamos o cursor pointer no mobile para indicar clique
        cursor: isMobile ? 'pointer' : 'default',
      }}
    >
      <Tippy
        // Conteúdo da tooltip
        content={tooltipContent}
        // Posição à esquerda do elemento
        placement="left"
        // No desktop, hover; no mobile, clique
        trigger={isMobile ? 'click' : 'mouseenter focus'}
        // Deixa a tooltip interativa (pode mover mouse sobre ela) e fecha ao clicar fora
        interactive
        hideOnClick
        // Define um tema escuro padrão do Tippy
        theme="dark"
      >
        <div>
          <Statistic
            // Sem título no mobile para ficar minimalista
            title={!isMobile ? 'Créditos Restantes' : null}
            value={textCredits}
            prefix={<Coins size={isMobile ? 14 : 18} />}
            formatter={formatter}
            valueStyle={{
              fontSize: isMobile ? '16px' : '20px',
              fontWeight: 600,
            }}
          />
        </div>
      </Tippy>
    </div>
  );
};

export default CreditsCounter;
