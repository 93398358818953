// src\components\subscription\SubscriptionActivation.jsx

import {useContext, useEffect} from "react";
import LoadingScreen from "../loading-screen/LoadingScreen";
import {useNavigate} from "react-router-dom";
import useUser from "../../utils/hooks/useUser";
import {PermissionGroupsContext} from "../../utils/providers/permissionGroupsProvider";

const SubscriptionActivation = () => {
    const navigate = useNavigate();
    const {refetch: refetchUser} = useUser();
    const [, , refetchPermissionGroups] = useContext(PermissionGroupsContext);

    useEffect(() => {
        refetchPermissionGroups()
            .then(async () => {
                navigate("/chat");
                refetchUser();
            });
    }, []);

    return (
        <LoadingScreen/>
    );
}

export default SubscriptionActivation;