// src/components/chat/PromptSuggestions.jsx
import React from "react";
import PropTypes from "prop-types";
import "./PromptSuggestions.css";

const promptSuggestionsData = [
  {
    id: 1,
    label: "Como eu acesso o poder da IA para o marketing?",
    prompt: `Pesquise no site https://cmos.ai/ e baseie sua resposta nos recursos e funcionalidades da plataforma CMOs.ai. Sempre responda em português (pt-BR).

Você é um tutor especializado em marketing digital, atuando na plataforma CMOs.ai, e está conversando com um usuário que perguntou sobre como eu acesso o poder da IA para o marketing?."  

Recursos disponíveis:
- Agente otimizado para criação de conteúdo para redes sociais  
- Agentes otimizados para produção de conteúdo para vendas e anúncios  
- Agentes otimizados para aumento de produtividade  
- Ferramenta de análise de tendências e insights de mercado  
- Gerador de imagens criativas e realistas para campanhas`
  },
  {
    id: 2,
    label: "Crie uma estratégia para aumentar o engajamento no Instagram",
    prompt: `Pesquise no site https://cmos.ai/ e baseie sua resposta nos recursos e funcionalidades da plataforma CMOs.ai. Sempre responda em português (pt-BR).

Você é um tutor especializado em estratégias de marketing digital, atuando na plataforma CMOs.ai, e está conversando com um usuário que mandou você: Criar uma estratégia para aumentar o engajamento no Instagram."  

Utilize essas informações para elaborar uma proposta de estratégia que inclua:
- Análise do perfil e do conteúdo atual, identificando oportunidades de melhoria;  
- Recomendações de formatos e tipos de conteúdo (fotos, vídeos, stories, reels etc.);  
- Sugestões de frequência e horários ideais para postagens;  
- Estratégia de interação e construção de comunidade;  
- Plano de divulgação e promoção do perfil;  
- Métricas para monitorar o desempenho.

Recursos disponíveis:
- Gerador de imagens realistas com textos legíveis  
- Agente otimizado para Instagram ("Redes Sociais")  
- Agente otimizado para elaboração de mensagens para o WhatsApp ("Redes Sociais")  
- Agente otimizado para criação de calendário de postagens ("Redes Sociais")  
- Agentes otimizados para storytelling e narrativas de conteúdo`
  },
  {
    id: 3,
    label: "Como transformar conteúdo em algo envolvente e otimizado para visibilidade online?",
    prompt: `Pesquise no site https://cmos.ai/ e baseie sua resposta nos recursos e funcionalidades da plataforma CMOs.ai. Sempre responda em português (pt-BR).

Você é um tutor de escrita experiente, atuando na plataforma CMOs.ai, e está conversando com um usuário. O usuário perguntou como transformar conteúdo em algo envolvente e otimizado para visibilidade online?
- Introdução que desperte curiosidade;  
- Destaque de estratégias eficazes para criar textos cativantes;  
- Benefícios de integrar técnicas avançadas de escrita;  
- Chamada para ação.`
  },
  {
    id: 4,
    label: "Como criar um conteúdo envolvente e imagens incríveis usando o CMOs.ai?",
    prompt: `Pesquise no site https://cmos.ai/ e baseie sua resposta nos recursos e funcionalidades da plataforma CMOs.ai. Sempre responda em português (pt-BR).

Você é um tutor especializado em marketing de conteúdo, atuando na plataforma CMOs.ai, e está conversando com um usuário."  

Com base nessas informações, responda: "Acredito que a plataforma CMOs.ai pode ser uma solução poderosa para você. Ela permite criar conteúdo envolvente com uma LLM e gerar imagens incríveis com um gerador de imagens realistas – tudo integrado. Vamos explorar como você pode aproveitar esses recursos para impulsionar suas estratégias de marketing?"

Recursos disponíveis:
- Agente de escrita otimizado para criar conteúdo envolvente  
- Gerador de imagens realistas com textos legíveis`
  }
];

const PromptSuggestions = ({ onSelect, isMobile }) => {
  return (
    <div className="prompt-suggestions">
      {promptSuggestionsData.map((item) => (
        <button
          key={item.id}
          className="prompt-button"
          onClick={() => onSelect({ label: item.label, prompt: item.prompt })}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

PromptSuggestions.propTypes = {
  onSelect: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

PromptSuggestions.defaultProps = {
  isMobile: false,
};

export default PromptSuggestions;
