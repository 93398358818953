import {useQuery} from "@tanstack/react-query";
import OrganizationRemote from "../../../services/dao/remote/OrganizationDAORemote";
import React, {useContext, useState} from "react";
import {UserContext} from "../../../utils/providers/userProvider";
import _ from "lodash";
import "./SettingsSubscription.css";
import SubscriptionPlans from "./components/SubscriptionPlans";
import BillingPeriodSelector from "./components/BillingPeriodSelector";
import ManageSubscriptionButton from "./components/ManageSubscriptionButton";

const SettingsSubscription = () => {
    const {user} = useContext(UserContext);
    const [billingPeriod, setBillingPeriod] = useState("yearly");

    const fetchSubscriptions = useQuery({
        queryKey: ['subscriptions'],
        queryFn: () => OrganizationRemote.getSubscriptionPlans(),
        refetchOnWindowFocus: false,
    });

    const getSubscriptionPlans = () => {
        if (fetchSubscriptions.data) {
            return fetchSubscriptions.data.filter((subscription) => subscription.idOrganization === 1);
        }
        return [];
    }

    const getActiveSubscription = () => {
        if (fetchSubscriptions.data) {
            return _.head(_.get(user, "activeSubscriptions", []));
        }
        return null;
    }

    return (
        <div className="h-full p-4">
            <div className="w-full flex justify-center xs:justify-end">
                <div className="flex flex-col xs:flex-row gap-4">
                    <div className="items-center mt-[6px]">
                        {(!_.isEmpty(getActiveSubscription()) && !getActiveSubscription()?.isManaged) && (
                            <ManageSubscriptionButton/>
                        )}
                    </div>
                    <div className="flex-col">
                        <BillingPeriodSelector
                            billingPeriod={billingPeriod}
                            onChange={(value) => setBillingPeriod(value)}
                        />
                    </div>
                </div>
            </div>
            <SubscriptionPlans
                billingPeriod={billingPeriod}
                subscriptionPlans={getSubscriptionPlans()}
                activeSubscription={getActiveSubscription()}
            />
        </div>
    );
};

export default SettingsSubscription;