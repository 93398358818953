//src\components\post-registration\PostRegistrationScreen.jsx

import { Button, Col, Input, Progress, Radio, Row, Select, Space, Tooltip, message } from "antd";
import LogoWhite from "../../images/logo-purple.svg";
import { Image } from "@aws-amplify/ui-react";
import "./PostRegistrationScreen.css";
import React, { cloneElement, useEffect, useRef, useState } from "react";
import {
  BarChartOutlined,
  BulbOutlined,
  DashboardOutlined,
  EditOutlined,
  FileDoneOutlined,
  InstagramOutlined,
  LaptopOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputMask } from "primereact/inputmask";
import _ from "lodash";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import UserInformationRemote from "../../services/dao/remote/UserInformationRemoteDAO";
import UserRemote from "../../services/dao/remote/user";
import useIsMobile from "../../utils/hooks/useIsMobile";
import CompleteRegistrationForm from "./CompleteRegistrationForm";
import { useLegalDocumentValidation } from "../../utils/hooks/useLegalDocumentValidation";

const PostRegistrationScreen = ({ onFinish, user, isLoading }) => {
  const isMobile = useIsMobile();

  const [currentScreen, setCurrentScreen] = useState(0);
  const [selectedBoxes, setSelectedBoxes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [companyName, setCompanyName] = useState("");
  const [companySegment, setCompanySegment] = useState(null);
  const [companyEmail, setCompanyEmail] = useState("");
  const [teamSize, setTeamSize] = useState(null);
  const [companySize, setCompanySize] = useState(null);
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [currentPosition, setCurrentPosition] = useState(null);
  const [customCurrentPosition, setCustomCurrentPosition] = useState(null);
  const [mainAtributions, setMainAtributions] = useState([]);
  const [biggestChallenge, setBiggestChallenge] = useState("");

  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState(null);
  const [usedSimilarTools, setUsedSimilarTools] = useState(null);
  const [similarTools, setSimilarTools] = useState(null);

  const numberOfScreens = 5;
  const timer = useRef();
  const firstScreenFocusRef = useRef();
  const secondScreenFocusRef = useRef();
  const completeRegistrationFormRef = useRef();

  const [isCompleteRegistrationFormValid, setCompleteRegistrationFormValid] = useState(false);

  const [entityType, setEntityType] = useState("individual"); // individual or company
  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");

  const [client, setClient] = useState("");
  const [phone, setPhone] = useState("");
  const [cep, setCep] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");

  const [complement, setComplement] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [cepValidateStatus, setCepValidateStatus] = useState("");

  const CEP_MASK = "00.000-000";
  const CPF_MASK = "000.000.000-00";
  const CNPJ_MASK = "00.000.000/0000-00";
  const PHONE_MASK = "(00) 00000-0000";

  const legalDocumentValidation = useLegalDocumentValidation();
  const queryClient = useQueryClient();

  const createUserInfo = useMutation({
    mutationFn: (userInformation) => UserInformationRemote.create(userInformation),
    onSuccess: () => {
      // Avança para a próxima tela após salvar as informações do usuário
      queryClient.invalidateQueries('user');
      goToNextPage();
    },
    onError: (error) => {
      console.error("createUserInfo.mutate onError", error);
      message.error("Ocorreu um erro ao salvar suas informações. Por favor, tente novamente.");
    },
  });

  const handleSaveUserInformation = () => {
    const userInformation = {
      entityType,
      name: client,
      cpf,
      cnpj,
      phoneNumber: phone,
      zipCode: cep,
      street: address,
      number,
      complement,
      neighborhood,
      city,
      state,
      companyName,
      companySegment,
      companySize,
      currentPosition,
      customCurrentPosition,
      biggestChallenge,
      selectedBoxes,
    };
    console.log("Saving user information:", userInformation);

    createUserInfo.mutate(userInformation);
  };

  const handlePressEnter = (event, disabled, callback) => {
    if (!disabled) {
      event.preventDefault();
      callback();
    }
  };

  const goToPreviousPage = () => {
    if (currentScreen > 0) setCurrentScreen(currentScreen - 1);
  };

  const goToNextPage = (onFocus) => {
    setCurrentScreen((prevScreen) => {
      if (prevScreen < numberOfScreens - 1) {
        const nextScreen = prevScreen + 1;
        if (onFocus) {
          setTimeout(() => {
            onFocus();
          }, 150);
        }
        return nextScreen;
      } else {
        console.log("Already at the last screen. Cannot advance further.");
        return prevScreen;
      }
    });
  };

  const filterSort = (optionA, optionB) => {
    if (optionA.label === "Outro" || optionB.label === "Outro") return 1;
    if (optionA.label === "Outras" || optionB.label === "Outras") return 1;
    return (optionA?.label ?? "")
      .toLowerCase()
      .localeCompare((optionB?.label ?? "").toLowerCase());
  };

  const filterOption = (input, option) => {
    return (option?.label ?? "")
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(
        input
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      );
  };

  const handleSelectBox = (box) => {
    if (selectedBoxes.includes(box)) {
      setSelectedBoxes(selectedBoxes.filter((b) => b !== box));
    } else {
      setSelectedBoxes([...selectedBoxes, box]);
    }
  };

  const isBoxSelected = (box) => {
    return selectedBoxes.includes(box);
  };

  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const getHowDidYouFindOutAboutSelectOptions = () => {
    return [
      { label: "Pesquisa na internet", value: "Pesquisa na internet" },
      {
        label: "Redes sociais (Facebook, LinkedIn, Instagram)",
        value: "Redes sociais (Facebook, LinkedIn, Instagram)",
      },
      { label: "Indicação de um amigo", value: "Indicação de um amigo" },
      { label: "Anúncios", value: "Anúncios" },
      { label: "Outro", value: "Outro" },
    ];
  };

  const getTeamSizeSelectOptions = () => {
    return [
      { label: "Trabalho sozinho", value: "1" },
      { label: "De 2 a 5 integrantes", value: "2-5" },
      { label: "De 6 a 10 integrantes", value: "6-10" },
      { label: "De 11 a 20 integrantes", value: "11-20" },
      { label: "Mais de 20 integrantes", value: "20+" },
    ];
  };

  const getCompanySizeSelectOptions = () => {
    return [
      { label: "Trabalho sozinho", value: "1" },
      { label: "De 2 a 10 funcionários", value: "2-10" },
      { label: "De 11 a 50 funcionários", value: "11-50" },
      { label: "De 51 a 200 funcionários", value: "51-200" },
      { label: "Mais de 200 funcionários", value: "200+" },
    ];
  };

  const getCompanySegmentSelectOptions = () => {
    return [
      { label: "Agricultura", value: "Agricultura" },
      { label: "Alimentação e bebidas", value: "Alimentação e bebidas" },
      { label: "Educação", value: "Educação" },
      { label: "Manufatura/indústria", value: "Manufatura/indústria" },
      { label: "Saúde e cuidados", value: "Saúde e cuidados" },
      { label: "Serviços financeiros", value: "Serviços financeiros" },
      { label: "Serviços profissionais", value: "Serviços profissionais" },
      { label: "Tecnologia da informação", value: "Tecnologia da informação" },
      { label: "Varejo", value: "Varejo" },
      { label: "Outro", value: "Outro" },
    ];
  };

  const getCurrentPositionSelectOptions = () => {
    return [
      { label: "Analista", value: "Analista" },
      { label: "Assistente", value: "Assistente" },
      { label: "CEO", value: "CEO" },
      { label: "CMO", value: "CMO" },
      { label: "CTO", value: "CTO" },
      { label: "Coordenador", value: "Coordenador" },
      { label: "Consultor", value: "Consultor" },
      { label: "Criador de conteúdo", value: "Criador de conteúdo" },
      { label: "Designer", value: "Designer" },
      { label: "Diretor", value: "Diretor" },
      { label: "Especialista", value: "Especialista" },
      { label: "Estagiário", value: "Estagiário" },
      { label: "Gerente", value: "Gerente" },
      { label: "Head", value: "Head" },
      { label: "Social media", value: "Social media" },
      { label: "Outro", value: "Outro" },
    ];
  };

  const getMainAtributionsSelectOptions = () => {
    return [
      { label: "Redes sociais", value: "Redes sociais" },
      { label: "Vendas", value: "Vendas" },
      { label: "Tráfego pago", value: "Tráfego pago" },
      { label: "Tráfego orgânico", value: "Tráfego orgânico" },
      { label: "Inbound", value: "Inbound" },
      { label: "Marketing digital", value: "Marketing digital" },
      { label: "Planejamento estratégico", value: "Planejamento estratégico" },
      { label: "Branding", value: "Branding" },
      { label: "Performance", value: "Performance" },
      { label: "Gestão de projetos", value: "Gestão de projetos" },
      { label: "Produção de conteúdo", value: "Produção de conteúdo" },
      { label: "SEO", value: "SEO" },
      { label: "Comunicação", value: "Comunicação" },
      { label: "Design", value: "Design" },
      { label: "CRM", value: "CRM" },
      { label: "Copywriting", value: "Copywriting" },
      { label: "Publicidade", value: "Publicidade" },
      { label: "E-mail marketing", value: "E-mail marketing" },
      { label: "Produção audiovisual", value: "Produção audiovisual" },
      { label: "Campanhas publicitárias", value: "Campanhas publicitárias" },
      { label: "Marketing de afiliados", value: "Marketing de afiliados" },
      { label: "Eventos", value: "Eventos" },
    ];
  };

  // useEffect para o temporizador da última tela
  useEffect(() => {
    if (currentScreen === numberOfScreens - 1) {
      console.log("Starting loading timer...");
      timer.current = setTimeout(() => {
        setLoading(false);
      }, getRandomNumber(5000, 15000)); // Corrigido para milissegundos

      // Limpa o temporizador quando o componente desmonta ou currentScreen muda
      return () => clearTimeout(timer.current);
    }
  }, [currentScreen, numberOfScreens]);

  const renderContainer = () => {
    return (
      <div className="PostRegistrationContainer">
        <div style={{ height: "52px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
              height: "100%",
            }}
          >
            <Image style={{ margin: "8px 24px 0 16px" }} src={LogoWhite} height="24px" />
          </div>

          <Progress
            className="PostRegistrationProgressBar"
            percent={(100 / numberOfScreens) * (currentScreen + 1)}
            showInfo={false}
            strokeLinecap="square"
            strokeColor="#724CF9"
          />
        </div>
        <div className="PostRegistrationContentContainer">
          <Row style={{ maxHeight: "100%", height: "100%" }}>
            <Col
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 16, offset: 4 }}
              lg={{ span: 12, offset: 6 }}
              xl={{ span: 10, offset: 7 }}
              xxl={{ span: 8, offset: 8 }}
              style={{ display: "flex", alignItems: "center" }}
            >
              {renderCurrentScreen()}
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const renderCurrentScreen = () => {
    switch (currentScreen) {
      case 0:
        return renderWelcomeScreen();
      case 1:
        return renderCompleteRegistrationScreen();
      case 2:
        return renderFirstScreen();
      case 3:
        return renderThirdScreen();
      case 4:
        return renderLastScreen();
      default:
        return renderLoading();
    }
  };

  const renderWelcomeScreen = () => {
    if (isLoading) {
      return renderLoading();
    }

    return (
      <div>
        <div
          style={{
            fontSize: "36px",
            fontFamily: "Outfit",
            fontWeight: "bold",
            textAlign: "left",
          }}
        >{`Olá, ${_.get(user, "name", "").split(" ")[0]}`}</div>
        <div style={{ fontSize: "24px", fontFamily: "Outfit", fontWeight: "bold", textAlign: "left" }}>
          Estamos muito felizes de ter você aqui!
        </div>
        <div
          style={{
            color: "gray",
            textAlign: "left",
            margin: "24px 0",
            fontFamily: "Outfit",
            fontSize: "16px",
          }}
        >
          Gostaríamos de fazer algumas perguntas para personalizar a sua experiência. Tudo bem?
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ margin: "24px" }}
            type="primary"
            size="large"
            onClick={() => goToNextPage(() => firstScreenFocusRef?.current?.focus())}
          >
            Vamos lá!
          </Button>
        </div>
      </div>
    );
  };

  const isValidPhoneNumber = (text) => /^\+55 \(\d{2}\) \d{5} \d{4}$/.test(text);
  const isValidEmail = (text) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(text);

  const renderFirstScreen = () => {
    const disabled =
      _.isEmpty(companyName) ||
      (!_.isEmpty(currentPosition) && currentPosition === "Outro" && _.isEmpty(customCurrentPosition)) ||
      _.isEmpty(companySegment) ||
      _.isEmpty(companySize) ||
      _.isEmpty(biggestChallenge);

    const disabledMessage = "Alguns campos não foram preenchidos corretamente.";

    const onFocus = () => {
      if (!isMobile) secondScreenFocusRef?.current?.focus();
    };

    return (
      <div className="PostRegistrationScreen">
        <div className="PostRegistrationScreenTitleContainer">
          <div className="PostRegistrationScreenTitle">Conte-nos um pouco sobre você</div>
        </div>

        <Row gutter={16} className="PostRegistrationFormFieldDividedContainer">
          <Col span={12}>
            <div className="PostRegistrationFormFieldTitle" style={{ marginBottom: 6 }}>
              Empresa
            </div>
            <Input
              size="large"
              placeholder="Company Inc."
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              onPressEnter={(e) => handlePressEnter(e, disabled, goToNextPage)}
              ref={secondScreenFocusRef}
            />
          </Col>

          <Col span={12}>
            <div className="PostRegistrationFormFieldTitle" style={{ marginBottom: 6 }}>
              Qual é o seu cargo atual?
            </div>
            <Select
              style={{ width: "100%" }}
              showSearch={!isMobile}
              dropdownMatchSelectWidth={false}
              size="large"
              allowClear={!isMobile}
              placeholder="Selecione uma opção"
              value={currentPosition}
              options={getCurrentPositionSelectOptions()}
              onChange={setCurrentPosition}
              filterOption={filterOption}
              filterSort={filterSort}
            />
            {!_.isEmpty(currentPosition) && currentPosition === "Outro" && (
              <div>
                <div className="PostRegistrationFormFieldSubtitle" style={{ margin: "4px 0 6px 0" }}>
                  Poderia descrevê-lo?
                </div>
                <Input
                  size="large"
                  value={customCurrentPosition}
                  onChange={(e) => setCustomCurrentPosition(e.target.value)}
                  placeholder="Descreva o título do seu cargo atual"
                />
              </div>
            )}
          </Col>
        </Row>

        <Row gutter={16} className="PostRegistrationFormFieldDividedContainer">
          <Col span={12}>
            <div className="PostRegistrationFormFieldTitle" style={{ marginBottom: 6 }}>
              Segmento da empresa
            </div>
            <Select
              style={{ width: "100%" }}
              showSearch={!isMobile}
              dropdownMatchSelectWidth={false}
              size="large"
              allowClear={!isMobile}
              placeholder="Selecione uma opção"
              value={companySegment}
              options={getCompanySegmentSelectOptions()}
              onChange={setCompanySegment}
            />
          </Col>

          <Col span={12}>
            <div className="PostRegistrationFormFieldTitle" style={{ marginBottom: 6 }}>
              Tamanho da empresa
            </div>
            <Select
              style={{ width: "100%" }}
              showSearch={!isMobile}
              dropdownMatchSelectWidth={false}
              size="large"
              allowClear={!isMobile}
              placeholder="Selecione uma opção"
              value={companySize}
              options={getCompanySizeSelectOptions()}
              onChange={setCompanySize}
            />
          </Col>
        </Row>

        <Row gutter={16} className="PostRegistrationFormFieldDividedContainer">
          <Col span={24}>
            <div className="PostRegistrationFormFieldTitle" style={{ marginBottom: 6 }}>
              Qual é o seu maior desafio hoje?
            </div>
            <Input.TextArea
              size="large"
              autoSize={{ minRows: 1, maxRows: 3 }}
              value={biggestChallenge}
              onChange={(e) => setBiggestChallenge(e.target.value)}
              placeholder="Aumentar o tráfego orgânico, aumentar a conversão de leads, aumentar a receita..."
              onPressEnter={(e) => handlePressEnter(e, disabled, () => goToNextPage(onFocus))}
            />
          </Col>
        </Row>

        <div className="PostRegistrationFormDoubleButton">
          <Button size="large" type="default" onClick={goToPreviousPage}>
            Voltar
          </Button>
          <Tooltip title={disabled ? disabledMessage : null}>
            <Button size="large" type="primary" onClick={() => goToNextPage(onFocus)} disabled={disabled}>
              Continuar
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderSecondScreen = () => {
    // const disabled = companyName.length < 1
    //     || companySegment == null
    //     || companySize == null
    //     || teamSize == null
    //     || !isValidEmail(companyEmail)
    //     || linkedinProfile.length < 1
    //     || !isValidPhoneNumber(phoneNumber);
    const disabled = false;

    const invalidMessage = () => {
      const invalidFields = [];
      if (!isValidPhoneNumber(phoneNumber)) invalidFields.push("'Telefone'");
      if (companyName.length < 1) invalidFields.push("'Empresa'");
      if (!isValidEmail(companyEmail)) invalidFields.push("'E-mail corporativo'");
      if (linkedinProfile.length < 1) invalidFields.push("'LinkedIn'");
      if (companySegment == null) invalidFields.push("'Segmento da empresa'");
      if (companySize == null) invalidFields.push("'Tamanho da empresa'");
      if (teamSize == null) invalidFields.push("'Tamanho do time'");
      return `Os campos ${invalidFields.join(", ")} não foram preenchidos corretamente.`;
    };

    return (
      <div className="PostRegistrationScreen">
        <div className="PostRegistrationScreenTitleContainer">
          <div className="PostRegistrationScreenTitle">Vamos completar o seu perfil</div>
          {!isMobile && (
            <div className="PostRegistrationScreenSubtitle">
              Suas respostas nos ajudam a criar uma melhor experiência para você
            </div>
          )}
        </div>

        <Row className="PostRegistrationFormFieldDividedContainer">
          <Col span={11}>
            <div className="PostRegistrationFormFieldTitle" style={{ marginBottom: 6 }}>
              Empresa
            </div>
            <Input
              size="large"
              placeholder="Company Inc."
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              onPressEnter={(e) => handlePressEnter(e, disabled, goToNextPage)}
              ref={secondScreenFocusRef}
            />
          </Col>

          <Col span={12} offset={1}>
            <div className="PostRegistrationFormFieldTitle" style={{ marginBottom: 6 }}>
              Segmento da empresa
            </div>
            <Select
              style={{ width: "100%" }}
              showSearch={!isMobile}
              dropdownMatchSelectWidth={false}
              size="large"
              allowClear={!isMobile}
              placeholder="Selecione uma opção"
              value={companySegment}
              options={getCompanySegmentSelectOptions()}
              onChange={setCompanySegment}
            />
          </Col>
        </Row>

        <Row className="PostRegistrationFormFieldDividedContainer">
          <Col span={11}>
            <div className="PostRegistrationFormFieldTitle" style={{ marginBottom: 6 }}>
              Tamanho do time
            </div>
            <Select
              style={{ width: "100%" }}
              showSearch={!isMobile}
              dropdownMatchSelectWidth={false}
              size="large"
              allowClear={!isMobile}
              placeholder="Selecione uma opção"
              value={teamSize}
              options={getTeamSizeSelectOptions()}
              onChange={setTeamSize}
            />
          </Col>
          <Col span={12} offset={1}>
            <div className="PostRegistrationFormFieldTitle" style={{ marginBottom: 6 }}>
              Tamanho da empresa
            </div>
            <Select
              style={{ width: "100%" }}
              showSearch={!isMobile}
              dropdownMatchSelectWidth={false}
              size="large"
              allowClear={!isMobile}
              placeholder="Selecione uma opção"
              value={companySize}
              options={getCompanySizeSelectOptions()}
              onChange={setCompanySize}
            />
          </Col>
        </Row>

        <div className="PostRegistrationFormFieldContainer">
          <div className="PostRegistrationFormFieldTitle" style={{ marginBottom: 6 }}>
            E-mail corporativo
          </div>
          <Input
            size="large"
            placeholder="name@company.com"
            value={companyEmail}
            onChange={(e) => setCompanyEmail(e.target.value)}
            onPressEnter={(e) => handlePressEnter(e, disabled, goToNextPage)}
          />
        </div>

        <Row className="PostRegistrationFormFieldDividedContainer">
          <Col span={11}>
            <div className="PostRegistrationFormFieldTitle" style={{ marginBottom: 6 }}>
              LinkedIn
            </div>
            <Input
              size="large"
              placeholder="linkedin.com/in/username"
              value={linkedinProfile}
              onChange={(e) => setLinkedinProfile(e.target.value)}
              onPressEnter={(e) => handlePressEnter(e, disabled, goToNextPage)}
            />
          </Col>
          <Col span={12} offset={1}>
            <div className="PostRegistrationFormFieldTitle" style={{ marginBottom: 6 }}>
              Telefone
            </div>
            <InputMask
              mask="+55 (99) 99999 9999"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              style={{ width: "100%" }}
              onPressEnter={(e) => handlePressEnter(e, disabled, goToNextPage)}
            >
              {() => <Input />}
            </InputMask>
          </Col>
        </Row>

        <div className="PostRegistrationFormDoubleButton">
          <Button size="large" type="default" onClick={goToPreviousPage}>
            Voltar
          </Button>
          <Tooltip title={disabled ? invalidMessage() : null}>
            <Button size="large" type="primary" disabled={disabled} onClick={() => goToNextPage()}>
              Continuar
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderCompleteRegistrationScreen = () => {
    const isFormValid = () => {
      const isValidDocument =
        entityType === "individual" ? legalDocumentValidation.validateCPF(cpf) : legalDocumentValidation.validateCNPJ(cnpj);
      return (
        isValidDocument &&
        _.get(client, "length", 0) > 3 &&
        _.get(phone, "length", 0) === PHONE_MASK.length &&
        cepValidateStatus === "success" &&
        _.get(address, "length", 0) > 0 &&
        _.get(number, "length", 0) > 0 &&
        _.get(complement, "length", 0) > 0 &&
        _.get(neighborhood, "length", 0) > 0 &&
        _.get(city, "length", 0) > 0 &&
        _.get(state, "length", 0) > 0
      );
    };

    return (
      <div className="PostRegistrationScreen">
        <div className="PostRegistrationScreenTitleContainer">
          <div className="PostRegistrationScreenTitle">Vamos completar o seu cadastro</div>
          {!isMobile && (
            <div className="PostRegistrationScreenSubtitle">
              Utilizaremos estes dados para a emissão da nota fiscal
            </div>
          )}
        </div>
        <CompleteRegistrationForm
          entityType={[entityType, setEntityType]}
          cpf={[cpf, setCpf]}
          cnpj={[cnpj, setCnpj]}
          client={[client, setClient]}
          phone={[phone, setPhone]}
          cep={[cep, setCep]}
          address={[address, setAddress]}
          number={[number, setNumber]}
          complement={[complement, setComplement]}
          neighborhood={[neighborhood, setNeighborhood]}
          city={[city, setCity]}
          state={[state, setState]}
          cepValidateStatus={[cepValidateStatus, setCepValidateStatus]}
        />
        <div className="PostRegistrationFormDoubleButton">
          <Button size="large" type="default" onClick={goToPreviousPage}>
            Voltar
          </Button>
          <Button size="large" type="primary" disabled={!isFormValid()} onClick={() => goToNextPage()}>
            Continuar
          </Button>
        </div>
      </div>
    );
  };

  const renderBox = (type, label, icon) => {
    const isSelected = isBoxSelected(type);

    const boxClasses = `PostRegistrationSelectableBox ${isSelected ? "PostRegistrationSelectableBoxSelected" : ""}`;
    const iconClasses = `PostRegistrationSelectableBoxIcon ${isSelected ? "PostRegistrationSelectableBoxIconSelected" : ""}`;
    const labelClasses = `PostRegistrationSelectableBoxLabel ${isSelected ? "PostRegistrationSelectableBoxLabelSelected" : ""}`;

    return (
      <div className={boxClasses} onClick={() => handleSelectBox(type)}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          {cloneElement(icon, { className: iconClasses })}
          <span className={labelClasses}>{label}</span>
        </div>
      </div>
    );
  };

  const renderThirdScreen = () => {
    const disabled = _.isEmpty(selectedBoxes);

    return (
      <div className="PostRegistrationScreen">
        <div className="PostRegistrationScreenTitleContainer">
          <div className="PostRegistrationScreenTitle">Como você planeja utilizar o CMOs.ai?</div>
        </div>

        <div className="PostRegistrationSelectableBoxContainer">
          {renderBox("writing", "Escrever", <EditOutlined />)}
          {renderBox("socialMedia", "Redes sociais", <InstagramOutlined />)}
          {renderBox("posts", "Posts", <FileDoneOutlined />)}
          {renderBox("ads", "Ads", <TagsOutlined />)}
          {renderBox("productivity", "Produtividade", <DashboardOutlined />)}
          {renderBox("insights", "Insights", <BulbOutlined />)}
          {renderBox("analytics", "Análises", <BarChartOutlined />)}
          {renderBox("learning", "Aprendizagem", <LaptopOutlined />)}
        </div>

        <div style={{ margin: "24px 0", width: "100%", display: "flex", justifyContent: "flex-start" }} />

        <div className="PostRegistrationFormDoubleButton">
          <Button size="large" type="default" onClick={goToPreviousPage}>
            Voltar
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={handleSaveUserInformation}
            disabled={disabled || createUserInfo.isLoading}
          >
            {createUserInfo.isLoading ? "Salvando..." : "Continuar"}
          </Button>
        </div>
      </div>
    );
  };

  const renderFourthScreen = () => {
    // const disabled = _.isEmpty(howDidYouHearAboutUs)
    //     || usedSimilarTools === null
    //     || (!!usedSimilarTools && _.isEmpty(similarTools));

    const disabled = false;

    const disabledMessage = "Alguns campos não foram preenchidos corretamente.";

    return (
      <div className="PostRegistrationScreen">
        <div className="PostRegistrationScreenTitleContainer">
          <div className="PostRegistrationScreenTitle">Como nos conheceu?</div>
          {!isMobile && (
            <div className="PostRegistrationScreenSubtitle">
              Suas respostas nos ajudam a criar uma melhor experiência.
            </div>
          )}
        </div>

        <div className="PostRegistrationFormFieldContainer">
          <div className="PostRegistrationFormFieldTitle">Como você ficou sabendo do CMOs.ai?</div>
          <Select
            showSearch={!isMobile}
            dropdownMatchSelectWidth={false}
            size="large"
            allowClear={!isMobile}
            placeholder="Selecione uma opção"
            value={howDidYouHearAboutUs}
            options={getHowDidYouFindOutAboutSelectOptions()}
            onChange={setHowDidYouHearAboutUs}
          />
        </div>

        <div className="PostRegistrationFormFieldContainer">
          <div className="PostRegistrationFormFieldTitle" style={{ marginBottom: "6px" }}>
            Você já utilizou alguma plataforma similar?
          </div>

          <Row>
            <Col span={2}>
              <Radio.Group onChange={(e) => setUsedSimilarTools(e.target.value)} value={usedSimilarTools}>
                <Space direction={"horizontal"}>
                  <Radio value={true}>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Space>
              </Radio.Group>
            </Col>
            <Col span={20} offset={2}></Col>
          </Row>
        </div>

        {!!usedSimilarTools && (
          <div className="PostRegistrationFormFieldContainer">
            <div className="PostRegistrationFormFieldSubtitle">Quais?</div>
            <Input
              size="large"
              value={similarTools}
              onChange={(e) => setSimilarTools(e.target.value)}
              placeholder="Cite algumas plataformas similares ao CMOs.ai que você já utilizou"
              onPressEnter={(e) => handlePressEnter(e, disabled, goToNextPage)}
            />
          </div>
        )}

        <div className="PostRegistrationFormDoubleButton">
          <Button size="large" type="default" onClick={goToPreviousPage}>
            Voltar
          </Button>
          <Tooltip title={disabled ? disabledMessage : null}>
            <Button size="large" type="primary" onClick={() => goToNextPage()} disabled={disabled}>
              Continuar
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderLastScreen = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {loading && (
          <>
            <div
              style={{
                fontSize: "42px",
                fontFamily: "Outfit",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Agradecemos as respostas!
            </div>
            <div
              style={{
                fontSize: "24px",
                fontFamily: "Outfit",
                fontWeight: "bold",
                textAlign: "center",
                margin: "12px 0 48px 0",
              }}
            >
              Aguarde enquanto criamos uma experiência personalizada para você.
            </div>
            <ProgressSpinner strokeWidth="2" />
          </>
        )}
        {!loading && (
          <>
            <div
              style={{
                fontSize: "42px",
                fontFamily: "Outfit",
                fontWeight: "bold",
                textAlign: "center",
                margin: "12px 0 32px 0",
              }}
            >
              Tudo pronto!
            </div>
            <Button style={{ margin: "24px" }} type="primary" size="large" onClick={onFinish}>
              Começar
            </Button>
          </>
        )}
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div>
        <ProgressSpinner />
      </div>
    );
  };

  return renderContainer();
};

export default PostRegistrationScreen;