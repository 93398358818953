// src/components/text-input/TextInput.jsx
import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind";

// Ícones do lucide-react (adicionamos File e X)
import { Paperclip, Globe, Send, File as FileIcon, X } from "lucide-react";

// Outras dependências
import { Tooltip, Spin, message } from "antd";

// Import do SpecialtySelector e BrandVoiceSelector
import SpecialtySelector from "../chat/SpecialtySelector";
import BrandVoiceSelector from "../chat/BrandVoiceSelector";

// Import do componente TypewriterPlaceholder
import TypewriterPlaceholder from "./TypewriterPlaceholder";

import "./TextInput.css";

class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      // Estados referentes ao upload
      uploadedFileUri: null,
      uploadedFileName: null,
      uploadedFileType: null,
      isUploadingFile: false,
      // Estado para controlar se o input está focado
      inputFocused: false,
    };

    this.textareaRef = createRef();
    this.fileInputRef = createRef();
    this.userAlreadyTyped = false;

    autoBind(this);
  }

  componentDidMount() {
    this.focus();
  }

  focus() {
    if (this.textareaRef.current) {
      this.textareaRef.current.focus();
    }
  }

  clearInput() {
    this.setState({ text: "" }, () => {
      // Sempre que limpar, zera a altura novamente
      this.autoGrow();
    });
  }

  /**
   * autoGrow: Ajusta dinamicamente a altura do textarea enquanto tiver até 300px
   */
  autoGrow() {
    const ta = this.textareaRef.current;
    if (!ta) return;
    // Reseta a altura para calcular o scrollHeight correto
    ta.style.height = "auto";
    const maxHeight = 300; // altura máxima em pixels
    const newHeight = ta.scrollHeight;
    if (newHeight < maxHeight) {
      ta.style.height = `${newHeight}px`;
      ta.style.overflowY = "hidden";
    } else {
      ta.style.height = `${maxHeight}px`;
      ta.style.overflowY = "auto";
    }
  }

  handleUserFirstTyping() {
    this.userAlreadyTyped = true;
  }

  handleTextChange(e) {
    const text = e.target.value;
    if (!this.userAlreadyTyped) {
      this.handleUserFirstTyping();
    }
    this.setState({ text }, () => {
      this.autoGrow();
    });
  }

  handleFocus() {
    this.setState({ inputFocused: true });
  }

  handleBlur() {
    this.setState({ inputFocused: false });
  }

  /**
   * handleSubmit enviará text + fileUri para o componente-pai via onSubmit({ text, fileUri }).
   */
  handleSubmit() {
    const { text, uploadedFileUri } = this.state;
    // Desestruture searchEnabled das props para enviá-lo junto
    const { onSubmit, searchEnabled } = this.props;
  
    if (!text.trim() && !uploadedFileUri) {
      return;
    }
  
    // Limpamos o textarea
    this.clearInput();
  
    // Callback do pai: agora inclui a flag search_enabled
    onSubmit({
      text: text.trim(),
      fileUri: uploadedFileUri || null,
      search_enabled: searchEnabled,
    });
  
    // Zera o estado do arquivo
    this.removeUploadedFile();
  }

  handleKeyDown(e) {
    // Se Enter e não Shift, submete
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      this.handleSubmit();
    }
  }

  /**
   * Upload do arquivo
   */
  async handleFileChange(e) {
    const file = e.target.files?.[0];
    if (!file) return;
    await this.uploadFile(file);
  }

  async uploadFile(file) {
    const { REACT_APP_BACKEND_URL } = process.env;
    const formData = new FormData();
    formData.append("file", file);

    this.setState({ isUploadingFile: true });

    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/upload`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        const { file_uri, mime_type } = data;
        this.setState({
          uploadedFileUri: file_uri,
          uploadedFileName: file.name,
          uploadedFileType: mime_type,
        });
        message.success(
          "Upload realizado com sucesso! Agora você pode usar o arquivo para contexto na sua pergunta."
        );
      } else {
        message.error("Falha ao enviar o arquivo.");
      }
    } catch (error) {
      console.error("Erro ao enviar o arquivo:", error);
      message.error("Erro ao enviar o arquivo.");
    } finally {
      this.setState({ isUploadingFile: false });
    }
  }

  removeUploadedFile() {
    this.setState({
      uploadedFileUri: null,
      uploadedFileName: null,
      uploadedFileType: null,
    });
  }

  /**
   * Função para criar um botão de ícone
   */
  renderIconButton(children, onClick = () => {}) {
    return (
      <button className="icon-button" onClick={onClick}>
        {children}
      </button>
    );
  }

  /**
   * Botão de enviar ou spinner se a IA estiver respondendo
   */
  renderSendButton() {
    const { isAIWriting } = this.props;
    if (isAIWriting) {
      return <Spin style={{ marginRight: 8 }} />;
    }
    return this.renderIconButton(<Send className="icon" />, this.handleSubmit);
  }

  /**
   * Ações ao clicar no ícone do Paperclip
   */
  handlePaperclipClick() {
    const { isMobile, isGeminiModel } = this.props;
    const { isUploadingFile, uploadedFileUri } = this.state;

    if (!isGeminiModel) {
      if (isMobile) {
        message.info(
          "Envio de arquivos só está habilitado no modelo Gemini para resultados mais contextuais."
        );
      }
      return;
    }

    if (isUploadingFile) {
      if (isMobile) {
        message.info("Aguarde, estamos enviando seu arquivo...");
      }
      return;
    }

    if (uploadedFileUri) {
      if (isMobile) {
        message.info("Você já anexou um arquivo. Se quiser remover, clique no X.");
      }
      return;
    }

    this.fileInputRef.current?.click();
  }

  /**
   * Ações ao clicar no ícone do Globe
   */
  handleGlobeClick() {
    const { isDeepSeek, isOpenAIModel, searchEnabled, onToggleSearch, isMobile } = this.props;
    
    if (isDeepSeek) {
      // DeepSeek sempre busca na internet.
      message.info(
        isMobile
          ? "DeepSeek ativo! Garante resultados ainda mais completos com busca e cadeia de pensamento em servidor privado."
          : "DeepSeek ativo: aproveite as buscas avançadas e a cadeia de pensamento para melhores respostas em servidor privado!"
      );
    } else if (isOpenAIModel) {
      // Para modelos OpenAI, alterna o estado da busca.
      onToggleSearch();
      message.info(
        searchEnabled
          ? "Busca desativada. A consulta será enviada sem pesquisa na internet."
          : "Busca ativada. A consulta incluirá resultados da internet."
      );
    } else {
      message.info(
        isMobile
          ? "Selecione um modelo OpenAI ou DeepSeek para usar a busca na internet."
          : "A funcionalidade de busca está disponível apenas para modelos OpenAI ou DeepSeek."
      );
    }
  }

  render() {
    const { text, isUploadingFile, uploadedFileUri, inputFocused } = this.state;
    const {
      disabled,
      brandVoice,
      onBrandVoiceChange,
      isGeminiModel,
      isAIWriting,
      specialty,
      onSpecialtyChange,
      isMobile,
      isDeepSeek,
      isOpenAIModel,    // Desestruturado
      searchEnabled,    // Desestruturado
      onToggleSearch,   // Desestruturado
    } = this.props;

    const overLimit = text.length > 300;

    // Frases criativas para o efeito typewriter
    const creativeSentences = [
      "Desperte sua criatividade: qual ideia revolucionária te inspira hoje?",
      "Imagine uma solução que ninguém ousou pensar – conte-nos sua dúvida!",
      "Sua mente é um universo em expansão: que pergunta vai desvendar seus segredos?",
      "Inove e surpreenda: qual insight inédito você tem para compartilhar?",
      "Deixe o comum para trás – escreva a pergunta que desafia o óbvio!",
      "Transforme sua curiosidade em revolução: qual visão ousada você tem?",
      "Que mistério você quer desvendar hoje? Solte sua imaginação!",
      "Pense fora da caixa: sua pergunta pode ser a chave para um novo mundo!",
      "Escreva o inédito – qual desafio vai acender sua chama criativa?",
      "Dê voz à sua genialidade: que pergunta iluminará seu caminho?"
    ];

    return (
      <div className="container">
        <div className="input-container">
          {/* Linha superior: textarea + botão de enviar */}
          <div className="input-wrapper" style={{ position: "relative" }}>
            <textarea
              ref={this.textareaRef}
              className={`message-input ${overLimit ? "over-limit" : ""}`}
              disabled={disabled}
              value={text}
              onChange={this.handleTextChange}
              onKeyDown={this.handleKeyDown}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              style={{
                position: "relative",
                zIndex: 2,
                background: "transparent",
              }}
            />
            {/* Exibe o placeholder customizado somente se o campo estiver vazio e não estiver focado */}
            {!text && !inputFocused && (
              <div
                className="custom-placeholder"
                style={{
                  position: "absolute",
                  top: "0.5rem",
                  left: "0.5rem",
                  right: "3rem",
                  zIndex: 1,
                  pointerEvents: "none",
                  color: "#999",
                }}
              >
                <TypewriterPlaceholder sentences={creativeSentences} />
              </div>
            )}
            {this.renderSendButton()}
          </div>

          {/* Linha inferior de ícones: BrandVoice, Specialty, Upload, Calendar, Globe */}
          <div className="icon-group">
            {this.renderIconButton(
              <BrandVoiceSelector
                brandVoice={brandVoice}
                onBrandVoiceChange={onBrandVoiceChange}
              />
            )}

            {this.renderIconButton(
              <SpecialtySelector
                idSpecialty={specialty?.idSpecialty}
                onChange={onSpecialtyChange}
                isMobile={isMobile}
              />
            )}

            {/* Ícone de upload */}
            {isMobile ? (
              this.renderIconButton(
                <Paperclip className="icon" />,
                this.handlePaperclipClick
              )
            ) : (
              <Tooltip
                title={
                  !isGeminiModel
                    ? "Envio de arquivos só está disponível no modelo Gemini para contextualizar suas perguntas."
                    : "Anexe um arquivo (pdf, imagem etc.) para enriquecer sua consulta."
                }
              >
                {this.renderIconButton(
                  <Paperclip className="icon" />,
                  this.handlePaperclipClick
                )}
              </Tooltip>
            )}

            {/* Se já existe arquivo e não está enviando, mostrar ícone + X */}
            {uploadedFileUri && !isUploadingFile && (
              <span
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  marginLeft: "0.5rem",
                }}
              >
                <FileIcon className="icon" />
                <X
                  className="icon remove-file-icon"
                  style={{ marginLeft: "0.25rem", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.removeUploadedFile();
                  }}
                />
              </span>
            )}

            {/* input de arquivo oculto */}
            <input
              type="file"
              style={{ display: "none" }}
              ref={this.fileInputRef}
              onChange={this.handleFileChange}
            />

              {/* Ícone do Globe */}
              {isMobile ? (
                this.renderIconButton(
                  <Globe
                    className="icon"
                    style={{
                      // Se for DeepSeek ou se for OpenAI e a busca estiver ativa, destaca com verde.
                      color: (isDeepSeek || (isOpenAIModel && searchEnabled)) ? "#47b149" : "inherit",
                      cursor: (isDeepSeek || isOpenAIModel) ? "pointer" : "not-allowed",
                    }}
                  />,
                  this.handleGlobeClick
                )
              ) : (
                <Tooltip
                  title={
                    isDeepSeek
                      ? "DeepSeek ativo: a busca na internet está sempre ativa."
                      : isOpenAIModel
                      ? (searchEnabled
                          ? "Clique para desativar a busca na internet."
                          : "Clique para ativar a busca na internet.")
                      : "Selecione um modelo OpenAI ou DeepSeek para usar a busca na internet."
                  }
                >
                  {this.renderIconButton(
                    <Globe
                      className="icon"
                      style={{
                        color: (isDeepSeek || (isOpenAIModel && searchEnabled)) ? "#47b149" : "inherit",
                        cursor: (isDeepSeek || isOpenAIModel) ? "pointer" : "not-allowed",
                      }}
                    />,
                    this.handleGlobeClick
                  )}
                </Tooltip>
              )}
          </div>
        </div>
      </div>
    );
  }
}

TextInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  brandVoice: PropTypes.object,
  onBrandVoiceChange: PropTypes.func,
  isGeminiModel: PropTypes.bool,
  isAIWriting: PropTypes.bool,
  specialty: PropTypes.object,
  onSpecialtyChange: PropTypes.func,
  isMobile: PropTypes.bool,
  isDeepSeek: PropTypes.bool,
  isOpenAIModel: PropTypes.bool,
  searchEnabled: PropTypes.bool,
  onToggleSearch: PropTypes.func,
};

TextInput.defaultProps = {
  disabled: false,
  brandVoice: null,
  onBrandVoiceChange: () => {},
  isGeminiModel: false,
  isAIWriting: false,
  specialty: null,
  onSpecialtyChange: () => {},
  isMobile: false,
  isDeepSeek: false,
  isOpenAIModel: false,
  searchEnabled: false,
  onToggleSearch: () => {},
};

export default TextInput;
