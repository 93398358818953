// src\services\dao\remote\UserInformationRemoteDAO.js

import {urlBase, params} from "./resources";

const url = urlBase + "/api/user";


async function create(data) {
    const content = {body: data};
    return fetch(`${url}/info`, await params.put(content));
}

async function createPersonalInformation(data) {
    const content = {body: data};
    return fetch(`${url}/info/p`, await params.put(content));
}

const UserInformationRemote = {
    create,
    createPersonalInformation
};

export default UserInformationRemote;