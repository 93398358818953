import {Rate} from "antd";

const RateWrapper = (props) => {
    const handleClick = (event) => {
        event.stopPropagation();
    }

    return (
        <>
            <div onClick={handleClick}>
                <Rate {...props}/>
            </div>
        </>
    );
}

export default RateWrapper;