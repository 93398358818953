// brainwave-ui-develop\src\components\tools\Tool.jsx

import React, {Component, useContext, useEffect, useState} from "react";
import autoBind from "auto-bind";
import {useLocation, useParams} from "react-router-dom";
import {ToolsRemote} from "../../services/dao/remote/tools";
import {
    Col, Drawer,
    FloatButton,
    Form,
    Input,
    Row,
    Skeleton, Tooltip,
    Modal, Avatar, Tabs, notification, message
} from "antd";
import "./Tool.css";
import _ from "lodash";
import ResultCard from "./ResultCard";
import {v4 as uuidv4} from 'uuid';
import {replace} from "../../utils/functions/collection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCircleExclamation,
    faFireFlameCurved, faPencil, faStopwatch, faWandSparkles
} from "@fortawesome/free-solid-svg-icons";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import StopGenerationButton from "./StopGenerationButton";
import ToolExample from "./ToolExample";
import {SelectButton} from 'primereact/selectbutton';
import withIsMobile from "../../utils/hocs/withIsMobile";
import MainHeaderTitle from "../main-header/MainHeaderTitle";
import ToolForm from "./ToolForm";
import {Theme} from "../../api/Theme";
import {TabPanel, TabView} from "primereact/tabview";
import LoadingScreen from "../loading-screen/LoadingScreen";
import {OrganizationContext} from "../../utils/providers/organizationProvider";
import {UserContext} from "../../utils/providers/userProvider";
import {useWalletService} from "../../utils/hooks/useWalletService";
import useOrganizationService from "../../utils/hooks/useOrganizationService";
import {useGenerationModelClass} from "../../utils/hooks/useGenerationModelClass";
import GenerationModelClassSelector from "../../app/generation-model-class/GenerationModelClassSelector";
import CreditsCounter from "../../app/generation-model-class/CreditsCounter";
import {useWallet} from "../../utils/hooks/useWallet";


class Tool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tool: {},
            values: {},

            outputs: 1,

            results: [],

            isInitialState: true,
            isLoading: false,
            isGenerating: false,
            isExampleRunning: false,
            isProcessingStream: false,

            showDrawer: false,
            showClearConfirmModal: false,

            switchScreenValue: "Inputs",
            tabActiveIndex: 0,
        };

        this.drawerTitle = "";
        this.drawerContent = "";

        this.cachedResults = [];
        this.previousResultsLength = 0;

        this.toolResultsComponent = React.createRef();

        this.stopGeneration = false;
        this.autoScroll = true;

        this.messageDelayWarningTimeout = null;
        this.brandVoice = null;

        autoBind(this);
    }

    componentDidMount() {
        const {params, organization} = this.props;
        const {idTool} = params;

        const {setHeaderTitle} = this.props;

        if (idTool) {
            this.setState({isLoading: true});

            if (organization) {
                ToolsRemote.get(idTool)
                    .then((tool) => {
                        this.setState({tool, outputs: tool.outputs, isLoading: false});
                        setHeaderTitle(this.renderToolTitle());
                        document.title = `${tool.name} · CMOs.ai`;
                    });
            } else {
                ToolsRemote.get(idTool)
                    .then((tool) => {
                        this.setState({tool, outputs: tool.outputs, isLoading: false});
                        setHeaderTitle(this.renderToolTitle());
                        document.title = `${tool.name} · CMOs.ai`;
                    });
            }

        }
    }

    componentWillUnmount() {
        const {setHeaderTitle} = this.props;
        setHeaderTitle(null);
    }

    generateResultName = (index) => {
        return `# sugestão ${index + 1}`;
    }

    openDrawer = (field) => {
        this.drawerTitle = field.name;
        this.drawerContent = field.description;
        this.setState({showDrawer: true});
    }

    closeDrawer = () => {
        this.setState({showDrawer: false});
    }

    openRequestErrorNotification = () => {
        const {notificationApi} = this.props;

        notificationApi['error']({
            message: 'Erro ao processar requisição',
            description: (
                <>
                    <div style={{padding: "5px 0"}}>Estamos com um <b>alto nível de utilização</b> no momento, o que
                        está ocasionando <b>lentidão no sistema</b>. Nosso time já está atuando para resolver o
                        problema.
                    </div>
                    <div style={{padding: "6px 0"}}>Pedimos desculpas pelo inconveniente e agradecemos a sua
                        compreensão.
                    </div>
                </>
            ),
            duration: 60
        });
    }

    openMessageDelayWarning = () => {
        const {messageApi} = this.props;

        messageApi.open({
            key: 'message-delay-warning-tool',
            type: 'loading',
            content: 'A resposta está demorando um pouco mais do que o esperado...',
            duration: 0
        });
    }

    translateDifficultyLevelLabel = (difficultyLevel) => {
        switch (difficultyLevel) {
            case "beginner":
                return "Nível iniciante";
            case "intermediate":
                return "Nível intermediário";
            case "advanced":
                return "Nível avançado";
            default:
                return difficultyLevel;
        }
    }

    startMessageDelayWarningTimeout = () => {
        this.messageDelayWarningTimeout = setTimeout(this.openMessageDelayWarning, 7500);
    }

    stopMessageDelayWarningTimeout = () => {
        const {messageApi} = this.props;

        messageApi.destroy('message-delay-warning-tool');
        clearTimeout(this.messageDelayWarningTimeout);
    }

    handleOpenDrawer(field) {
        this.openDrawer(field);
    }

    handleIncreaseOutput() {
        const {tool, outputs} = this.state;
        const maxOutputs = _.get(tool, "maxOutputs", 5);

        if (outputs < maxOutputs) {
            this.setState({outputs: outputs + 1});
        }
    }

    handleDecreaseOutput() {
        const {outputs} = this.state;

        if (outputs > 1) {
            this.setState({outputs: outputs - 1});
        }
    }

    handleEditResult(result, content) {
        const results = replace(
            Object.assign(result, {content}),
            this.state.results,
            _.matchesProperty("idToolResult", result.idToolResult)
        );
        this.setState({results});
    }

    handleRemoveResult(result) {
        const results = _.reject([...this.state.results], _.matchesProperty("idToolResult", result.idToolResult));
        this.setState({results});
    }

    handleRateResult(result, rating) {
        const results = replace(
            Object.assign(result, {rating}),
            this.state.results,
            _.matchesProperty("idToolResult", result.idToolResult)
        );
        this.setState({results});
    }

    handleResultTitleChange(result, title) {
        const results = replace(
            Object.assign(result, {title}),
            this.state.results,
            _.matchesProperty("idToolResult", result.idToolResult)
        );
        this.setState({results});
    }

    handleSaveResult(result, title) {
        // const {tool} = this.state;
        // console.log(result)
        //
        // const toolResult = {
        //     idTool: tool.idTool,
        //     title: result.title,
        //     content: result.content,
        //     rating: result.rating,
        // }
        //
        // ToolResutRemote.create()
    }

    handleClearResults() {
        this.cachedResults = [];
        this.setState({results: []});
        this.handleCloseClearConfirmModal();
    }

    handleOpenClearConfirmModal() {
        this.setState({showClearConfirmModal: true});
    }

    handleCloseClearConfirmModal() {
        this.setState({showClearConfirmModal: false});
    }

    handleScrollDetector(event) {
        const {scrollTop, scrollHeight, clientHeight} = event.target;
        const atBottom = scrollTop + clientHeight >= scrollHeight;
        this.autoScroll = atBottom;
    }

    handleScrollDown(force = false) {
        if (force) this.autoScroll = true;

        if (this.autoScroll && this.toolResultsComponent && this.toolResultsComponent.current) {
            this.toolResultsComponent.current.scrollTop = this.toolResultsComponent.current.scrollHeight;
        }
    }

    handleStopGeneration() {
        this.stopGeneration = true;
        setTimeout(() => {
            this.stopGeneration = false;
        }, 1000);
    }

    handleBrandVoiceChange(brandVoice) {
        this.brandVoice = brandVoice;
    }

    async onGenerateResult(values, isExample = false) {
        const {userRefetch, generationModelClass, refetchCredits} = this.props;
        const {tool, outputs} = this.state;

        this.previousResultsLength = this.cachedResults.length;
        this.cachedResults = this.cachedResults.concat(Array(outputs).fill({
            content: "",
            rating: null,
        }).map((result, index) => {
            return {
                ...result,
                title: this.generateResultName(index + this.previousResultsLength),
                idToolResult: uuidv4()
            };
        }));

        this.setState({
            isGenerating: true,
            results: this.cachedResults,
            switchScreenValue: "Resultados",
            tabActiveIndex: 1
        });
        this.startMessageDelayWarningTimeout();

        try {
            const {controller, reader} = await ToolsRemote.generate({
                idTool: tool.idTool,
                values,
                outputs,
                isExample,
                generationModelClass,
                brandVoice: this.brandVoice
            });

            this.stopMessageDelayWarningTimeout()
            this.setState({isProcessingStream: true});
            this.handleScrollDown(true);
            this.startStateUpdateCachedResultsInterval();
            await this.processGeneratedResultsStream(controller, reader);
        } catch (e) {
            this.openRequestErrorNotification();
        } finally {
            setTimeout(() => {
                userRefetch();
            }, 2000);
            this.stopMessageDelayWarningTimeout();
        }

        this.stopStateUpdateCachedResultsInterval();
        this.setState({isGenerating: false, isProcessingStream: false});
        this.handleScrollDown(true);
        refetchCredits();
    }

    async processGeneratedResultsStream(controller, reader) {
        const separator = '\x1F\x1E';
        
        let done = false;
        while (!done) {
          if (this.stopGeneration === true) {
            controller.abort();
            done = true;
            break;
          }
      
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
      
          if (value) {
            // Decodifica o chunk em texto
            const chunkString = new TextDecoder("utf-8").decode(value);
            console.log("CHUNK recebido:", chunkString);
      
            // Dividir pelo separador usado para OpenAI
            const rawArray = chunkString.split(separator).slice(0, -1);
      
            // Se o chunk não tiver esse separador (caso Perplexity não mande),
            // então `rawArray` provavelmente será vazio. Podemos tratar isso:
            if (rawArray.length === 0) {
              // Significa que é um chunk puro (Perplexity)
              // -> Concatene no último (ou primeiro) resultado
              // Ex.: se tiver apenas 1 output, index = 0
              const index = this.previousResultsLength; 
              this.cachedResults[index].content += chunkString;
            }
      
            rawArray.forEach((text) => {
              console.log("Trecho pós-separador:", text);
              try {
                const data = JSON.parse(text);   // Tenta parsear JSON para GPT/OpenAI
                const index = data.index + this.previousResultsLength;
                this.cachedResults[index].content += data.content;
              } catch (e) {
                console.warn("Não é JSON válido; pode ser Perplexity. Fallback para texto puro.");
                // Fallback: concatenar texto puro no último ou primeiro 'result'
                const index = this.previousResultsLength;
                this.cachedResults[index].content += text;
              }
            });
          }
        }
      }

    startStateUpdateCachedResultsInterval() {
        this.stateUpdateCachedResultsInterval = setInterval(() => {
            this.setState({results: this.cachedResults});
            this.handleScrollDown();
        }, 25);
    }

    stopStateUpdateCachedResultsInterval() {
        clearInterval(this.stateUpdateCachedResultsInterval);
    }

    handleStartExample() {
        this.setState({isExampleRunning: true});
    }

    handleGenerateExample() {
        this.setState({isExampleRunning: true, tabActiveIndex: 1});
        const values = this.handleExampleData();
        this.setState({values}, () => {
            this.onGenerateResult(this.state.values, true)
                .then(() => {
                        this.setState({isExampleRunning: false});
                    }
                );
        });
    }

    handleFinishExample() {
        this.setState({isExampleRunning: false});
    }

    handleExampleData() {
        const {tool} = this.state;

        return _.get(tool, "fields", []).map((field) => {
            return {[field.token]: field.exampleValue};
        }).reduce((acc, curr) => {
            return {...acc, ...curr};
        });
    }

    handleSwitchScreenValueChange(value) {
        this.setState({switchScreenValue: value});
    }

    renderFixedFields() {
        return (
            <Form.Item label="teste" name="teste" rules={[{required: true}]}>
                <Input placeholder="Documento sem título"/>
            </Form.Item>
        );
    }

    renderDrawer() {
        const {showDrawer} = this.state;
        const {isMobile} = this.props;

        return (
            <div className="ToolDrawer">
                <Drawer
                    title={this.drawerTitle}
                    placement="right"
                    open={showDrawer}
                    onClose={this.closeDrawer}
                    style={{fontFamily: "Outfit"}}
                    width={isMobile ? 280 : 650}
                    closable={false}
                >
                    {this.renderDrawerContent()}
                </Drawer>
            </div>
        );
    }

    renderDrawerContent() {
        return (
            <div className="ToolDrawerContent">
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                >
                    {this.drawerContent}
                </ReactMarkdown>
            </div>
        );
    }

    renderForm() {
        const {tool, isLoading, isGenerating, outputs, results, isExampleRunning, values} = this.state;
        return (
            <>
                <div className="flex flex-row items-center justify-between bg-white px-4 py-2">
                    <GenerationModelClassSelector/>
                    <CreditsCounter/>
                </div>
                <ToolForm
                    tool={tool}
                    isLoading={isLoading}
                    isGenerating={isGenerating}
                    onOpenDrawer={this.handleOpenDrawer}
                    outputs={outputs}
                    handleDecreaseOutput={this.handleDecreaseOutput}
                    handleIncreaseOutput={this.handleIncreaseOutput}
                    onGenerateResult={this.onGenerateResult}
                    resultsLength={results ? results.length : 0}
                    isExampleRunning={isExampleRunning}
                    exampleValues={values}
                    brandVoice={this.brandVoice}
                    onBrandVoiceChange={this.handleBrandVoiceChange}
                />
            </>
        );
    }

    renderToolExample() {
        const {tool} = this.state;

        if (_.get(tool, "introduction.hasExample", false)) {
            return (
                <ToolExample
                    onStartExample={this.handleStartExample}
                    onFillInputs={this.handleExampleData}
                    onStartGeneration={this.onGenerateResult}
                    onFinishExample={this.handleFinishExample}
                    artifact={tool.artifact}
                    onGenerateExample={this.handleGenerateExample}
                />
            );
        }
    }

    renderToolIntroductionLabels() {
        const {tool} = this.state;
        return (
            <div className="ToolIntroductionTags">
                <Tooltip
                    title="Tempo estimado para configurar esta ferramenta"
                    color={Theme.PRIMARY}
                    key="tool-time-tooltip"
                >
                    <label className="ToolIntroductionTag">
                        <FontAwesomeIcon icon={faStopwatch} className="ToolIntroductionTagIcon"/>
                        {_.get(tool, "introduction.estimatedTime", 5)} min
                    </label>
                </Tooltip>

                <Tooltip
                    title="Nível de expertise necessária para utilizar esta ferramenta"
                    color={Theme.PRIMARY}
                    key="tool-level-tooltip"
                >
                    <label className="ToolIntroductionTag">
                        <FontAwesomeIcon icon={faFireFlameCurved} className="ToolIntroductionTagIcon"/>
                        {this.translateDifficultyLevelLabel(_.get(tool, "introduction.difficultyLevel", ""))}
                    </label>
                </Tooltip>
            </div>
        );
    }

    renderToolIntroduction() {
        const {tool, isLoading} = this.state;

        if (isLoading) {
            return (
                <div className="ToolIntroductionContainer">
                    <div className="ToolIntroductionWrapper">
                        <Skeleton avatar paragraph={{rows: 4}} active/>
                    </div>
                </div>
            )
        }

        return (
            <div className="ToolIntroductionContainer">
                <div className="ToolIntroductionWrapper">
                    <div className="ToolIntroduction">
                        <div className="ToolIntroductionHeader">
                            <div className="ToolIntroductionTitle">
                                <Avatar
                                    shape={"square"}
                                    size={52}
                                    src={process.env.REACT_APP_TOOL_ICON_S3_BUCKET_PREFIX_URL + tool.icon}
                                />
                                {tool.name}
                            </div>
                            {this.renderToolIntroductionLabels()}
                        </div>

                        <div className="ToolIntroductionText">
                            <ReactMarkdown
                                rehypePlugins={[rehypeRaw]}
                            >
                                {_.get(tool, "introduction.text", "")}
                            </ReactMarkdown>
                        </div>
                    </div>
                    <div className="ToolIntroductionExample">
                        {this.renderToolExample()}
                    </div>
                </div>

            </div>
        );
    }

    renderResultsContainer() {
        const {results, isGenerating} = this.state;

        if (results.every((result) => _.isEmpty(result.content))) {
            if (isGenerating) {
                return <LoadingScreen/>;
            }

            return (
                <div style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    fontFamily: "var(--font-family)"
                }}>
                    <div>Você ainda não possui nenhum resultado.</div>
                    <div>Experimente gerar um exemplo na aba ao lado!</div>
                </div>
            );
        }

        if (isGenerating && results.every((result) => _.isEmpty(result.content))) {
            return <LoadingScreen/>;
        }

        return results.map((result) => {
            if (_.isEmpty(result.content)) {
                return;
            }

            return (
                <ResultCard
                    result={result}
                    isGenerating={isGenerating}
                    onEdit={this.handleEditResult}
                    onRemove={this.handleRemoveResult}
                    onRate={this.handleRateResult}
                    onTitleChange={this.handleResultTitleChange}
                    onSave={this.handleSaveResult}
                />
            );
        });
    }

    renderToolTitle() {
        const {tool} = this.state;

        return (
            <MainHeaderTitle
                src={`${process.env.REACT_APP_TOOL_ICON_S3_BUCKET_PREFIX_URL}${tool.icon}`}
                title={tool.name}
                renderBack
            />
        )
    }

    renderClearButton() {
        const {results, isGenerating, isExampleRunning} = this.state;

        if (_.isEmpty(results) || isGenerating || isExampleRunning)
            return;

        return (
            <Tooltip title="Limpar resultados" color={"#724CF9"} placement="left">
                <FloatButton
                    className="ToolClearButton"
                    type="primary"
                    icon={<FontAwesomeIcon icon={faTrashCan}/>}
                    onClick={this.handleOpenClearConfirmModal}
                />
            </Tooltip>
        );
    }

    renderClearConfirmModal() {
        const {showClearConfirmModal} = this.state;
        return (
            <Modal
                open={showClearConfirmModal}
                onOk={this.handleClearResults}
                onCancel={this.handleCloseClearConfirmModal}
                okText={"Limpar"}
                cancelText={"Cancelar"}
            >
                <div className="ToolClearResultsConfirmModalMessageContainer">
                    <FontAwesomeIcon
                        className="ToolClearResultsConfirmModalMessageIcon"
                        icon={faCircleExclamation}
                    />
                    <label className="ToolClearResultsConfirmModalMessage">
                        Você tem certeza que deseja limpar os resultados?
                    </label>
                </div>


            </Modal>
        )
    }

    renderStopGenerationButton() {
        const {isProcessingStream} = this.state;

        return (
            <StopGenerationButton
                onStop={this.handleStopGeneration}
                isGenerating={isProcessingStream}
            />
        );
    }

    renderTabs() {
        const items = [
            {
                key: "1",
                label: "Resultados",
                children: this.renderResultsContainer()
            },
        ]
        return (
            <Tabs defaultActiveKey="1" items={items}/>
        )
    }

    renderSwitchButton() {
        const {switchScreenValue} = this.state;
        const {isMobile} = this.props;

        const options = ['Inputs', 'Resultados'];

        if (isMobile) {
            return (
                <div className={`flex w-full justify-center pt-2 pb-4 ${switchScreenValue === options[0] ? "bg-white" : ""}`}>
                    <SelectButton
                        className="ToolFloatingSwitch"
                        options={options}
                        value={switchScreenValue}
                        onChange={(e) => this.handleSwitchScreenValueChange(e.value)}
                    />
                </div>
            );
        }
    }

    renderIntroductionTab() {
        return (
            <TabPanel
                leftIcon={<FontAwesomeIcon icon={faWandSparkles}/>}
                style={{maxHeight: "calc(100% - 48px)", height: "100%"}}
            >
                {this.renderToolIntroduction()}
            </TabPanel>
        )
    }

    renderResultsTab() {
        return (
            <TabPanel
                leftIcon={<FontAwesomeIcon icon={faPencil} style={{marginRight: 12}}/>}
                header="Resultados"
                style={{height: "100%"}}
            >
                <div className="ToolResultsContainer" ref={this.toolResultsComponent}>
                    {this.renderResultsContainer()}
                    {this.renderStopGenerationButton()}
                </div>
            </TabPanel>
        );
    }

    renderScreen() {
        const {switchScreenValue, tabActiveIndex} = this.state;
        const {isMobile} = this.props;

        if (isMobile) {
            if (switchScreenValue === "Inputs") {
                return (
                    <div className="ToolParamsContainer">
                        {this.renderForm()}
                    </div>
                )
            } else {
                return (
                    <div className="ToolTabsContainer" ref={this.toolResultsComponent}
                         onScroll={this.handleScrollDetector}>
                        <TabView
                            activeIndex={tabActiveIndex}
                            onTabChange={(e) => this.setState({tabActiveIndex: e.index})} // needs to be here so tabview can change tabs
                            style={{height: "100%"}}
                        >
                            {this.renderIntroductionTab()}
                            {this.renderResultsTab()}
                        </TabView>
                    </div>
                )
            }
        } else {
            return (
                <Row style={{height: "100%"}}>
                    <Col span={8} className="ToolParamsContainer">
                        {this.renderForm()}
                    </Col>
                    <Col span={16} className="ToolTabsContainer">
                        <TabView
                            activeIndex={tabActiveIndex}
                            onTabChange={(e) => this.setState({tabActiveIndex: e.index})} // needs to be here so tabview can change tabs
                            style={{height: "100%"}}
                        >
                            {this.renderIntroductionTab()}
                            {this.renderResultsTab()}
                        </TabView>
                    </Col>
                </Row>
            );
        }
    }

    render() {
        return (
            <>
                <div className="ToolContainer">
                    {this.renderScreen()}
                    {this.renderSwitchButton()}
                    {this.renderClearButton()}
                    {this.renderDrawer()}
                    {this.renderClearConfirmModal()}
                </div>
            </>
        );
    }
}

const ToolWrapper = (props) => {
    const [messageApi, messageContextHolder] = message.useMessage();
    const [notificationApi, notificationContextHolder] = notification.useNotification();
    const [organization, setOrganization] = useContext(OrganizationContext);
    const {user, refetch} = useContext(UserContext);
    const walletService = useWalletService();
    const params = useParams();
    const organizationService = useOrganizationService();

    const {generationModelClass} = useGenerationModelClass();

    const location = useLocation();
    const {refetch: refetchCredits} = useWallet();

    useEffect(() => {
        const {idOrganization} = params;

        if (location?.state?.currentOrganization) {
            setOrganization(location.state.currentOrganization);
        } else if (idOrganization) {
            const organization = organizationService.getOrganization(parseInt(idOrganization));
            if (!_.isEmpty(organization))
                setOrganization(organization);
        } else {
            setOrganization(null);
        }

        return () => setOrganization(null);
    }, []);

    return (
        <>
            {messageContextHolder}
            {notificationContextHolder}
            <Tool
                user={user}
                userRefetch={refetch}
                messageApi={messageApi}
                notificationApi={notificationApi}
                currentOrganization={organization}
                walletService={walletService}
                generationModelClass={generationModelClass}
                params={params}
                organization={organization}
                refetchCredits={refetchCredits}
                {...props}
            />
        </>
    );
}

export default withIsMobile(ToolWrapper);