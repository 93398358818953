import {Button, Col, Input, Row, message} from "antd";
import React, {useState} from "react";
import SettingsProfilePicture from "./components/SettingsProfilePicture";
import _ from "lodash";
import {InputMask} from "primereact/inputmask";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {LoadingOutlined} from "@ant-design/icons";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserRemote from "../../../services/dao/remote/user";
import SettingsContainer from "../components/SettingsContainer";

const SettingsProfile = ({user, defaultPicture}) => {
    const [name, setName] = useState(_.get(user, "name", ""));
    const [phoneNumber, setPhoneNumber] = useState(_.get(user, "phoneNumber", ""));
    const [picture, setPicture] = useState(_.get(user, "picture", null) ? _.get(user, "picture", null) : defaultPicture);

    const queryClient = useQueryClient();

    const update = useMutation({
        mutationFn: (data) => UserRemote.update(data),
        onSuccess: (user) => {
            queryClient.setQueryData(["user"], (old) => user);
            message.open({
                type: 'success',
                content: 'Perfil atualizado!',
            });
        },
        onError: () => {
            message.open({
                type: 'error',
                content: 'Erro ao atualizar perfil!',
            });
        }
    });

    const handleUpdateProfile = () => {
        update.mutate({name, phoneNumber});
    }

    const handleUpdatePicture = (user) => {
        queryClient.setQueryData(["user"], (old) => user);
        setPicture(user.picture);
        message.open({
            type: 'success',
            content: 'Imagem de perfil atualizada!',
        });
    }

    const renderNameField = () => {
        return (
            <div className="SettingsField">
                <div className="SettingsFieldTitle">
                    Nome completo
                </div>
                <Input
                    size={"large"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
        );
    }

    const renderPhoneNumberField = () => {
        return (
            <div className="SettingsField">
                <div className="SettingsFieldTitle">
                    Telefone
                </div>
                <InputMask
                    mask="+55 (99) 99999 9999"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    style={{width: "100%"}}
                >
                    {() => <Input/>}
                </InputMask>
            </div>
        );
    }

    const renderProfilePicture = () => {
        return (
            <div className="SettingsProfilePicture">
                <SettingsProfilePicture
                    idUser={user.idUser}
                    profilePicture={picture}
                    onUpdate={handleUpdatePicture}
                />
            </div>
        );
    }

    const renderUpdateButton = () => {
        return (
            <div className="SettingsSaveButton">
                <Button
                    size="large"
                    type="primary"
                    onClick={handleUpdateProfile}
                    disabled={update.isLoading}
                >
                    {update.isLoading ? (
                        <>
                            <LoadingOutlined
                                style={{marginRight: 6}}
                                spin
                            /> Atualizando
                        </>
                    ) : ('Atualizar')}
                </Button>
            </div>
        );
    }

    return (
        <SettingsContainer containerized>
            <div className="SettingsSectionTitle" style={{marginBottom: 24}}>
                <FontAwesomeIcon icon={faUser} style={{marginRight: 12}}/>
                Meu perfil
            </div>
            <Row gutter={36}>
                <Col xs={{span: 8}} lg={{span: 6}}>
                    {renderProfilePicture()}
                </Col>
                <Col xs={{span: 16}} lg={{span: 18}}>
                    {renderNameField()}
                    {renderPhoneNumberField()}
                </Col>
            </Row>
            
            {renderUpdateButton()}

            {/* Seção de contato adicionada */}
            <div className="mt-8 bg-gradient-to-br from-purple-50 to-white border border-purple-100 rounded-xl p-6 shadow-lg relative overflow-hidden">
                {/* Elemento decorativo de fundo */}
                <div className="absolute -right-4 -top-4 w-24 h-24 bg-purple-500/10 rounded-full blur-2xl" />
                <div className="absolute -left-8 -bottom-8 w-32 h-32 bg-purple-600/5 rounded-full blur-3xl" />
                
                <h3 className="text-xl font-semibold text-purple-900 mb-4 relative">
                    Precisa de ajuda?
                </h3>
                
                <div className="space-y-3 relative">
                    <p className="text-gray-700 leading-relaxed">
                    Fale conosco pelo e-mail:{" "}
                    <a 
                        href="mailto:contato@cmos.ai"
                        className="text-purple-600 hover:text-purple-700 font-medium transition-colors duration-200 border-b border-purple-200 hover:border-purple-600"
                    >
                        contato@cmos.ai
                    </a>
                    </p>
                    
                    <p className="text-gray-700 leading-relaxed">
                    Ou preencha nosso{" "}
                    <a 
                        href="https://forms.gle/2XBuCJxicuVt81Uj6"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-purple-600 hover:text-purple-700 font-medium transition-colors duration-200 border-b border-purple-200 hover:border-purple-600 inline-flex items-center gap-1"
                    >
                        formulário de contato
                        <svg 
                        className="w-4 h-4" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                        >
                        <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" 
                        />
                        </svg>
                    </a>
                    </p>
                </div>
                </div>
        </SettingsContainer>
    );
}

export default SettingsProfile;