import {Card, Collapse, Input, Popconfirm, Rate, Select, Tooltip, Button, message, Modal} from "antd";
import "./ResultCard.css";
import React from "react";
import {faBookmark, faClone, faEdit, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ExclamationCircleFilled} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {faCheck, faXmark, faBookmark as faBookmarkSolid} from "@fortawesome/free-solid-svg-icons";
import RateWrapper from "../../utils/components/rate-wrapper/RateWrapper";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const {confirm} = Modal;

/**
 * Remove bloco de metadata (||METADATA||...||ENDMETADATA||) se existir
 */
function removeMetadata(str) {
  if (!str) return "";
  const start = str.indexOf("||METADATA||");
  const end = str.indexOf("||ENDMETADATA||");
  if (start === -1 || end === -1 || end < start) {
    return str;
  }
  return str.slice(0, start) + str.slice(end + "||ENDMETADATA||".length);
}

/**
 * Remove referências do tipo [1] [2][3] do texto
 */
function removeCitations(str) {
  return str.replace(/\s*\[[0-9]+(?:\]\[[0-9]+\])*\]/g, "");
}

// Extrai URLs presentes dentro de tags <think>...</think>
function extractThinkCitations(text) {
  const thinkRegex = /<think>([\s\S]*?)<\/think>/gi;
  let citations = [];
  let match;
  while ((match = thinkRegex.exec(text)) !== null) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    let urlMatch;
    while ((urlMatch = urlRegex.exec(match[1])) !== null) {
      citations.push(urlMatch[0]);
    }
  }
  return citations;
}

// Extrai todo o bloco <think>...</think> como string
function extractThinkBlock(text) {
  const thinkRegex = /<think>([\s\S]*?)<\/think>/gi;
  const matches = text.match(thinkRegex);
  if (!matches) return "";
  return matches.map((m) => m.replace(/<\/?think>/g, "")).join("\n\n");
}

// Remove os blocos <think>...</think> do texto principal
function removeThinkBlock(text) {
  return text.replace(/<think>[\s\S]*?<\/think>/gi, "");
}

// Extrai domínio da URL
function extractDomain(url) {
  try {
    if (!/^https?:\/\//i.test(url)) {
      url = "https://" + url;
    }
    const { hostname } = new URL(url);
    return hostname.toLowerCase();
  } catch (err) {
    return "";
  }
}

// Gera favicon pela API do Google
function getFaviconUrl(domain) {
  return `https://www.google.com/s2/favicons?sz=32&domain_url=${domain}`;
}

// Componentes padrão para renderizar Markdown, incluindo tabelas
const markdownComponents = {
  table: ({ node, ...props }) => (
    <div className="overflow-x-auto my-4">
      <table className="min-w-full table-auto border-collapse border border-gray-300 rounded-sm" {...props} />
    </div>
  ),
  thead: ({ node, ...props }) => <thead className="bg-gray-100" {...props} />,
  tbody: ({ node, ...props }) => <tbody className="bg-white divide-y divide-gray-200" {...props} />,
  tr: ({ node, ...props }) => <tr className="hover:bg-gray-50 even:bg-gray-50" {...props} />,
  th: ({ node, ...props }) => (
    <th className="px-4 py-2 text-xs font-semibold text-gray-700 border border-gray-300 uppercase tracking-wide text-left" {...props} />
  ),
  td: ({ node, ...props }) => (
    <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300" {...props} />
  ),
  code: ({ node, inline, className, children, ...props }) => {
    const match = /language-(\w+)/.exec(className || '');
    return !inline ? (
      <pre className="bg-gray-800 text-white p-3 rounded-md overflow-x-auto my-4" {...props}>
        <code className={className}>{children}</code>
      </pre>
    ) : (
      <code className="bg-gray-100 text-red-500 p-1 rounded" {...props}>
        {children}
      </code>
    );
  },
  heading: ({ node, level, ...props }) => {
    const sizes = [
      'text-2xl',
      'text-xl',
      'text-lg',
      'text-base',
      'text-sm',
      'text-xs',
    ];
    const size = sizes[level - 1] || sizes[2];
    return React.createElement(
      'h' + level,
      { className: `font-bold ${size} my-4`, ...props },
      props.children
    );
  },
  blockquote: ({ node, ...props }) => (
    <blockquote className="border-l-4 border-gray-300 pl-4 italic text-gray-600 my-4" {...props} />
  ),
  hr: ({ node, ...props }) => (
    <hr className="my-8 border-0 h-px bg-gradient-to-r from-transparent via-gray-300 to-transparent" {...props} />
  ),
};

const ResultCard = ({result, isGenerating, onEdit, onRemove, onRate, onTitleChange, onSave}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isEditing, setEditing] = React.useState(false);
  const [title, setTitle] = React.useState(result.title);
  const [content, setContent] = React.useState(result.content);
  
  // Botão de "Ver/Ocultar Raciocínio" aberto por padrão
  const [showReasoning, setShowReasoning] = React.useState(true);
  const [isSaved, setSaved] = React.useState(false);

  function showDeleteConfirmModal() {
    confirm({
      title: "Você deseja apagar esse resultado?",
      icon: <ExclamationCircleFilled />,
      okText: "Apagar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        onRemove(result);
      },
      onCancel() {}
    });
  }

  function handleCopy(event) {
    if (isGenerating || isEditing) return;
    navigator.clipboard.writeText(result.content)
      .then(() => {
        messageApi.success("Copiado!", 1);
      });
  }

  function handleRemove(event) {
    event.stopPropagation();
    if (isGenerating) return;
    showDeleteConfirmModal();
  }

  function handleEdit(event) {
    event.stopPropagation();
    if (isGenerating) return;
    setTitle(result.title);
    setContent(result.content);
    setEditing(true);
  }

  function handleContentChange(event) {
    event.stopPropagation();
    if (isGenerating) return;
    setContent(event.target.value);
  }

  function handleResultEditConfirm(event) {
    event.stopPropagation();
    if (isGenerating) return;
    setEditing(false);
    onEdit(result, content);
  }

  function handleResultEditCancel(event) {
    event.stopPropagation();
    if (isGenerating) return;
    setEditing(false);
  }

  function handleResultRatingChange(value, event) {
    event.stopPropagation();
    if (isGenerating) return;
    onRate(result, value);
  }

  const handleResultTitleChange = (event) => {
    event.stopPropagation();
    if (isGenerating) return;
    onTitleChange(result, event.target.value);
  };

  function handleSave(event) {
    event.stopPropagation();
    if (isGenerating) return;
    onSave(result);
  }

  function handleUnsave(event) {
    event.stopPropagation();
    if (isGenerating) return;
    onSave(result);
  }

  const handleSaveButtonClick = (event) => {
    event.stopPropagation();
    setSaved(!isSaved);
    isSaved ? handleUnsave() : handleSave();
  };

  function renderIconTray() {
    if (isGenerating) {
      return (<div style={{height: "22px"}}></div>);
    }
    if (isEditing) {
      return renderEditIconTray();
    }
    return renderRegularIconTray();
  }

  function renderEditIconTray() {
    return (
      <div className="ResultCardIconTray">
        <div className={!isGenerating && "ResultCardIconTrayIcon"} onClick={handleResultEditConfirm}>
          <FontAwesomeIcon icon={faCheck}/>
        </div>
        <div className={!isGenerating && "ResultCardIconTrayIcon"} onClick={handleResultEditCancel}>
          <FontAwesomeIcon icon={faXmark}/>
        </div>
      </div>
    );
  }

  function renderRegularIconTray() {
    return (
      <div className="ResultCardIconTray">
        {renderCopyButton()}
        {renderDeleteButton()}
        {renderEditButton()}
      </div>
    );
  }

  function renderCopyButton() {
    return (
      <Tooltip title="Copiar" color={"#724cf9"} key={"copy-icon-tray-icon"}>
        <div className={!isGenerating && "ResultCardIconTrayIcon"} onClick={handleCopy}>
          <FontAwesomeIcon icon={faClone}/>
        </div>
      </Tooltip>
    );
  }

  function renderDeleteButton() {
    return (
      <Tooltip title="Apagar" color={"#724cf9"} key={"delete-icon-tray-icon"}>
        <div className={!isGenerating && "ResultCardIconTrayIcon"} onClick={handleRemove}>
          <FontAwesomeIcon icon={faTrashCan}/>
        </div>
      </Tooltip>
    );
  }

  function renderEditButton() {
    return (
      <Tooltip title="Editar" color={"#724cf9"} key={"edit-icon-tray-icon"}>
        <div className={!isGenerating && "ResultCardIconTrayIcon"} onClick={handleEdit}>
          <FontAwesomeIcon icon={faEdit}/>
        </div>
      </Tooltip>
    );
  }

  const renderTitle = () => {
    if (isEditing) {
      return (
        <Input
          className={"ResultCardTitleField"}
          bordered={false}
          maxLength={70}
          value={title}
          onChange={handleResultTitleChange}
        />
      );
    }
    return (
      <div className={"ResultCardTitle"}>
        {result.title}
      </div>
    );
  };

  function renderContent() {
    if (isEditing) {
      return (
        <TextArea
          className="ResultCardContentField"
          autoSize
          value={content}
          bordered={false}
          onChange={handleContentChange}
        />
      );
    }
    // Remove METADATA e referências numéricas
    let finalContent = removeCitations(removeMetadata(result.content));
    
    // Verifica se existe tag <think> mesmo sem fechamento
    let extractedThink = "";
    let contentWithoutThink = finalContent;
    const openIdx = finalContent.indexOf("<think>");
    const closeIdx = finalContent.indexOf("</think>");
    if (openIdx !== -1) {
      if (closeIdx === -1) {
        extractedThink = finalContent.substring(openIdx + 7).trim();
        contentWithoutThink = finalContent.substring(0, openIdx);
      } else {
        const regex = /<think>([\s\S]*?)<\/think>/gi;
        extractedThink = "";
        let match;
        while ((match = regex.exec(finalContent)) !== null) {
          extractedThink += match[1] + "\n\n";
        }
        extractedThink = extractedThink.trim();
        contentWithoutThink = finalContent.replace(regex, "");
      }
    }
    
    // Se for apenas a palavra "markdown", descarta
    if (extractedThink.trim().toLowerCase() === "markdown") {
      extractedThink = "";
    }

    // Extrai links das tags <think> para exibir favicon
    const citations = extractThinkCitations(finalContent);

    return (
      <>
        {/* Botão e bloco de raciocínio no topo */}
        {extractedThink && (
          <>
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setShowReasoning(!showReasoning);
              }}
              style={{ marginBottom: "8px" }}
            >
              {showReasoning ? "Ocultar Raciocínio" : "Ver Raciocínio"}
            </Button>
            {showReasoning && (
              <div 
                className="ResultCardReasoning" 
                style={{ 
                  marginBottom: "8px",
                  backgroundColor: "#f7f7f7", 
                  padding: "16px", 
                  borderRadius: "8px",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
                  border: "1px solid #e5e7eb"
                }}
              >
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                  components={markdownComponents}
                >
                  {extractedThink}
                </ReactMarkdown>
                {citations.length > 0 && (
                  <div
                    className="ResultCardCitations"
                    style={{ display: "flex", gap: "8px" }}
                  >
                    {citations.map((link, i) => {
                      const domain = extractDomain(link);
                      const faviconUrl = getFaviconUrl(domain);
                      return (
                        <Tooltip title={link} key={i}>
                          <img
                            src={faviconUrl}
                            alt="favicon"
                            style={{ width: 16, height: 16, objectFit: "contain" }}
                          />
                        </Tooltip>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {/* Conteúdo principal sem tags <think> */}
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={markdownComponents}
        >
          {contentWithoutThink && contentWithoutThink.replace(/```/g, "")}
        </ReactMarkdown>
      </>
    );
  }

  return (
    <Card className="ResultCard" onClick={handleCopy}>
      {contextHolder}
      {renderTitle()}
      <div className="ResultCardContent">
        {renderContent()}
      </div>
      {renderIconTray()}
    </Card>
  );
};

export default ResultCard;
