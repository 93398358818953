// src/components/chat/ChatContainerNew.jsx

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ChatRemote } from "../../services/dao/remote/chat";
import MainHeaderTitle from "../main-header/MainHeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import * as LocalStorage from "../../services/dao/local/localStorage";
import _ from "lodash";
import { Layout } from "antd";
import ChatOrchestrator from "./ChatOrchestrator";
import { Content } from "antd/es/layout/layout";
// import ChatHistoryMobile from "./ChatHistoryMobile"; // <-- Removido do layout mobile
import ChatHistory from "./ChatHistory";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { v4 as uuidv4 } from 'uuid';
import { ChatDAO } from "../../services/dao/local/ChatLocalDAO";
import PropTypes from "prop-types";
import ArtifactViewer from "../Artifact/ArtifactViewer";
import api from '../../services/api';
import MainMenuMobile from "../menu/MainMenuMobile";
import { useCurrentWorkspace } from "../../utils/hooks/useCurrentWorkspace";

// Função para converter snake_case -> camelCase
const snakeToCamel = (s) => {
  return s.replace(/(_\w)/g, m => m[1].toUpperCase());
};

// Mapear um único chat do backend -> frontend
const mapChatFromBackend = (chat) => {
  const mappedChat = {};
  for (let key in chat) {
    if (chat.hasOwnProperty(key)) {
      const camelKey = snakeToCamel(key);
      mappedChat[camelKey] = chat[key];
    }
  }
  return mappedChat;
};

// Mapear uma lista de chats do backend -> frontend
const mapChatsFromBackend = (chats) => {
  return chats.map(mapChatFromBackend);
};

// Mapear um chat do frontend -> backend
const mapChatToBackend = (chat) => {
  const mappedChat = {};
  for (let key in chat) {
    if (chat.hasOwnProperty(key)) {
      const snakeKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      mappedChat[snakeKey] = chat[key];
    }
  }
  return mappedChat;
};

const ChatContainerNew = ({ setHeaderTitle, user }) => {
  const queryClient = useQueryClient();
  const [idSelectedChat, setIdSelectedChat] = useState(null);
  const isMobile = useIsMobile();
  const [disabled, setDisabled] = useState(false);
  const [isAIWriting, setIsAIWriting] = useState(false);
  const [chatToEditId, setChatToEditId] = useState(null);
  const [selectedArtifact, setSelectedArtifact] = useState(null);
  const isCreatingChat = useRef(false);
  const { currentWorkspace } = useCurrentWorkspace(); // Obtenha o workspace atual

  const removedChats = useRef([]);

  // Define título e carrega chats locais
  useEffect(() => {
    document.title = "Chat · CMOs.ai";
    setHeaderTitle(
      <MainHeaderTitle
        icon={<FontAwesomeIcon icon={faComments} />}
        title="Chat especialista"
      />
    );
    loadLocalChats();
  }, [setHeaderTitle]);

  // Query dos chats, usando a query key com o workspace atual
  const { data: chats, isLoading } = useQuery({
    queryKey: ['chats', currentWorkspace?.idWorkspace],
    queryFn: () => {
      if (!currentWorkspace) return Promise.resolve([]);
      return ChatRemote.list(currentWorkspace.idWorkspace);
    },
    refetchOnWindowFocus: false,
    onSuccess: (backendChats) => {
      const mappedChats = mapChatsFromBackend(backendChats);
      ChatDAO.clear()
        .then(() => {
          ChatDAO.bulkPut(mappedChats)
            .then(() => console.log("Chats carregados e armazenados localmente."))
            .catch((error) => console.error("Erro ao armazenar chats localmente:", error));
        })
        .catch((error) => console.error("Erro ao limpar chats locais:", error));
    }
  });

  // Se não há chat selecionado, seleciona o primeiro disponível
  useEffect(() => {
    if (!idSelectedChat && !isCreatingChat.current && !disabled && chats != null) {
      selectChat();
    }
  }, [chats, idSelectedChat, currentWorkspace]);

  // Mutations
  const { mutateAsync: createChatRemoteFn } = useMutation({
    mutationFn: (newChat) => {
      const backendChat = mapChatToBackend(newChat);
      return ChatRemote.create(backendChat);
    },
    onSuccess: (backendChat) => {
      const mappedChat = mapChatFromBackend(backendChat);
      // Atualiza o cache usando a mesma query key
      queryClient.setQueryData(
        ['chats', currentWorkspace?.idWorkspace],
        (old) => [...(old || []), mappedChat]
      );
      selectChat(mappedChat.idChat);
      setChatToEditId(mappedChat.idChat);
      ChatDAO.put(mappedChat)
        .then(() => console.log(`Chat criado com sucesso no Dexie: ${mappedChat.idChat}`))
        .catch((error) => console.error(`Erro ao criar chat no Dexie:`, error));
    },
    onSettled: () => {
      setDisabled(false);
      isCreatingChat.current = false;
    }
  });

  const { mutateAsync: updateChatRemoteFn } = useMutation({
    mutationFn: (chat) => {
      const backendChat = mapChatToBackend(chat);
      return ChatRemote.update(backendChat);
    },
    onSuccess: (backendChat) => {
      const mappedChat = mapChatFromBackend(backendChat);
      queryClient.setQueryData(
        ['chats', currentWorkspace?.idWorkspace],
        (old) => {
          return old.map((c) => c.idChat === mappedChat.idChat ? mappedChat : c);
        }
      );
      ChatDAO.put(mappedChat)
        .then(() => console.log(`Chat idChat=${mappedChat.idChat} atualizado no Dexie.`))
        .catch((error) => console.error(`Erro ao atualizar chat idChat=${mappedChat.idChat}:`, error));
    },
    onSettled: () => {
      setDisabled(false);
    }
  });

  const { mutateAsync: deleteChatRemoteFn } = useMutation({
    mutationFn: (idChat) => ChatRemote.remove(idChat),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ['chats', currentWorkspace?.idWorkspace],
        (oldChats) => (oldChats || []).filter((c) => c.idChat !== response.idChat)
      );
      ChatDAO.remove(response.idChat)
        .then(() => console.log(`Chat ${response.idChat} removido do Dexie.`))
        .catch((error) => console.error(`Erro ao remover chat ${response.idChat} do Dexie:`, error));
      setIdSelectedChat(null);
    },
    onSettled: () => {
      setDisabled(false);
    }
  });

  // Funções CRUD
  const createChatFn = async (newChat) => {
    setDisabled(true);
    createChatRemoteFn(newChat);
  };

  const updateChatFn = (chat) => {
    setDisabled(true);
    return updateChatRemoteFn(chat);
  };

  const deleteChatFn = (idChat) => {
    setDisabled(true);
    deleteChatRemoteFn(idChat);
  };

  // Carrega chats locais para offline
  const loadLocalChats = () => {
    ChatDAO.list()
      .then((localChats) => {
        queryClient.setQueryData(
          ['chats', currentWorkspace?.idWorkspace],
          (old) => {
            if (_.isEmpty(old) && !_.isEmpty(localChats)) {
              return localChats;
            }
            return old;
          }
        );
      })
      .catch((error) => console.error("Erro ao carregar chats locais:", error));
  };

  // Seleciona um chat (ou cria se não existir)
  const selectChat = (idChat) => {
    if (chats == null) return;

    if (idChat == null) {
      const orderedChats = getOrderedChats();
      if (orderedChats.length === 0 && !disabled && !isCreatingChat.current) {
        isCreatingChat.current = true;
        handleChatCreate();
      } else {
        for (let chat of orderedChats) {
          if (!removedChats.current.includes(chat.idChat)) {
            setIdSelectedChat(chat.idChat);
            LocalStorage.setIdChatSelected(chat.idChat);
            break;
          }
        }
      }
    } else {
      setIdSelectedChat(idChat);
      LocalStorage.setIdChatSelected(idChat);
    }
  };

  // Retorna o chat atual
  const getCurrentChat = () => {
    if (!chats) return null;
    const currentChat = chats.find(chat => chat.idChat === idSelectedChat);
    if (currentChat == null) {
      return _.head(getOrderedChats());
    }
    return currentChat;
  };

  // Ordena chats por data
  const getOrderedChats = () => {
    if (!chats) return [];
    return _.orderBy(chats, (chat) => new Date(chat.updatedAt), ["desc"]);
  };

  // Cria um chat vazio
  const getEmptyChat = () => {
    const generateChatTitle = () => {
      const defaultTitle = "Chat";
      const usedTitles = chats.map(chat => chat.title);
      let index = 1;
      while (usedTitles.includes(`${defaultTitle} ${index}`)) {
        index++;
      }
      return `${defaultTitle} ${index}`;
    };

    return {
      idChat: uuidv4(),
      title: generateChatTitle(),
      messages: [],
      specialty: null,
      brandVoice: null,
      createdAt: new Date(),
      updatedAt: new Date()
    };
  };

  // Cria chat
  const handleChatCreate = () => {
    if (disabled) {
      console.log("Chat creation is disabled.");
      return;
    }
    const newChat = getEmptyChat();
    console.log("Creating new chat:", newChat);
    createChatFn(newChat);
  };

  // Troca chat
  const handleChatChange = (idChat) => {
    if (disabled) {
      console.log("Chat change is disabled.");
      return;
    }
    console.log(`Changing to chat: ${idChat}`);
    setIdSelectedChat(idChat);
    LocalStorage.setIdChatSelected(idChat);
  };

  // Deleta chat
  const handleChatDelete = (idChat) => {
    if (disabled) {
      console.log("Chat delete is disabled.");
      return;
    }
    console.log(`Deleting chat: ${idChat}`);
    deleteChatFn(idChat);
  };

  // Renomeia chat
  const handleChatRename = (idChat, title) => {
    console.log(`Renaming chat ${idChat} to ${title}`);
    const chat = chats.find((c) => c.idChat === idChat);
    if (!chat) {
      console.error(`Chat with idChat=${idChat} not found.`);
      return Promise.reject(new Error('Chat not found'));
    }
    const updatedChat = { ...chat, title, updatedAt: new Date() };
    console.log("Renaming chat with updated data:", updatedChat);
    return updateChatFn(updatedChat);
  };

  // Altera especialidade do chat
  const handleSpecialtyChange = (idChat, specialty) => {
    console.log(`Changing specialty of chat ${idChat} to`, specialty);
    const chat = _.cloneDeep(chats.find((c) => c.idChat === idChat));
    chat.specialty = specialty;
    queryClient.setQueryData(
      ['chats', currentWorkspace?.idWorkspace],
      (old) => old.map((c) => (c.idChat === chat.idChat ? chat : c))
    );
    updateChatFn(chat);
  };

  // Altera brand voice do chat
  const handleBrandVoiceChange = (idChat, brandVoice) => {
    console.log(`Changing brand voice of chat ${idChat} to`, brandVoice);
    const chat = _.cloneDeep(chats.find((c) => c.idChat === idChat));
    chat.brandVoice = brandVoice;
    queryClient.setQueryData(
      ['chats', currentWorkspace?.idWorkspace],
      (old) => old.map((c) => (c.idChat === chat.idChat ? chat : c))
    );
    updateChatFn(chat);
  };

  // Quando envia mensagem
  const handleMessageSent = (idChat) => {
    console.log(`Message sent in chat ${idChat}`);
    const chat = _.cloneDeep(chats.find((c) => c.idChat === idChat));
    chat.updatedAt = new Date();
    queryClient.setQueryData(
      ['chats', currentWorkspace?.idWorkspace],
      (old) => old.map((c) => (c.idChat === chat.idChat ? chat : c))
    );
  };

  // Renderiza histórico (desktop)
  const renderChatHistory = () => {
    return (
      <ChatHistory
        chats={getOrderedChats()}
        selectedChatId={idSelectedChat}
        workspaceId={currentWorkspace?.idWorkspace}
        onChatCreate={handleChatCreate}
        onChatChange={handleChatChange}
        onChatDelete={handleChatDelete}
        onChatRename={handleChatRename}
        isLoading={isLoading}
        disabled={disabled || isAIWriting}
        chatToEditId={chatToEditId}
        onChatEditComplete={() => setChatToEditId(null)}
        user={user}
      />
    );
  };

  // (Removemos a exibição do ChatHistoryMobile no mobile)
  // const renderChatHistoryMobile = () => {
  //   return (
  //     <ChatHistoryMobile
  //       // ...
  //     />
  //   );
  // };

  // Pré-visualização de artifacts
  const handleArtifactPreview = (artifact) => {
    console.log("Artifact preview requested:", artifact);
    setSelectedArtifact(artifact);
  };

  return (
    <>
      {isMobile && (
        <MainMenuMobile
          user={user}
          // Passe as funções reais aqui:
          onChatCreate={handleChatCreate}
          onChatChange={handleChatChange}
          onChatDelete={handleChatDelete}
          onChatRename={handleChatRename}
        />
      )}
      <Layout>
        <Layout style={{ display: "flex", flexDirection: "row", height: "100%" }}>
          {/* Se for desktop, mostramos o ChatHistory.
              No mobile, o histórico agora estará no MainMenuMobile (Drawer). */}
          {!isMobile && renderChatHistory()}
          <Content style={{ flex: 1, overflow: "hidden" }}>
            <ChatOrchestrator
              user={user}
              chat={getCurrentChat()}
              onSpecialtyChange={handleSpecialtyChange}
              onBrandVoiceChange={handleBrandVoiceChange}
              onAIWriting={(isWriting) => setIsAIWriting(isWriting)}
              onMessageSent={handleMessageSent}
              onArtifactPreview={handleArtifactPreview}
            />
          </Content>
        </Layout>
        {selectedArtifact && (
          <ArtifactViewer
            visible={!!selectedArtifact}
            codeContent={selectedArtifact.content || ""}
            codeLanguage={selectedArtifact.language || "javascript"}
            onClose={() => {
              console.log("Closing ArtifactViewer");
              setSelectedArtifact(null);
            }}
          />
        )}
      </Layout>
    </>
  );
};

ChatContainerNew.propTypes = {
  setHeaderTitle: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default ChatContainerNew;
