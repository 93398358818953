// brainwave-ui-develop\src\utils\providers\organizationProvider.js

import React, {createContext, useState} from "react";

export const OrganizationContext = createContext(null);

export const OrganizationContextProvider = ({children}) => {
    const [organization, setOrganization] = useState(null);

    return (
        <OrganizationContext.Provider value={[organization, setOrganization]}>
            {children}
        </OrganizationContext.Provider>
    );
};