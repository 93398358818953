// src/components/chat/ChatHistoryMobile.jsx
import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ChatHistoryItemMobile from "./ChatHistoryItemMobile";
import _ from "lodash";

const { confirm } = Modal;

/* 
  Cores utilizadas:
  - Azul Cobalto: #2962FF
  - Preto: #000000
  - Off-white: #F4F4F4
*/

/* Container principal do histórico com fundo Off‑white, borda e sombra marcante */
const ChatHistoryMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #F4F4F4;
  border: 3px solid #000;
  box-shadow: 3px 3px 0 #000;
  padding: 16px;
  border-radius: 8px;
  max-height: 40vh; /* Define a altura máxima para 60% da viewport */
  overflow-y: auto; /* Permite rolagem vertical */
`;

/* Wrapper para o botão de adicionar chat, centralizado */
const AddChatButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
`;

/* Botão para adicionar chat com estilo neobrutalista */
const BrutalAddChatButton = styled(Button)`
  border: 3px solid #000;
  box-shadow: 3px 3px 0 #000;
  background: #F4F4F4;
  color: #000;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translate(1.5px, 1.5px);
    box-shadow: 1.5px 1.5px 0 #000;
  }
  
  &:active {
    transform: translate(3px, 3px);
    box-shadow: none;
  }
`;

/* Label abaixo do botão "Criar Chat" */
const ChatHistoryMobileItemLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #000;
  margin-top: 4px;
`;

/* Conteúdo dos modais com espaçamento adequado */
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

/* Botões dos modais, mantendo a estética: borda, sombra e transição */
const ModalButton = styled(Button)`
  border: 3px solid #000;
  box-shadow: 3px 3px 0 #000;
  background: #F4F4F4;
  color: #000;
  font-weight: bold;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translate(1.5px, 1.5px);
    box-shadow: 1.5px 1.5px 0 #000;
  }
  
  &:active {
    transform: translate(3px, 3px);
    box-shadow: none;
  }
`;

const ChatHistoryMobile = (props) => {
  const {
    chats = [],
    selectedChatId,
    onChatCreate,
    onChatChange,
    onChatDelete,
    onChatRename,
  } = props;

  const [isItemModalOpen, setItemModalOpen] = useState(false);
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [idPressedChat, setIdPressedChat] = useState(null);
  const [renamedTitle, setRenamedTitle] = useState("");

  // Retorna o chat com base no id
  const getChat = (idChat) => chats.find((chat) => chat.idChat === idChat);

  // Abre modal ao pressionar e segurar um item
  const handleItemLongPress = (idChat) => {
    setIdPressedChat(idChat);
    setItemModalOpen(true);
  };

  // Fecha modal de item
  const handleItemCloseModal = () => {
    setIdPressedChat(null);
    setItemModalOpen(false);
  };

  // Confirma renomear o chat
  const handleRenameModalOk = () => {
    if (onChatRename && idPressedChat && renamedTitle.trim()) {
      onChatRename(idPressedChat, renamedTitle.trim());
    }
    setRenameModalOpen(false);
    setRenamedTitle("");
  };

  const handleRenameModalCancel = () => {
    setRenameModalOpen(false);
    setRenamedTitle("");
  };

  // Abre modal para renomear
  const handleRename = () => {
    setItemModalOpen(false);
    setRenameModalOpen(true);
  };

  // Exclui o chat
  const handleDelete = () => {
    setItemModalOpen(false);
    showConfirmDeleteModal();
  };

  // Modal de confirmação para excluir chat
  const showConfirmDeleteModal = () => {
    confirm({
      title: "Excluir este chat?",
      icon: <ExclamationCircleFilled style={{ color: "#ff4d4f" }} />,
      okText: "Excluir",
      okType: "danger",
      cancelText: "Cancelar",
      centered: true,
      onOk() {
        if (onChatDelete) {
          onChatDelete(idPressedChat);
        }
        setIdPressedChat(null);
      },
      onCancel() {
        setIdPressedChat(null);
      },
    });
  };

  // Botão de adicionar chat
  const renderAddChatButton = () => (
    <BrutalAddChatButton
      shape="circle"
      type="text"
      size="large"
      onClick={onChatCreate}
    >
      <FontAwesomeIcon icon={faPlus} style={{ fontSize: 20, color: "#000" }} />
    </BrutalAddChatButton>
  );

  // Renderiza os itens de chat
  const renderChatItems = () => (
    <>
      <AddChatButtonWrapper>
        {renderAddChatButton()}
        <ChatHistoryMobileItemLabel>Criar Chat</ChatHistoryMobileItemLabel>
      </AddChatButtonWrapper>
      {chats.slice().reverse().map((chat) => (
        <ChatHistoryItemMobile
          key={chat.idChat}
          idChat={chat.idChat}
          title={chat.title}
          onChatChange={onChatChange}
          onLongPress={handleItemLongPress}
          isSelected={chat.idChat === selectedChatId}
        />
      ))}
    </>
  );

  // Modal de opções (renomear/excluir)
  const renderItemModal = () => (
    <Modal
      open={isItemModalOpen}
      footer={null}
      width={300}
      centered
      closable={false}
      onCancel={handleItemCloseModal}
    >
      <ModalContent>
        <ModalButton type="text" block onClick={handleRename}>
          Renomear
        </ModalButton>
        <ModalButton type="text" danger block onClick={handleDelete}>
          Excluir
        </ModalButton>
        <ModalButton type="text" block onClick={handleItemCloseModal}>
          Cancelar
        </ModalButton>
      </ModalContent>
    </Modal>
  );

  // Modal de renomear chat
  const renderRenameModal = () => {
    const pressedChat = getChat(idPressedChat);
    if (_.isEmpty(pressedChat)) return null;
    return (
      <Modal
        open={isRenameModalOpen}
        onOk={handleRenameModalOk}
        onCancel={handleRenameModalCancel}
        width={300}
        centered
        closable={false}
        okText="Salvar"
        cancelText="Cancelar"
      >
        <ModalContent>
          <Input
            placeholder={pressedChat.title}
            value={renamedTitle}
            size="large"
            maxLength={20}
            onChange={(e) => setRenamedTitle(e.target.value)}
          />
        </ModalContent>
      </Modal>
    );
  };

  return (
    <>
      <ChatHistoryMobileContainer>
        {renderChatItems()}
      </ChatHistoryMobileContainer>
      {renderItemModal()}
      {renderRenameModal()}
    </>
  );
};

export default ChatHistoryMobile;
