// src/app/marketing-research/DeepSeekQuickChat.jsx

import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Auth } from "aws-amplify";
import { Card } from "./ui/card.tsx";
import { Send } from "lucide-react";
import MessageBubble from "./MessageBubble";

function extractThinkTexts(text) {
  const regex = /<think>([\s\S]*?)<\/think>/gi;
  let extracted = "";
  let match;
  while ((match = regex.exec(text)) !== null) {
    extracted += match[1] + " ";
  }
  const cleanText = text.replace(regex, "");
  return { cleanText, extracted: extracted.trim() };
}

const DeepSeekQuickChat = forwardRef(
  ({ currentSession, handleSuggestionClick }, ref) => {
    // --------------------------------
    // Estado local
    // --------------------------------
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userInput, setUserInput] = useState("");
    const [enhancingMsgIndex, setEnhancingMsgIndex] = useState(null);
    const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

    // Guardar o chatId somente em memória (sem localStorage)
    const [chatId, setChatId] = useState("");

    // Refs
    const containerRef = useRef(null);
    const textareaRef = useRef(null);
    const accumulatedResponseRef = useRef("");
    const abortControllerRef = useRef(null);

    // --------------------------------
    // Expondo métodos p/ componente pai
    // --------------------------------
    useImperativeHandle(ref, () => ({
      sendHiddenPrompt,
      resetMessages,
    }));

    // Função para resetar o estado local (não salva nada)
    function resetMessages() {
      setMessages([]);
      setUserInput("");
      setLoading(false);
      setEnhancingMsgIndex(null);
      accumulatedResponseRef.current = "";
    }

    // --------------------------------
    // 1) Quando a session mudar: gera um chatId novo e zera
    // --------------------------------
    useEffect(() => {
      if (!currentSession || !currentSession.sessionId) return;

      // Aborta requisições pendentes e zera o estado
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }
      resetMessages();

      // Gera um chatId único “volátil”
      const newChatId = `marketing-playground-${currentSession.sessionId}-${Date.now()}`;
      setChatId(newChatId);

    }, [currentSession.sessionId]);

    // ------------------------------------------------------------------
    // 2) Monitorar a rolagem do contêiner para habilitar/desabilitar o auto-scroll
    // ------------------------------------------------------------------
    useEffect(() => {
      const container = containerRef.current;
      if (!container) return;

      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const atBottom = scrollHeight - scrollTop - clientHeight < 100;
        setAutoScrollEnabled(atBottom);
      };

      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }, []);

    // ------------------------------------------------------------------
    // Auto-scroll para o final se estiver habilitado
    // ------------------------------------------------------------------
    useEffect(() => {
      if (containerRef.current && autoScrollEnabled) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }, [messages, autoScrollEnabled]);

    // ----------------------------------
    // Funções de manipulação do state
    // ----------------------------------
    function addUserMessage(text) {
      setMessages((prev) => [
        ...prev,
        { role: "user", content: text, isFinished: true },
      ]);
    }

    function updateAssistantMessage(content, reasoning) {
      setMessages((prev) => {
        const lastIndex = findLastAssistantIndex(prev);
        if (lastIndex === -1) {
          return [
            ...prev,
            {
              role: "assistant",
              content,
              reasoning,
              isFinished: false,
              citations: [],
            },
          ];
        } else {
          const updated = { ...prev[lastIndex], content, reasoning };
          return [
            ...prev.slice(0, lastIndex),
            updated,
            ...prev.slice(lastIndex + 1),
          ];
        }
      });
    }

    function finalizeAssistantMessage(content, reasoning, citations = []) {
      setMessages((prev) => {
        const lastIndex = findLastAssistantIndex(prev);
        if (lastIndex === -1) return prev;
        const updated = {
          ...prev[lastIndex],
          content,
          reasoning,
          citations,
          isFinished: true,
        };
        return [
          ...prev.slice(0, lastIndex),
          updated,
          ...prev.slice(lastIndex + 1),
        ];
      });
    }

    function findLastAssistantIndex(arr) {
      for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i].role === "assistant" && !arr[i].isFinished) {
          return i;
        }
      }
      return -1;
    }

    // -------------------------------------------------
    // 3) Fluxo principal: Envio de pergunta para DeepSeek
    // -------------------------------------------------
    async function doStreamingFetch(promptText) {
      if (!chatId) {
        console.log("chatId ainda não definido, abortando.");
        return;
      }

      setLoading(true);
      accumulatedResponseRef.current = "";

      try {
        const session = await Auth.currentSession().catch(() => null);
        if (!session) {
          updateAssistantMessage(
            "Você não está autenticado. Por favor, faça login.",
            ""
          );
          finalizeAssistantMessage(
            "Você não está autenticado. Por favor, faça login.",
            ""
          );
          setLoading(false);
          return;
        }
        const token = session.getIdToken().getJwtToken();
        if (!token) {
          updateAssistantMessage(
            "Token indisponível. Por favor, faça login novamente.",
            ""
          );
          finalizeAssistantMessage(
            "Token indisponível. Por favor, faça login novamente.",
            ""
          );
          setLoading(false);
          return;
        }

        const payload = {
          idChat: chatId,
          content: promptText,
          generation_model_class: "deepseek-r1",
        };

        // Aborta requisição anterior, se houver
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;

        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/marketing-completion`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "X-Chat-Id": chatId,
            },
            body: JSON.stringify(payload),
            credentials: "include",
            signal,
          }
        );

        if (!res.ok) {
          updateAssistantMessage("Erro ao obter resposta. Tente novamente.", "");
          finalizeAssistantMessage("Erro ao obter resposta. Tente novamente.", "");
          setLoading(false);
          return;
        }

        // Leitura do streaming
        const reader = res.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let done = false;

        while (!done) {
          const { value, done: readerDone } = await reader.read();
          done = readerDone;
          if (value) {
            const chunk = decoder.decode(value);
            accumulatedResponseRef.current += chunk;

            const { cleanText, extracted } = extractThinkTexts(
              accumulatedResponseRef.current
            );

            const openIdx = accumulatedResponseRef.current.indexOf("<think>");
            const closeIdx = accumulatedResponseRef.current.indexOf("</think>");

            let currentContent = cleanText;
            let currentReasoning = extracted;
            if (openIdx !== -1 && closeIdx === -1) {
              currentContent = accumulatedResponseRef.current.substring(0, openIdx);
              currentReasoning = accumulatedResponseRef.current.substring(openIdx + 7);
            }
            updateAssistantMessage(currentContent, currentReasoning);
          }
        }

        // Fim do streaming
        let finalText = accumulatedResponseRef.current;
        let finalReason = "";
        let citations = [];
        const metaRegex = /(\|\|METADATA\|\|)([\s\S]*?)(\|\|ENDMETADATA\|\|)/gi;
        let match;
        while ((match = metaRegex.exec(finalText)) !== null) {
          try {
            const parsed = JSON.parse(match[2]);
            if (parsed.reasoning_content) {
              finalReason += "\n" + parsed.reasoning_content;
            }
            if (parsed.content) {
              finalText = parsed.content;
            }
            if (Array.isArray(parsed.citations)) {
              citations = parsed.citations;
            }
          } catch (err) {
            console.error("Erro ao parsear metadata", err);
          }
          finalText = finalText.replace(match[0], "");
        }

        finalizeAssistantMessage(finalText, finalReason, citations);

      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Requisição abortada devido à troca de sessão.");
        } else {
          console.error("Erro ao obter resposta do DeepSeek:", err);
          updateAssistantMessage("Ocorreu um erro ao obter a resposta.", "");
          finalizeAssistantMessage("Ocorreu um erro ao obter a resposta.", []);
        }
      } finally {
        setLoading(false);
        abortControllerRef.current = null;
      }
    }

    // -------------------------------------------------
    // 4) Fluxo de Aprimoramento Avançado (modelo o3-mini)
    // -------------------------------------------------
    async function handleEnhanceAssistantMessage(index) {
      setEnhancingMsgIndex(index);
      try {
        const session = await Auth.currentSession().catch(() => null);
        if (!session) {
          alert("Você não está autenticado! Faça login antes de aprimorar.");
          setEnhancingMsgIndex(null);
          return;
        }
        const token = session.getIdToken().getJwtToken();
        if (!token) {
          alert("Token indisponível. Faça login novamente.");
          setEnhancingMsgIndex(null);
          return;
        }

        const msgContent = messages[index]?.content;
        if (!msgContent) {
          alert("Conteúdo vazio. Não há o que aprimorar.");
          setEnhancingMsgIndex(null);
          return;
        }

        const payload = {
          idChat: chatId,
          content: msgContent,
          generation_model_class: "o3-mini",
        };

        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/completion`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
            credentials: "include",
          }
        );

        if (!res.ok) {
          throw new Error("Falha ao aprimorar a resposta.");
        }

        const reader = res.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let done = false;
        let accumulated = "";

        while (!done) {
          const { value, done: readerDone } = await reader.read();
          done = readerDone;
          if (value) {
            const chunk = decoder.decode(value);
            accumulated += chunk;
            setMessages((prev) => {
              const clone = [...prev];
              const updatedMsg = { ...clone[index], enhancedContent: accumulated };
              clone[index] = updatedMsg;
              return clone;
            });
          }
        }
      } catch (err) {
        console.error("Erro ao aprimorar a resposta:", err);
        setMessages((prev) => {
          const clone = [...prev];
          const updatedMsg = {
            ...clone[index],
            enhancedContent: "Não foi possível aprimorar a resposta.",
          };
          clone[index] = updatedMsg;
          return clone;
        });
      } finally {
        setEnhancingMsgIndex(null);
      }
    }

    // -------------------------------------------------
    // 5) Fluxo de “Sugestão” (prompt oculto)
    // -------------------------------------------------
    function sendHiddenPrompt(label, hiddenPrompt) {
      addUserMessage(label);
      doStreamingFetch(hiddenPrompt);
    }

    // -------------------------------------------------
    // 6) Formulário e UI
    // -------------------------------------------------
    function handleSubmit(e) {
      e.preventDefault();
      const text = userInput.trim();
      if (!text) return;
      addUserMessage(text);
      doStreamingFetch(text);
      setUserInput("");
    }

    function handleKeyDown(e) {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    }

    // Auto-redimensiona o textarea
    useEffect(() => {
      if (!textareaRef.current) return;
      const txt = textareaRef.current;
      txt.style.height = "auto";
      if (userInput.length > 300) {
        const maxH = 150;
        txt.style.height = Math.min(txt.scrollHeight, maxH) + "px";
        txt.style.overflowY = "auto";
      } else {
        txt.style.height = txt.scrollHeight + "px";
        txt.style.overflowY = "hidden";
      }
    }, [userInput]);

    // --------------------------------
    // Render
    // --------------------------------
    return (
      <div className="w-full h-[calc(100vh-90px)] flex flex-col">
        {/* Histórico do chat */}
        <div ref={containerRef} className="flex-1 overflow-auto p-4 bg-white">
          {currentSession && (
            <>
              {/* Featured Card */}
              <Card className="rounded-3xl overflow-hidden border-0 shadow hover:shadow-lg transition-shadow mb-6">
                <div className="flex items-center">
                  <img
                    src={currentSession.featured.image}
                    alt={currentSession.featured.alt}
                    className="object-cover h-[220px] w-[400px]"
                  />
                  <div className="p-8">
                    <h2 className="text-2xl font-crimson text-[#1a472a] leading-tight">
                      {currentSession.featured.title}
                    </h2>
                  </div>
                </div>
              </Card>

            {/* Bottom Cards Grid */}
            <div className="grid grid-cols-4 gap-4 mb-6">
              {currentSession.bottomCards.map((item, i) => (
                <Card
                  key={i}
                  onClick={() =>
                    handleSuggestionClick(item.title, item.hiddenPrompt)
                  }
                  className="group p-4 rounded-3xl border-0 transition-transform duration-300 hover:scale-105 hover:shadow-lg"
                  style={{
                    background: `radial-gradient(ellipse farthest-side at 76% 77%, rgba(245, 228, 212, 0.25) 4%, rgba(255, 255, 255, 0) calc(4% + 1px)),
                                radial-gradient(circle at 76% 40%, #fef6ec 4%, rgba(255, 255, 255, 0) 4.18%),
                                linear-gradient(135deg, #ff0000 0%, #000036 100%),
                                radial-gradient(ellipse at 28% 0%, #ffcfac 0%, rgba(98, 149, 144, 0.5) 100%),
                                linear-gradient(180deg, #cd6e8a 0%, #f5eab0 69%, #d6c8a2 70%, #a2758d 100%)`,
                    backgroundBlendMode: "normal, normal, screen, overlay, normal",
                    boxShadow: "0px 0px 10px 1px #000000",
                  }}
                >
                  <div className="text-3xl mb-3">{item.icon}</div>
                  <p className="text-base font-semibold from-neutral-700 text-[#ad4ec5] leading-tight transition-colors duration-300 group-hover:text-purple-600">
                    {item.title}
                  </p>
                </Card>
              ))}
            </div>
            </>
          )}

          {/* Render das mensagens */}
          {messages.map((msg, idx) => {
            const isAssistant = msg.role === "assistant";
            const showEnhanceButton = isAssistant && msg.isFinished === true;

            return (
              <MessageBubble
                key={idx}
                message={msg}
                showEnhanceButton={showEnhanceButton}
                onEnhanceClick={() => handleEnhanceAssistantMessage(idx)}
                isEnhancing={enhancingMsgIndex === idx}
                enhancedContent={msg.enhancedContent || ""}
              />
            );
          })}
        </div>

        {/* Área de input */}
        <form onSubmit={handleSubmit} className="border-t border-gray-200 bg-white py-4">
          <div className="relative max-w-2xl mx-auto">
            <textarea
              ref={textareaRef}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={loading}
              placeholder="Digite algo..."
              className="w-full border-2 border-gray-300 rounded-lg px-4 py-3 pr-12 focus:outline-none focus:border-green-500 focus:ring-2 focus:ring-green-200 resize-none shadow-sm transition-all duration-200 ease-in-out"
              style={{ minHeight: "3rem", maxHeight: "12rem" }}
            />
            <button
              type="submit"
              disabled={loading}
              className="absolute right-3 bottom-3 p-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all duration-200 ease-in-out disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
              <Send className="w-5 h-5" />
            </button>
          </div>
        </form>
      </div>
    );
  }
);

export default DeepSeekQuickChat;
