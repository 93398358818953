import {useQuery} from "@tanstack/react-query";
import WalletService from "../../services/dao/remote/WalletService";
import _ from "lodash";
import {useEffect, useState} from "react";

export const useWallet = () => {
    const {data: wallet, isLoading, refetch} = useQuery({
        queryKey: ['wallet'],
        queryFn: () => WalletService.get(),
        refetchOnWindowFocus: false,
    });

    const [imageCredits, setImageCredits] = useState(null);
    const [textCredits, setTextCredits] = useState(null);
    const [nextRecharge, setNextRecharge] = useState(null);

    const getCurrentBalance = (walletFundType) => {
        if (!wallet || isLoading) return null;
        return _.get(wallet, `funds.${walletFundType}.currentFund.balance`, 0);
    }

    const getNextRechargeDate = () => {
        if (!wallet || isLoading) return null;

        const fundGenerator = _.get(wallet, "funds.textGenerationCredits", []);
        const currentFund = _.get(fundGenerator, "currentFund", []);

        if (currentFund?.validUntil) {
            return new Date(currentFund.validUntil);
        }

        return null;
    }

    const getTextGenerationCredits = () => {
        if (!wallet || isLoading) return;

        const currentBalance = getCurrentBalance("textGenerationCredits");
        if (currentBalance) {
            return currentBalance;
        }

        return 0;
    }

    const getImageGenerationCredits = () => {
        if (!wallet || isLoading) return 0;

        const currentBalance = getCurrentBalance("imageGenerationCredits");
        if (currentBalance) {
            return currentBalance;
        }

        return 0;
    }

    useEffect(() => {
        setTextCredits(getTextGenerationCredits());
        setImageCredits(getImageGenerationCredits());
        setNextRecharge(getNextRechargeDate());
    }, [wallet, isLoading]);

    return { wallet, isLoading, refetch, textCredits, imageCredits, nextRecharge};
}