// src/components/chat/ChatRenderer.jsx

import React, { useEffect, useRef, useState, useCallback } from "react";
import "./ChatRenderer.css";
import TextInput from "../text-input/TextInput";
import Message from "../message/Message";
import CompletionRemote from "../../services/dao/remote/completion";
import {
  Drawer,
  Layout,
  Modal,
  Tooltip,
  notification,
  message,
  Dropdown,
  Avatar,
  Button,
  Spin,
  Tag,
} from "antd";
import { Specialties } from "../../api/specialties";
import _ from "lodash";
import { Content } from "antd/es/layout/layout";
import { QuestionCircleOutlined, CloseOutlined } from "@ant-design/icons";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { useGenerationModelClass } from "../../utils/hooks/useGenerationModelClass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PremiumButton from "../buttons/PremiumButton";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import { faUpload, faFile } from "@fortawesome/free-solid-svg-icons";
import SpecialtySelector from "./SpecialtySelector";
import Logo from "../../images/message.png";
import SuggestionBox from "./SuggestionBox";
import { useMutation, useQuery } from "@tanstack/react-query";
import { MessageRemote } from "../../services/dao/remote/message";
import BrandVoiceSelector from "./BrandVoiceSelector";
import GenerationModelClassSelector from "../../app/generation-model-class/GenerationModelClassSelector";
import CreditsCounter from "../../app/generation-model-class/CreditsCounter";
import { useWallet } from "../../utils/hooks/useWallet";
import NoMoreTextCreditsModal from "../../app/generation-model-class/NoMoreTextCreditsModal";
import PropTypes from "prop-types";
import ArtifactViewer from "../Artifact/ArtifactViewer";
import { useSpecialties } from "../../utils/hooks/useSpecialties";
import { UpOutlined } from '@ant-design/icons';
import PromptSuggestions from "./PromptSuggestions";

const ChatRenderer = ({
  idChat,
  user,
  specialty = null,
  brandVoice = null,
  onSpecialtyChange,
  onBrandVoiceChange,
  onAIWriting,
  onMessageSent,
  onArtifactPreview,
}) => {
  const [isAIWritingState, setAIWriting] = useState(false);
  const [showSpecialtyFeatureDrawer, setShowSpecialtyFeatureDrawer] = useState(false);
  const [showSpecialtyModalMobile, setShowSpecialtyModalMobile] = useState(false);
  const [userPicture, setUserPicture] = useState(null);
  const [selectedCodeSnippet, setSelectedCodeSnippet] = useState(null);
  const isMobile = useIsMobile();

  let autoScroll = useRef(true);
  const chatBox = useRef();
  const textInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const textInputContainerRef = useRef(null);
  const [messageApi, messageContextHolder] = message.useMessage();
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const messageDelayWarningTimeout = useRef(null);
  const { generationModelClass } = useGenerationModelClass();
  const openAIModels = ["gpt-4o-mini", "gpt-4o", "o3-mini"];
  const isOpenAIModel = openAIModels.includes(generationModelClass);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const isDeepSeek = generationModelClass === "deepseek-r1";
  const [messages, setMessages] = useState([]);
  const { refetch, textCredits, isLoading: isWalletLoading } = useWallet();
  const [hideNoMoreTextCreditsModal, setHideNoMoreTextCreditsModal] = useState(false);
  const { specialties, isLoading: isSpecialtiesLoading } = useSpecialties();

  // Estados carregamento lazy das mensagens
  const [visibleMessages, setVisibleMessages] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const MESSAGES_PER_PAGE = 5;

  const handleScroll = useCallback((event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const atBottom = scrollTop + clientHeight >= scrollHeight;
    autoScroll.current = atBottom;
  }, []);

  // Estados e referências para o upload de arquivos
  const [uploadedFileUri, setUploadedFileUri] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const fileInputRef = useRef(null);
  const [uploadedFileType, setUploadedFileType] = useState(null);

  // Verificar se o modelo é Gemini
  const isGeminiModel = generationModelClass === 'gemini-flash' || generationModelClass === 'gemini-pro';

  // Constantes para as chaves das mensagens
  const MESSAGE_DELAY_WARNING_KEY = "message-delay-warning-chat";
  const WAIT_MESSAGE_KEY = "waitMessage";

  // Definir os tipos de arquivos permitidos
  const allowedFileTypes = [
    'pdf', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'heic', 'heif',
    'mp4', 'mpeg', 'mov', 'avi', 'flv', 'mpg', 'webm', 'wmv', '3gpp',
    'wav', 'mp3', 'aiff', 'aac', 'ogg', 'flac'
  ];
  const allowedFileTypesString = allowedFileTypes.join(', ');

  const showWaitMessage = (content, duration) => {
    messageApi.open({
      key: WAIT_MESSAGE_KEY,
      type: "loading",
      content: content,
      duration: duration / 1000,
    });
  };

  useEffect(() => {
    console.log("idChat recebido pelo ChatRenderer:", idChat);
    handleScrollDown(true);
    const picture = getUserPicture();
    console.log("URL da imagem de perfil do usuário:", picture);
    setUserPicture(picture);
  }, [idChat, user]);

  const { mutateAsync: createMessageRemoteFn } = useMutation({
    mutationFn: (newMessage) => {
      console.log('Creating message:', newMessage);
      return MessageRemote.create(newMessage);
    },
  });

  const { isLoading, isFetched } = useQuery({
    queryKey: ['messages', idChat],
    queryFn: () => MessageRemote.list(idChat),
    refetchOnWindowFocus: false,
    onSuccess: (messages) => {
        console.log('Mensagens carregadas:', messages);

        // Converter timestamp para instância de Date
        const messagesWithDates = messages.map((msg) => ({
            ...msg,
            timestamp: new Date(msg.timestamp),
        }));

        setMessages(messagesWithDates);
        // Inicialmente, mostrar apenas as últimas 5 mensagens
        setVisibleMessages(messagesWithDates.slice(-MESSAGES_PER_PAGE));
        handleScrollDown(true);
    },
    onError: (error) => {
        console.error("Erro ao carregar mensagens:", error);
    }
});

  const getUserPicture = () => {
    const defaultPicture = localStorage.getItem(`${user?.email}/defaultPicture`);
    if (user?.picture) {
      if (user.picture.startsWith("http")) {
        return user.picture;
      }
      return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/${user.idUser}/256/${user.picture}`;
    } else if (defaultPicture) {
      return defaultPicture;
    }
    return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/default/256/default.png`;
  };

  const openMessageDelayWarning = (currentUploadedFileType) => {
    let contentMessage = "A resposta está demorando um pouco mais do que o esperado...";

    if (isGeminiModel && currentUploadedFileType && (currentUploadedFileType.startsWith('audio/') || currentUploadedFileType.startsWith('video/'))) {
      contentMessage = "Analisando arquivos, o processamento pode demorar um pouco. Por favor, aguarde...";
    }

    // Antes de abrir uma nova mensagem, destrua qualquer mensagem existente com as chaves usadas
    messageApi.destroy(WAIT_MESSAGE_KEY);
    messageApi.destroy(MESSAGE_DELAY_WARNING_KEY);

    messageApi.open({
      key: MESSAGE_DELAY_WARNING_KEY,
      type: "loading",
      content: contentMessage,
      duration: 0,
    });
  };

  const openRequestErrorNotification = () => {
    notificationApi["error"]({
      message: "Erro ao processar mensagem",
      description: (
        <>
          <div style={{ padding: "5px 0" }}>
            Estamos com um <b>alto nível de utilização</b> no momento, o que está ocasionando <b>lentidão no sistema</b>. Nosso time já está atuando para resolver o problema.
          </div>
          <div style={{ padding: "6px 0" }}>
            Pedimos desculpas pelo inconveniente e agradecemos a sua compreensão.
          </div>
        </>
      ),
      duration: 60,
    });
  };

  const startMessageDelayWarningTimeout = () => {
    const currentUploadedFileType = uploadedFileType;
    messageDelayWarningTimeout.current = setTimeout(() => {
      openMessageDelayWarning(currentUploadedFileType);
    }, 7500);
  };

  const stopMessageDelayWarningTimeout = () => {
    messageApi.destroy(MESSAGE_DELAY_WARNING_KEY);
    clearTimeout(messageDelayWarningTimeout.current);
  };

  const loadMoreMessages = useCallback(() => {
    setIsLoadingMore(true);
    
    // Salvar a posição atual do scroll e a altura do conteúdo
    const scrollContainer = chatBox.current;
    const previousScrollHeight = scrollContainer.scrollHeight;

    const currentLength = visibleMessages.length;
    const nextMessages = messages.slice(
        Math.max(0, messages.length - (currentLength + MESSAGES_PER_PAGE)),
        messages.length - currentLength
    );

    if (nextMessages.length > 0) {
        setVisibleMessages(prev => [...nextMessages, ...prev]);
        
        // Após a atualização do DOM, ajustar o scroll
        setTimeout(() => {
            const newScrollHeight = scrollContainer.scrollHeight;
            const heightDifference = newScrollHeight - previousScrollHeight;
            scrollContainer.scrollTop = heightDifference;
            setIsLoadingMore(false);
        }, 0);
    } else {
        setIsLoadingMore(false);
    }
}, [messages, visibleMessages]);

  const handleScrollDown = (force = false) => {
    if (force) {
      autoScroll.current = true;
    }

    if (autoScroll.current && chatBox && chatBox.current) {
      chatBox.current.scrollTop = chatBox.current.scrollHeight;
    }
  };

  const handleCreateUserMessage = async (message) => {
    try {
        setMessages(old => [...old, message]);
        setVisibleMessages(old => {
            if (old.length >= MESSAGES_PER_PAGE) {
                return [...old.slice(1), message];
            }
            return [...old, message];
        });
        setTimeout(() => {
            handleScrollDown(true);
        }, 500);
        handleScrollDown(true);
        onMessageSent(idChat);
        await createMessageRemoteFn(message);
        await handleOpenAIMessage(message);
        setUploadedFileUri(null); // Resetar após o envio
        setUploadedFileName(null);
    } catch (error) {
        console.error("Erro em handleCreateUserMessage:", error);
    }
};

const handleCreateSystemMessage = (message) => {
    setMessages(old => [...old, message]);
    setVisibleMessages(old => {
        if (old.length >= MESSAGES_PER_PAGE) {
            return [...old.slice(1), message];
        }
        return [...old, message];
    });
};

const handleUpdateOngoingSystemMessage = (message) => {
    setMessages(old => [...old.slice(0, -1), message]);
    setVisibleMessages(old => [...old.slice(0, -1), message]);
};

const handleUpdateReadySystemMessage = (message) => {
    console.log('Saving message:', message);
    setMessages(old => [...old.slice(0, -1), message]);
    setVisibleMessages(old => [...old.slice(0, -1), message]);
    createMessageRemoteFn(message).catch(error => {
        console.error("Erro ao salvar mensagem:", error);
    });
};

  const displaySpecialtyFeatureDrawer = () => {
    setShowSpecialtyFeatureDrawer(true);
  };

  const hideSpecialtyFeatureDrawer = () => {
    setShowSpecialtyFeatureDrawer(false);
  };

  const getSpecialtyItems = () => {
    if (!specialties) return [];
    const sortedSpecialties = _.orderBy(specialties, ['name'], ['asc']);
    return sortedSpecialties.map((specialty) => {
      return {
        label: specialty.name,
        key: specialty.idSpecialty,
      };
    });
  };

  const handleSpecialtyChangeLocal = (specialty) => {
    onSpecialtyChange(idChat, specialty);
  };

  const handleBrandVoiceChangeLocal = (brandVoice) => {
    onBrandVoiceChange(idChat, brandVoice);
  };

  // Função para lidar com a mudança de arquivo
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await uploadFile(file);
    }
  };

  // Função para fazer upload do arquivo
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    setIsUploadingFile(true); // Iniciar o indicador de carregamento
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upload`, {
        method: 'POST',
        body: formData,
        credentials: 'include', // Inclua se necessário
      });
  
      if (response.ok) {
        const data = await response.json();
        const { file_uri, mime_type } = data;
        setUploadedFileUri(file_uri);
        setUploadedFileName(file.name);
        setUploadedFileType(mime_type);
        message.success('Arquivo enviado com sucesso!');
      } else {
        message.error('Falha ao enviar o arquivo.');
      }
    } catch (error) {
      console.error('Erro ao enviar o arquivo:', error);
      message.error('Erro ao enviar o arquivo.');
    } finally {
      setIsUploadingFile(false); // Parar o indicador de carregamento
    }
  };

  const handleUserSendingMessage = async (payload) => {
    try {
      // Se o payload tiver label e prompt, use o label para exibição e guarde o prompt em hiddenPrompt
      let displayText = payload.text;
      let hiddenPrompt = null;
      if (payload.label && payload.prompt) {
        displayText = payload.label;
        hiddenPrompt = payload.prompt;
      }
      
      const contentData = { text: displayText };
      const contentAsString = JSON.stringify(contentData);
  
      const message = {
        idChat,
        content: contentAsString, // O que será exibido no histórico (apenas o label, se enviado por SuggestionBox)
        reasoningContent: "...",   // Adapte conforme necessário
        role: "USER",
        specialty,
        brandVoice,
        timestamp: new Date(),
        onboard: false,
        files: payload.fileUri ? [payload.fileUri] : [],
        search_enabled: searchEnabled
      };
  
      // Se houver hiddenPrompt, adiciona essa propriedade (para uso interno na LLM)
      if (hiddenPrompt) {
        message.hiddenPrompt = hiddenPrompt;
      }
  
      await handleCreateUserMessage(message);
    } catch (error) {
      console.error("Erro em handleUserSendingMessage:", error);
    }
  };

  function extractThinkTagsFromBuffer(bufferText) {
    // Regex que pega UM par <think>...</think>
    const pattern = /(.*?)<think>([\s\S]*?)<\/think>([\s\S]*)/i;
    const match = bufferText.match(pattern);
    if (match) {
      const before = match[1]; // texto antes do <think>
      const cot = match[2];    // conteúdo entre <think> e </think>
      const after = match[3];  // resto que sobrou depois
      return {
        cleaned: before,        // "texto limpo" sem a tag
        leftover: after,        // o que sobra no buffer (pode ter outro <think>)
        extractedCot: cot,      // o raciocínio extraído
      };
    } else {
      return { cleaned: "", leftover: bufferText, extractedCot: null };
    }
  }

  const handleOpenAIMessage = async (userMessage) => {
    // Cria a mensagem do sistema iniciando com o primeiro texto base
    const message = {
      idChat,
      content: "Pensando", // texto base inicial (sem pontos, a animação os acrescentará)
      reasoningContent: "",
      role: "SYSTEM",
      specialty,
      brandVoice,
      timestamp: new Date(),
      onboard: false,
      generationModelUsed: generationModelClass,
    };
  
    setAIWriting(true);
    onAIWriting(true);
  
    // Define o prompt a ser enviado
    const promptToSend = userMessage.hiddenPrompt ? userMessage.hiddenPrompt : userMessage.content;
  
    // Cria a mensagem do sistema no chat com o texto inicial
    handleCreateSystemMessage(message);
  
    // --- Configuração da animação de texto ---
    // Array com diversos textos base para o estado de espera
    const baseTexts = [
      "Pensando",
      "Analisando",
      "Ponderando",
      "Aprofundando",
      "Reflexionando"
    ];
  
    let currentBaseTextIndex = 0;
    let dotCount = 0;
  
    // Função que atualiza o conteúdo da mensagem com os pontos animados
    const updateMessageContent = () => {
      message.content = baseTexts[currentBaseTextIndex] + ".".repeat(dotCount);
      message.timestamp = new Date();
      handleUpdateOngoingSystemMessage(message);
    };
  
    // Intervalo para animar os pontos (atualiza a cada 500ms)
    const ellipsisInterval = setInterval(() => {
      dotCount = (dotCount + 1) % 4; // Cicla de 0 a 3 pontos
      updateMessageContent();
    }, 500);
  
    // Intervalo para alternar o texto base (a cada 4 segundos)
    const baseTextInterval = setInterval(() => {
      currentBaseTextIndex = (currentBaseTextIndex + 1) % baseTexts.length;
      dotCount = 0; // Reinicia a contagem de pontos ao trocar o texto base
      updateMessageContent();
    }, 4000);
    // --- Fim da configuração da animação ---
  
    let isFirstChunk = true;
  
    try {
      // Inicia a requisição que devolve um reader streaming
      const reader = await CompletionRemote.getReader({
        idChat: message.idChat,
        content: promptToSend,
        specialty: message.specialty,
        brandVoice: message.brandVoice,
        generationModelClass: generationModelClass,
        files: userMessage.files,
      });
      if (!reader) throw new Error("No reader from CompletionRemote.");
  
      // Buffer local para tratar splits de <think>
      let buffer = "";
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        const chunkText = new TextDecoder("utf-8").decode(value);
  
        if (isFirstChunk) {
          // Ao receber o primeiro chunk, interrompe a animação
          clearInterval(ellipsisInterval);
          clearInterval(baseTextInterval);
          isFirstChunk = false;
          // Atualiza o conteúdo com o primeiro chunk recebido
          message.content = chunkText;
        } else {
          message.content += chunkText;
        }
        message.timestamp = new Date();
        handleUpdateOngoingSystemMessage(message);
  
        // (1) Extrair <think> do conteúdo recebido, se houver
        let { cleaned, leftover, extractedCot } = extractThinkTagsFromBuffer(message.content);
        while (extractedCot) {
          // Move o raciocínio extraído para 'reasoningContent'
          message.reasoningContent += extractedCot;
          // Remove as tags <think> do texto exibido ao usuário
          message.content = cleaned;
          message.timestamp = new Date();
          handleUpdateOngoingSystemMessage(message);
          const next = extractThinkTagsFromBuffer(leftover);
          cleaned = next.cleaned;
          leftover = next.leftover;
          extractedCot = next.extractedCot;
        }
  
        // (2) Verifica se o chunk é um JSON final (com "content" e "reasoning_content")
        if (chunkText.trim().startsWith("{") || chunkText.trim().startsWith("{\"")) {
          try {
            const parsed = JSON.parse(chunkText.trim());
            if (parsed.content !== undefined && parsed.reasoning_content !== undefined) {
              message.content = parsed.content;
              message.reasoningContent = parsed.reasoning_content;
              if (Array.isArray(parsed.citations)) {
                message.citations = parsed.citations;
              }
              handleUpdateOngoingSystemMessage(message);
              continue; // Não processa como texto normal
            }
          } catch (err) {
            console.warn("Falha ao parsear chunk JSON final:", err);
            // Se falhar, segue o fluxo normal
          }
        }
        // Aguarda o próximo chunk
      }
    } catch (error) {
      console.error("Error in handleOpenAIMessage:", error);
      // Aqui, optamos por não exibir notificações adicionais
    } finally {
      // Garante que os intervals sejam limpos, caso ainda estejam ativos
      clearInterval(ellipsisInterval);
      clearInterval(baseTextInterval);
    }
  
    // Finaliza a mensagem e atualiza a interface
    handleUpdateReadySystemMessage(message);
    if (!isMobile) {
      textInputRef.current?.delayedFocus();
    }
    setAIWriting(false);
    onAIWriting(false);
  };

  const memoizedHandleArtifactPreview = useCallback(
    (codeSnippet) => {
      console.log("Artifact preview requested:", codeSnippet);
      setSelectedCodeSnippet(codeSnippet);
      if (onArtifactPreview) {
        onArtifactPreview(codeSnippet);
      }
    },
    [onArtifactPreview]
  );

  const memoizedHandleScrollDown = useCallback(() => {
    handleScrollDown();
  }, [handleScrollDown]);

  const handleArtifactPreview = (codeSnippet) => {
    console.log("Artifact preview requested:", codeSnippet);
    setSelectedCodeSnippet(codeSnippet);
  };

  const renderArtifactViewer = () => {
    return (
      <>
        {selectedCodeSnippet && (
          <ArtifactViewer
            visible={!!selectedCodeSnippet}
            codeContent={selectedCodeSnippet.content || ""}
            codeLanguage={selectedCodeSnippet.language || "javascript"}
            onClose={() => {
              console.log("Closing ArtifactViewer");
              setSelectedCodeSnippet(null);
            }}
          />
        )}
      </>
    );
  };

  const systemUser = {
    id: 'system',
    name: 'CMOs.ai',
    picture: Logo,
  };

  const renderMessages = () => {
    if (_.isEmpty(messages) && !isLoading && isFetched) {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}
            >
                {renderEmptyChat()}
            </div>
        );
    }

    return (
        <div className="messages-wrapper">
            {isLoadingMore && (
                <div className="loading-old-messages">
                    <div className="loading-spinner-container">
                        <Spin size="large" />
                        <span className="loading-spinner-text">
                            Carregando mensagens antigas...
                        </span>
                    </div>
                </div>
            )}
            
            {messages.length > visibleMessages.length && !isLoadingMore && (
                <Button
                    className="load-more-messages-button"
                    onClick={loadMoreMessages}
                    icon={<UpOutlined />}
                    loading={isLoadingMore}
                >
                    Carregar mais {Math.min(MESSAGES_PER_PAGE, messages.length - visibleMessages.length)} mensagens antigas
                </Button>
            )}

            {visibleMessages.map((message, index) => {
                const uniqueKey = message.idMessage
                    ? `${message.idMessage}-${index}`
                    : `message-${index}`;

                const messageUser = message.role === "SYSTEM" ? systemUser : user;
                const messagePicture = message.role === "SYSTEM" ? systemUser.picture : userPicture;

                return (
                    <Message
                        key={uniqueKey}
                        idChat={idChat}
                        user={messageUser}
                        picture={messagePicture}
                        role={message.role}
                        specialty={message.specialty}
                        brandVoice={message.brandVoice}
                        content={message.content}
                        onboard={message.onboard}
                        timestamp={message.timestamp}
                        onArtifactPreview={memoizedHandleArtifactPreview}
                        scrollDown={memoizedHandleScrollDown}
                        isMobile={isMobile}
                        files={message.files}
                        reasoningContent={message.reasoningContent}
                        citations={message.citations} // citações de links retornando pela ia
                        generationModelUsed={message.generationModelUsed}
                    />
                );
            })}
        </div>
    );
};

  const renderEmptyChat = () => {
    return (
      <div className="flex flex-col h-full">
        {renderEmptyChatWelcomeMessage()}
        {renderEmptyChatSuggestions()}
      </div>
    );
  };

  const renderEmptyChatWelcomeMessage = () => {
    return (
      <div className="flex justify-end items-center flex-col h-[60%]">
        <Avatar size={96} src={Logo} />
        <label className="ImageGeneratorExample w-full">
          Como posso te ajudar hoje?
        </label>
      </div>
    );
  };

  const renderEmptyChatSuggestions = () => {
    return (
      <div className="empty-chat-suggestions">
        <PromptSuggestions
          onSelect={(data) => handleUserSendingMessage(data)}
          isMobile={isMobile}
        />
      </div>
    );
  };

  const renderSpecialtyFeatureDrawer = () => {
    return (
      <Drawer
        title="O que são as especialidades?"
        placement="right"
        onClose={hideSpecialtyFeatureDrawer}
        open={showSpecialtyFeatureDrawer}
        style={{ fontFamily: "Outfit" }}
        width={isMobile ? "80%" : 650}
      >
        <p>
          O recurso de especialidades permite que o usuário defina o tom e o escopo das respostas em seu chat,
          personalizando a experiência do usuário de acordo com a especialidade escolhida.
        </p>

        <p>
          Cada especialidade tem sua própria abordagem e conhecimento específico, proporcionando respostas mais
          precisas e relevantes para o usuário.
        </p>
      </Drawer>
    );
  };

  const renderSpecialtySelect = () => {
    const items = getSpecialtyItems();

    return (
      <div className="flex flex-row gap-2 justify-center items-center">
        <Dropdown
          type="primary"
          size="small"
          menu={{ items }}
          trigger={['click']}
          className="rounded-full"
          placement="topRight"
          arrow
          dropdownRender={(menu) => (
            <div className="max-h-[200px] overflow-auto">
              {menu}
            </div>
          )}
        >
          <PremiumButton innerClasses="rounded-full">
            <FontAwesomeIcon icon={faUserTie} className="text-white mr-2" />
            Especialidade
          </PremiumButton>
        </Dropdown>
      </div>
    );
  };

  const renderChatInput = () => {
    const renderUploadedFileInfo = () => {
      if (!uploadedFileName) return null;

      return (
        <div className="uploaded-file-info">
          <Tag
            icon={<FontAwesomeIcon icon={faFile} />}
            color="blue"
            closable
            onClose={() => {
              setUploadedFileUri(null);
              setUploadedFileName(null);
            }}
          >
            {uploadedFileName}
          </Tag>
        </div>
      );
    };

    const renderFileUploadButton = () => {
      return (
        <>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Tooltip title={!isGeminiModel ? "O upload de arquivos está disponível apenas para o modelo Gemini" : ""}>
            <Button
              onClick={() => fileInputRef.current && fileInputRef.current.click()}
              disabled={!isGeminiModel || isAIWritingState || isUploadingFile}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <FontAwesomeIcon icon={faUpload} />
              <span style={{ marginLeft: 8 }}>Upload</span>
              {isGeminiModel && (
                <Tooltip title={`Tipos de arquivos permitidos: ${allowedFileTypesString}`}>
                  <QuestionCircleOutlined
                    style={{ marginLeft: 8, cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation(); // Evita que o clique no ícone acione o upload
                    }}
                  />
                </Tooltip>
              )}
            </Button>
          </Tooltip>
        </>
      );
    };

    const renderFileUploadSection = () => {
      if (isUploadingFile) {
        return (
          <div className="uploading-file-info">
            <Spin tip="Enviando arquivo..." />
          </div>
        );
      }

      return renderUploadedFileInfo();
    };

    if (isMobile) {
      return (
        <div className="flex flex-col w-[95%] gap-2 mb-2 mt-2">
          <div className="w-full flex flex-row items-center">
            <div className="flex flex-row flex-grow items-center justify-start gap-2">
            </div>

            <div className="flex justify-end">
              <div className="flex items-center content-center gap-2">

              </div>
            </div>
          </div>

          {renderFileUploadSection()}

          <div className="w-full">
          <TextInput
            ref={textInputContainerRef}
            onSubmit={handleUserSendingMessage}
            disabled={isAIWritingState || isUploadingFile || (textCredits === 0 && !isWalletLoading)}
            isUploading={isUploadingFile}
            isOpenAIModel={isOpenAIModel}
            searchEnabled={isOpenAIModel ? searchEnabled : true}
            onToggleSearch={isOpenAIModel ? () => setSearchEnabled(prev => !prev) : () => {}}
            isMobile={isMobile}
            brandVoice={brandVoice}
            onBrandVoiceChange={handleBrandVoiceChangeLocal}
            isGeminiModel={isGeminiModel}
            isAIWriting={isAIWritingState}
            specialty={specialty}
            onSpecialtyChange={handleSpecialtyChangeLocal}
            isDeepSeek={isDeepSeek}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="ChatInput flex flex-col w-[75%] mb-4 mt-2">
        <div className="flex flex-row items-center">
          <div className="flex flex-row flex-grow items-center justify-start gap-2">
          </div>

          <div className="flex justify-end">
            <div className="flex items-center content-center gap-2">


            </div>
          </div>
        </div>

        {renderFileUploadSection()}

        <div style={{ width: "100%" }} ref={textInputContainerRef}>
          <TextInput
            ref={textInputContainerRef}
            onSubmit={handleUserSendingMessage}
            disabled={isAIWritingState || isUploadingFile || (textCredits === 0 && !isWalletLoading)}
            isUploading={isUploadingFile}
            isOpenAIModel={isOpenAIModel}
            searchEnabled={isOpenAIModel ? searchEnabled : true}
            onToggleSearch={isOpenAIModel ? () => setSearchEnabled(prev => !prev) : () => {}}
            isGeminiModel={isGeminiModel}
            isAIWriting={isAIWritingState}
            specialty={specialty}
            onSpecialtyChange={handleSpecialtyChangeLocal}
            isDeepSeek={isDeepSeek}
          />
        </div>
      </div>
    );
  };

  const renderSpecialtyModalMobile = () => {
    if (!isMobile) {
      return null;
    }

    return (
      <Modal
        open={showSpecialtyModalMobile}
        title="Escolha a especialidade"
        onOk={() => {}}
        onCancel={() => setShowSpecialtyModalMobile(false)}
        width={300}
        centered
        closable={false}
        footer={null}
      >
        {renderSpecialtySelect()}
      </Modal>
    );
  };

  return (
    <>
      {messageContextHolder}
      {notificationContextHolder}
      <div className="w-full flex flex-row items-center justify-between mx-4 mr-10 my-2">
        <GenerationModelClassSelector />
        <div className="mr-8">
          <CreditsCounter />
        </div>
      </div>
      <Layout className="Chat w-full flex flex-col" ref={chatContainerRef}>
      <Content
        className="
          ChatContainer
          flex-1
          w-full          // Largura total
          md:max-w-[70%]  // Em telas >= md, max-width de 60%
          mx-auto         // Centraliza horizontalmente
          px-2 py-2
        "
        ref={chatBox}
        onScroll={handleScroll}
      >
        {renderMessages()}
      </Content>
        {renderChatInput()}
        {renderSpecialtyFeatureDrawer()}
        {renderSpecialtyModalMobile()}
      </Layout>
      <NoMoreTextCreditsModal
        hide={hideNoMoreTextCreditsModal}
        onClose={() => setHideNoMoreTextCreditsModal(true)}
      />
      {renderArtifactViewer()}
    </>
  );
};

// Definir PropTypes para ChatRenderer
ChatRenderer.propTypes = {
  idChat: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  specialty: PropTypes.object,
  brandVoice: PropTypes.object,
  onSpecialtyChange: PropTypes.func.isRequired,
  onBrandVoiceChange: PropTypes.func.isRequired,
  onAIWriting: PropTypes.func.isRequired,
  onMessageSent: PropTypes.func.isRequired,
  onArtifactPreview: PropTypes.func.isRequired,
};

export default ChatRenderer;
