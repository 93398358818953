import {useQuery} from "@tanstack/react-query";
import UserRemote from "../../services/dao/remote/user";

const useUser = () => {
    const query = useQuery({
        queryKey: ['user'],
        queryFn: () => UserRemote.get(),
        refetchOnWindowFocus: false,
    });

    const refetch = () => query.refetch();

    return {
        user: query.data,
        isLoading: query.isLoading,
        refetch
    }
}

export default useUser;