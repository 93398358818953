// src\components\subscription\UpgradeButton.jsx

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import PremiumButton from "../buttons/PremiumButton";
import styled from "styled-components";

const StyledUpgradeButton = styled(PremiumButton)`
  position: relative;
  z-index: 9999;
  background: linear-gradient(90deg, #2962FF, #4FBCF5);
  border: 3px solid #000;
  box-shadow: 3px 3px 0 #000;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translate(1.5px, 1.5px);
    box-shadow: 1.5px 1.5px 0 #000;
  }
  
  &:active {
    transform: translate(3px, 3px);
    box-shadow: none;
  }
`;

const UpgradeButton = ({ label = "Upgrade", size, block = true }) => {
  const navigate = useNavigate();

  return (
    <StyledUpgradeButton
      size={size}
      block={block}
      icon={
        <FontAwesomeIcon
          className="UpgradeButtonIcon"
          icon={faBolt}
          style={{ marginRight: 8 }}
        />
      }
      onClick={() => navigate("/settings/subscription")}
    >
      {label}
    </StyledUpgradeButton>
  );
};

export default UpgradeButton;
