// src\components\tools\ToolsFilter.jsx

import Sider from "antd/es/layout/Sider";
import "./ToolsFilter.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faSearch} from "@fortawesome/free-solid-svg-icons";
import {Avatar, Button, Input} from "antd";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import React, {useState} from "react";
import _ from "lodash";

const ToolsFilter = ({categories, onTextFilter, onToggleCategoryFilter, onClearFilters, toolCategoriesFilter, isLoading}) => {

    const [isCollapsed, setCollapsed] = useState(false);
    const [filterText, setFilterText] = useState(null);

    const handleTextFilter = (e) => {
        setFilterText(e.target.value);
        onTextFilter(e.target.value);
    }

    const handleToggleCategoryFilter = (category) => {
        onToggleCategoryFilter(category);
    }

    const handleClearFilters = () => {
        setFilterText(null);
        onClearFilters();
    }

    const handleCollapse = () => {
        setCollapsed(!isCollapsed);
    }

    const renderSearchBox = () => {
        if (isCollapsed) {
            return (
                <div className="ToolsFilterSearchBox ToolsFilterSearchBoxCollapsed">
                    <FontAwesomeIcon icon={faSearch}/>
                </div>
            );
        }

        if (isLoading) {
            return;
        }

        return (
            <div className="ToolsFilterSearchBox">
                <Input
                    placeholder="O que você procura?"
                    prefix={<FontAwesomeIcon icon={faSearch}/>}
                    size={"large"}
                    onChange={handleTextFilter}
                    value={filterText}
                    allowClear
                    disabled={isLoading}
                />
            </div>
        );
    }

    const getCategoryPicture = (category) => {
        const prefix = process.env.REACT_APP_TOOL_CATEGORIES_ICON_S3_BUCKET_PREFIX_URL;
        return {
            "Redes sociais": `${prefix}/social_media.png`,
            "Conteúdo": `${prefix}/content.png`,
            "Gestão": `${prefix}/management.png`,
            "Produtividade": `${prefix}/productivity.png`,
            "Diversas": `${prefix}/miscellaneous.png`,
            "Vendas": `${prefix}/sales.png`,
            "Anúncios": `${prefix}/ads.png`,
        }[category];
    }

    const renderCategory = (category) => {
        const selected = toolCategoriesFilter ? toolCategoriesFilter.includes(category) : false;

        return (
            <div
                key={category}
                className={`ToolsFilterCategoryButton ${selected ? "ToolsFilterCategoryButtonSelected" : ""}`}
                onClick={() => handleToggleCategoryFilter(category)}
            >
                <Avatar
                    src={getCategoryPicture(category)}
                    shape="circle"
                    size={32}
                    style={{marginRight: 4}}
                />
                {category}
            </div>
        );
    }

    const renderCategories = () => {
        if (isCollapsed) {
            return null;
        }

        return (
            <div className="ToolsFilterTagsContainer">
                <div className="ToolsFilterTags">
                    {categories.map(category => renderCategory(category))}
                </div>
            </div>

        );
    }

    const renderClearFiltersButton = () => {
        const hasFilters = !_.isEmpty(toolCategoriesFilter);
        if (isCollapsed || !hasFilters) {
            return null;
        }

        return (
            <div className="ToolsFilterClearFiltersButtonContainer">
                <Button
                    className="ToolsFilterClearFiltersButton"
                    icon={<FontAwesomeIcon icon={faTrashCan} style={{marginRight: "6px"}}/>}
                    type="primary"
                    onClick={handleClearFilters}
                >
                    Limpar filtros
                </Button>
            </div>

        );
    }

    const render = () => {
        return (
            <div className="ToolsFilter">
                <div style={{alignItems: "center", justifyContent: "center"}}>
                    {renderSearchBox()}
                    {renderCategories()}
                </div>
            </div>
        )
    }

    return render();
}

export default ToolsFilter;