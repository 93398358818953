// src\components\main-header\MainHeader.jsx

import React from "react";
import "./MainHeader.css";
import { Header } from "antd/es/layout/layout";
import UserDropdown from "./UserDropdown";
import { useSubscriptionService } from "../../utils/hooks/useSubscriptionService";
import _ from "lodash";

function MainHeader(props) {
  const subscriptionService = useSubscriptionService();

  const renderUserDropdown = () => {
    const { user, onSignOut } = props;
    return (
      <UserDropdown
        user={user}
        defaultPicture={props.defaultPicture}
        onSignOut={onSignOut}
      />
    );
  };

  const renderSubscriptionLabel = () => {
    const activeSubscription = subscriptionService.getUserActiveSubscription();
    if (activeSubscription) {
      const trialing = subscriptionService.isUserActiveSubscriptionOnTrial();
      return (
        <div className="MainHeaderSubscriptionLabelContainer">
          <label className="MainHeaderSubscriptionLabel">
            {_.get(activeSubscription, "subscriptionPlan.name", null)}
          </label>
          {trialing && (
            <label className="MainHeaderSubscriptionLabelTrialBadge">
              trial
            </label>
          )}
        </div>
      );
    }
  };

  return (
    <Header className="MainHeader" style={{ marginLeft: 220 }}>
      <div className="MainHeaderContainer">
        <div className="MainHeaderLeftContainer">
          {props.headerTitle}
        </div>
        <div className="MainHeaderRightContainer">
          {renderSubscriptionLabel()}
          {renderUserDropdown()}
        </div>
      </div>
    </Header>
  );
}

export default MainHeader;
